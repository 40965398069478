/**
 *
 * Component: ProductTourNavigation
 * Date: 30/8/2021
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import styles from './style.css';
const { SubMenu } = Menu;

function ProductTourNavigation({
  tourGuidesOptions,
  selectedKeys,
  setselectedKeys,
  defaultOpenKeys,
  onTitleClick,
}) {
  return (
    <div className={styles.container}>
      <Menu
        data-testid="menu-cy"
        style={{ width: 256 }}
        mode="inline"
        theme="light"
        onSelect={e => {
          setselectedKeys(...e.selectedKeys);
        }}
        openKeys={[defaultOpenKeys]}
        selectedKeys={selectedKeys}
      >
        {tourGuidesOptions.map(submenu => (
          <SubMenu
            onTitleClick={onTitleClick}
            key={submenu.id}
            title={
              <div
                className={`${submenu.date ? styles.submenuContainer : ''} ${
                  submenu.id === defaultOpenKeys ? styles.selectedSubMenu : ''
                } `}
              >
                <span className={styles.submenuTitle}>{submenu.title}</span>
                {submenu.date && (
                  <span className={styles.submenuDate}>{submenu.date}</span>
                )}
              </div>
            }
          >
            {submenu.subOptions.map(option => (
              <Menu.Item key={option.id}>{option.title}</Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </div>
  );
}

ProductTourNavigation.propTypes = {
  tourGuidesOptions: PropTypes.array,
  selectedKeys: PropTypes.number,
  setselectedKeys: PropTypes.func,
  defaultOpenKeys: PropTypes.array,
  onTitleClick: PropTypes.func,
};

export default ProductTourNavigation;
