/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/**
 *
 * Component: SingleCallNotes
 * Date: 31/7/2020
 *
 */

import React, { useState, useRef, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Divider } from 'antd';

import { mixpanelBackend as mixpanel } from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import message from 'utils/message';
import { StringConstants, dateTime } from 'utils/commonFunctions';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { Text, Button, Modal, Icon, Tooltip } from 'components/common';
import SlateMentions from 'components/Mentions';
import Auth from 'auth0-react';

import styles from './style.css';
import {
  fetchSingleCallNotes,
  postNewSingleCallNote,
  editSingleCallNote,
  deleteSingleCallNote,
} from './api';

const { isScopePresent } = new Auth();
const primaryTimezone =
  localStorage.getItem('timezone') || StringConstants.defaultTimezone;
const hasEditNotesScope = isScopePresent('edit_notes');

function SingleCallNotes({ callId, scorecardList }) {
  const [showModal, setShowModal] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const notesRef = useRef(`${Math.random()}`);
  const notesTextRef = useRef('');
  const mentioned = useRef(new Set());

  const [userEmail] = useLocalStorage('userEmail');
  const queryClient = useQueryClient();

  const {
    data: notes,
    refetch: refetchNotes,
    isFetching: loadingNotes,
  } = useQuery(
    ['singleCallNotes', callId],
    () => fetchSingleCallNotes(callId),
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      cacheTime: Infinity,
    },
  );

  const { mutate: createNote, isLoading: isSubmittingNotes } = useMutation(
    postNewSingleCallNote,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['singleCallNotes', callId]);
        notesTextRef.current = '';
        mentioned.current = new Set();
        resetMention();
        showMessage('added');
      },
      onError: err => {
        message.error(err?.response?.data?.message || 'Error');
      },
    },
  );

  /**
   * Update the Cal Notes via API call.
   * */
  const { mutate: editNote } = useMutation(editSingleCallNote, {
    onMutate: ({ requestParams: editedNote }) => {
      const oldNoteList = queryClient.getQueryData(['singleCallNotes', callId]);
      const updatedNote = produce(oldNoteList, draft => {
        const noteIdx = draft.findIndex(
          note => note.uniqueId === editedNote.uniqueId,
        );
        draft[noteIdx].text = editedNote.note.text;
      });
      // Optimistically update to the new value
      queryClient.setQueryData(['singleCallNotes', callId], updatedNote);

      /**
       * Return the snapshotted value
       * */
      return () =>
        queryClient.setQueryData(['singleCallNotes', callId], oldNoteList);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['singleCallNotes', callId]);
      showMessage('edited');
      resetMention();
    },
  });

  const { mutate: deleteNote, isLoading: deletingNote } = useMutation(
    deleteSingleCallNote,
    {
      onMutate: ({ requestParams }) => {
        showMessage('deleted');
        queryClient.cancelQueries(['singleCallNotes', callId]);

        // Snapshot the previous value
        const oldNotesList = queryClient.getQueryData([
          'singleCallNotes',
          callId,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          ['singleCallNotes', callId],
          oldNotesList.filter(note => note.uniqueId !== requestParams.uniqueId),
        );

        // Return a rollback function
        return () =>
          queryClient.setQueryData(['singleCallNotes', callId], oldNotesList);
      },
      onSuccess: () => {
        refetchNotes();
      },
      onError: (err, newNote, rollback) => rollback(),
    },
  );

  /**
   * @returns {Object} for notes params like text and mentionEmails.
   */
  const getNoteParams = () => {
    const text = notesTextRef.current;

    // Get Email data
    const mentionsEmail = Array.from(mentioned.current).filter(
      mention =>
        text.includes(`@${mention.mentionTagData}`) && mention.type === 'user',
    );
    const mentionedEmails = [
      ...new Set(mentionsEmail.map(mention => mention.actualMentionData)),
    ];

    // Get scorecards data.
    const mentionsScorecard = Array.from(mentioned.current).filter(
      mention =>
        text.includes(`@${mention.mentionTagData}`) &&
        mention.type === 'scorecard',
    );

    const uniqueIdScorecards = new Set();
    const mentionedScorecards = mentionsScorecard
      .filter(itm => {
        if (uniqueIdScorecards.has(itm.actualMentionData._id)) {
          return false;
        }
        uniqueIdScorecards.add(itm.actualMentionData._id);
        return true;
      })
      .map(mention => mention.actualMentionData);

    return {
      note: {
        text,
        mentionedEmails,
        mentionedScorecards,
      },
    };
  };

  /**
   * returns nothing, but creates a node based on notes Params and CallID.
   */
  const handleCreateNote = () => {
    const text = notesTextRef.current;
    // Ignore add note click if text is empty
    if (text) {
      const requestParams = getNoteParams();

      mixpanel('Notes Added', {
        [mixpanelKeys.mentionedEmails]:
          requestParams?.note?.mentionedEmails?.length || 0,
        [mixpanelKeys.mentionedScorecards]:
          requestParams?.note?.mentionedScorecards?.length || 0,
      });
      createNote({ requestParams, callId });
    }
  };

  /**
   * @param {String} text: text on the note.
   * @param {String} noteId: id given to the note.
   * @param {String} mentionedEmails: sets requestParams, denotes email mentions.
   *
   * @returns nothing.but edits Notes with the object for requestId and callID.
   */
  const handleEditNote = (
    text,
    noteId,
    mentionedEmails,
    mentionedScorecards,
  ) => {
    setEditingNote(null);
    if (text) {
      const requestParams = {
        note: {
          text,
          mentionedEmails,
          mentionedScorecards,
        },
        type: 'modify',
        uniqueId: noteId,
      };
      editNote({ requestParams, callId, noteId });
    }
  };

  /**
   * Deletes the Notes based on unique ID.
   * @param {String} noteId: id for the notes
   * return nothing.
   */
  const handleDeleteNote = noteId => {
    const requestParams = {
      callId,
      type: 'delete',
    };
    deleteNote({ requestParams, callId, noteId });
  };

  /**
   * returns notes if exist else returns null
   */
  const getNotes = useMemo(() => {
    if (notes?.length) {
      // Display only the latest note
      return (
        <div>
          <Note
            {...notes[0]}
            key={notes[0].uniqueId}
            mentionedEmailsProps={notes[0].mentionedEmails}
            mentionedScorecardsProps={notes[0].mentionedScorecards}
            userEmail={userEmail}
            handleDeleteNote={handleDeleteNote}
            setEditingNote={noteId => {
              setShowModal(true);
              setEditingNote(noteId);
            }}
            callId={callId}
            scorecardList={scorecardList}
            deletingNote={deletingNote || loadingNotes}
            className={styles.spacing}
          />
        </div>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, userEmail, editingNote, deletingNote, loadingNotes]);

  /**
   * return all the notes else null.
   */
  const getAllNotes = useMemo(() => {
    if (notes?.length) {
      // Display only the latest note
      return notes.map(note => (
        <div>
          <Note
            {...note}
            mentionedEmailsProps={note.mentionedEmails}
            mentionedScorecardsProps={note.mentionedScorecards}
            key={note.uniqueId}
            userEmail={userEmail}
            handleDeleteNote={handleDeleteNote}
            setEditingNote={setEditingNote}
            isEditing={note.uniqueId === editingNote}
            handleEditNote={handleEditNote}
            callId={callId}
            scorecardList={scorecardList}
            deletingNote={deletingNote || loadingNotes}
            className={styles.spacing}
          />
          <Divider className={styles.spacing} />
        </div>
      ));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, userEmail, editingNote, deletingNote, loadingNotes]);

  /**
   * TODO: Can be used in the future
   * */
  // eslint-disable-next-line no-unused-vars
  const changeHeightOfTextArea = () => {
    const textarea = document.getElementById('notes-text-area');
    if (textarea) {
      textarea.style.height = '';
      textarea.style.height = `${Math.min(textarea.scrollHeight) + 2}px`;
    } else {
      setTimeout(() => {
        changeHeightOfTextArea();
      }, 100);
    }
  };

  /**
   * Append to the mentionedUsers list.
   *  */
  const handleMentionSelect = (selection, type = 'user') => {
    let valueObj = {};
    if (type === 'user')
      valueObj = {
        mentionTagData: selection.displayOption,
        actualMentionData: selection.actualOption,
      };
    else
      valueObj = {
        mentionTagData: selection.displayOption,
        actualMentionData: {
          name: selection.displayOption,
          _id: selection.actualOption,
        },
      };
    valueObj.type = type;
    mentioned.current.add(valueObj);
  };

  const resetMention = () => {
    notesRef.current = `${Math.random()}`;
  };

  /**
   * Display the alert message
   * */
  const showMessage = messageType => {
    message.success(`Note successfully ${messageType}`);
  };

  return (
    <>
      <div className="add-notes-container">
        <SlateMentions
          key={notesRef.current}
          onSelect={handleMentionSelect}
          onChange={text => {
            notesTextRef.current = text;
          }}
          autoFocus
          scorecardList={scorecardList}
          callId={callId}
          readOnlyWithBorders={isSubmittingNotes || !hasEditNotesScope}
        />
        <div className="add-notes-btns">
          <Button
            disabled={!hasEditNotesScope}
            onClick={handleCreateNote}
            loading={isSubmittingNotes}
            type="primary"
            text="Add Note"
          />
          {notes?.length > 0 && (
            <Button
              onClick={() => setShowModal(true)}
              type="link-primary"
              text="View All Notes"
            />
          )}
        </div>
      </div>
      <div className="notes-div">{getNotes}</div>

      <NotesModal
        visible={showModal}
        setShowModal={() => {
          setShowModal(false);
          setEditingNote(false);
        }}
        notes={getAllNotes}
      />
    </>
  );
}

const Note = ({
  createdAt,
  createdBy,
  lastModifiedAt,
  text,
  uniqueId,
  userEmail,
  handleDeleteNote,
  mentionedEmailsProps = [],
  mentionedScorecardsProps = [],
  isEditing,
  setEditingNote,
  handleEditNote,
  callId,
  scorecardList = [],
  deletingNote,
}) => {
  const editedText = useRef(text);
  const mentioned = useRef(new Set());
  const [noteDeleted, setNoteDeleted] = useState(false);

  const getDateTimeFromString = dateTimeArg => new Date(dateTimeArg).getTime();

  const setIsEditing = noteId => () => {
    if (noteDeleted) return;
    setEditingNote(noteId);
  };

  const transformEmail = email =>
    email.map(itm => ({
      checker: `@${itm.split('@')[0]}\0`,
      displayOption: `${itm.split('@')[0]}\0 `,
      actualOption: itm,
    }));
  const transfromScorecard = scorecards =>
    scorecards.map(itm => ({
      checker: `@${itm.name}\0`,
      actualOption: itm._id,
      displayOption: `${itm.name}\0 `,
    }));

  const generateSlateInitialValue = (
    textParam,
    emailMentionsList,
    scorecardMentionsList,
  ) => {
    const slateStruct = { children: [] };
    let slateArr = [];
    const textArr = textParam.split(' ');
    const emails = transformEmail(emailMentionsList);
    const scorecards = transfromScorecard(scorecardMentionsList);
    // eslint-disable-next-line array-callback-return
    textArr.map((textItem, index) => {
      let escape = false;
      const textElement = textItem;
      emails.forEach(em => {
        if (em.checker === textElement) {
          slateStruct.children = [
            ...slateStruct.children,
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                type: 'user',
              },
              children: [{ text: '' }],
            },
            { text: '' }, // Add Extra Text node for editor to know mentions end (useful for autoFocus Editor.end())
          ];
          escape = true;
          return false;
        }
        if (textElement.startsWith(em.checker)) {
          slateStruct.children = [
            ...slateStruct.children,
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                type: 'user',
              },
              children: [{ text: '' }],
            },
            {
              text:
                index + 1 === textArr.length // ignore adding spaces if last item in array
                  ? `${textElement.split(em.checker)[1]}`
                  : `${textElement.split(em.checker)[1]} `,
            },
          ];
          escape = true;
          return false;
        }
        if (textElement.endsWith(em.checker)) {
          slateStruct.children = [
            ...slateStruct.children,
            { text: `${textElement.split(em.checker)[0]}` },
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                type: 'user',
              },
              children: [{ text: '' }],
            },
            { text: '' }, // Add Extra Text node for editor to know mentions end (useful for autoFocus Editor.end())
          ];
          escape = true;
          return false;
        }

        return true;
      });

      if (escape) return;

      scorecards.forEach(em => {
        if (em.checker === textElement) {
          slateStruct.children = [
            ...slateStruct.children,
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                callId,
                type: 'scorecard',
              },
              children: [{ text: '' }],
            },
            { text: '' }, // Add Extra Text node for editor to know mentions end (useful for autoFocus Editor.end())
          ];
          escape = true;
          return false;
        }
        if (textElement.startsWith(em.checker)) {
          slateStruct.children = [
            ...slateStruct.children,
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                callId,
                type: 'scorecard',
              },
              children: [{ text: '' }],
            },
            {
              text:
                index + 1 === textArr.length // ignore adding spaces if last item in array
                  ? `${textElement.split(em.checker)[1]}`
                  : `${textElement.split(em.checker)[1]} `,
            },
          ];
          escape = true;
          return false;
        }
        if (textElement.endsWith(em.checker)) {
          slateStruct.children = [
            ...slateStruct.children,
            { text: `${textElement.split(em.checker)[0]}` },
            {
              type: 'mention',
              character: {
                displayOption: em.displayOption,
                actualOption: em.actualOption,
                callId,
                type: 'scorecard',
              },
              children: [{ text: '' }],
            },
            { text: '' }, // Add Extra Text node for editor to know mentions end (useful for autoFocus Editor.end())
          ];
          escape = true;
          return false;
        }

        return true;
      });

      if (escape) return;

      slateStruct.children = [
        ...slateStruct.children,
        {
          text:
            index + 1 === textArr.length ? `${textElement}` : `${textElement} `, // ignore adding spaces if last item in array
        },
      ];
    });
    slateStruct.type = 'paragraph';
    slateArr = [...slateArr, slateStruct];
    return slateArr;
  };

  const displayNote = (
    <div key={uniqueId} className="notes-text-area">
      <div className="notes-card-heading">
        <p>
          <Text text={createdBy} type="subtitle" className="bold" />
          <span className="created-at-notes">
            {createdAt !== 'now'
              ? dateTime(createdAt, ' dd MMM uuuu @ hh:mma', primaryTimezone)
              : ' just now '}
            {getDateTimeFromString(createdAt) !==
              getDateTimeFromString(lastModifiedAt) && createdAt !== 'now' ? (
              <Tooltip
                placement="top"
                title={dateTime(
                  lastModifiedAt,
                  'dd MMM uuuu @ hh:mma',
                  primaryTimezone,
                )}
              >
                (edited)
              </Tooltip>
            ) : (
              ''
            )}
          </span>
        </p>
        {createdBy === userEmail && (
          <div
            className={`${
              hasEditNotesScope ? '' : styles.disabled
            } edit-notes-icons`}
          >
            <Icon
              className="cursor-pointer"
              onClick={setIsEditing(uniqueId)}
              type="edit"
            />
            <Icon
              className="cursor-pointer"
              onClick={() => {
                if (noteDeleted) return;
                handleDeleteNote(uniqueId);
                setNoteDeleted(true);
              }}
              type="deleteBlack"
            />
          </div>
        )}
      </div>
      <SlateMentions
        onSelect={(selection, type = 'user') => {
          let valueObj = {};
          if (type === 'user') {
            valueObj = {
              mentionTagData: selection.displayOption,
              actualMentionData: selection.actualOption,
            };
          } else {
            valueObj = {
              mentionTagData: selection.displayOption,
              actualMentionData: {
                name: selection.displayOption,
                _id: selection.actualOption,
              },
            };
          }
          valueObj.type = type;
          mentioned.current.add(valueObj);
        }}
        // eslint-disable-next-line no-return-assign
        onChange={changedText => {
          editedText.current = changedText;
        }}
        defaultValue={generateSlateInitialValue(
          // prevent the new line html element from rendering into dom
          text.replace(/(\n )/g, ''),
          mentionedEmailsProps,
          mentionedScorecardsProps,
        )}
        autoCompleteOff
        readOnly
      />
    </div>
  );

  const editedNote = (
    <div key={uniqueId} className="notes-text-area">
      <SlateMentions
        onSelect={(selection, type = 'user') => {
          let valueObj = {};
          if (type === 'user')
            valueObj = {
              mentionTagData: selection.displayOption,
              actualInfo: selection.actualOption,
            };
          else
            valueObj = {
              mentionTagData: selection.displayOption,
              actualMentionData: {
                name: selection.displayOption,
                _id: selection.actualOption,
              },
            };
          valueObj.type = type;
          mentioned.current.add(valueObj);
        }}
        // eslint-disable-next-line no-return-assign
        onChange={changedText => {
          editedText.current = changedText;
        }}
        defaultValue={generateSlateInitialValue(
          text,
          mentionedEmailsProps,
          mentionedScorecardsProps,
        )}
        scorecardList={scorecardList}
        autoCompleteOff
        autoFocus
      />
      <div className={styles.editActionBtns}>
        <Button onClick={setIsEditing(null)}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            const mentionsEmail = Array.from(mentioned.current).filter(
              mention =>
                editedText.current.includes(`@${mention.mentionTagData}`) &&
                mention.type === 'user',
            );
            const mentionsScorecard = Array.from(mentioned.current).filter(
              mention =>
                editedText.current.includes(`@${mention.mentionTagData}`) &&
                mention.type === 'scorecard',
            );
            const mentionedEmails = mentionsEmail.map(
              mention => mention.actualMentionData,
            );
            const mentionedScorecards = mentionsScorecard.map(
              mention => mention.actualMentionData,
            );
            handleEditNote(
              editedText.current,
              uniqueId,
              [...mentionedEmails],
              [...mentionedScorecards],
            );

            mentioned.current = new Set();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <div className={`${deletingNote ? styles.notes_text_area_deleted : ''}`}>
      {isEditing ? editedNote : displayNote}
    </div>
  );
};

const NotesModal = ({ visible = false, setShowModal, notes }) => (
  <Modal
    visible={visible}
    style={{ top: 20 }}
    width="750px"
    bodyStyle={{
      // maxHeight: 600,
      overflowY: 'hidden',
      display: 'grid',
    }}
    onCancel={() => {
      setShowModal(false);
    }}
    confirmLoading={false}
    footer={null}
    destroyOnClose
    centered
    closable
  >
    <Icon
      className={styles.modalIlluatration}
      type="notesIllustraion"
      showPointer={false}
    />
    <Text text="Notes" type="modal-title" />
    <div>
      <Divider className={styles.spacingMain} />
      <div className={styles.scrollableNotes}>{notes}</div>
    </div>
  </Modal>
);

SingleCallNotes.propTypes = {
  callId: PropTypes.string.isRequired,
  scorecardList: PropTypes.array,
};

Note.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  lastModifiedAt: PropTypes.string,
  text: PropTypes.string,
  uniqueId: PropTypes.string,
  onSelect: PropTypes.func,
  mentionEmailsProps: PropTypes.array,
  mentionedScorecardsProps: PropTypes.array,
  handleEditNote: PropTypes.func,
  mentiosList: PropTypes.array,
  callId: PropTypes.string,
  scorecardList: PropTypes.array,
  deletingNote: PropTypes.bool,
};

NotesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  notes: PropTypes.any,
};

export default SingleCallNotes;
