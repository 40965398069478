/**
 *
 * MetaView - Parse Meta data view
 *
 *
 */

import React, { useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import message from 'utils/message';
import SkeletonLoader from 'components/SkeletonLoader';
import { numFormatting } from 'utils/commonFunctions';
import mixpanel from 'utils/mixpanel';
import useHover from 'utils/hooks/useHover';
import { Icon, Text } from 'components/common';
import Auth from 'auth0-react';
import { mixpanelKeys } from 'utils/mixpanelKeys';

import styles from './style.css';

const { isScopePresent } = new Auth();
const hasExportAudioCallsScope = isScopePresent('export_audio_calls');

function MetaView({
  listOfFields,
  isInCard = false,
  downloadUrl,
  onFieldClicked,
}) {
  /**
   * Using cached network call to get data from react-query.
   * */
  const queryClient = useQueryClient();
  const { numRows } = queryClient.getQueryData(['appConfig']);

  const getMetaView = useMemo(() => {
    let list = [...listOfFields];
    if (list.length < 2 && !isInCard) {
      return <SkeletonLoader type="meta" repeat={(numRows + 1) * 2} />;
    }

    /**
     * Rendering extra data if metaData is in card, else, shows less Information.
     * */
    if (isInCard) list = list.filter(l => l.field !== 'alternate_phone');
    const metaViewList = list.map(metaDataField => {
      let field = metaDataField.value ? metaDataField.value : '-';

      if (field !== '-') {
        switch (metaDataField.type) {
          case 'amount':
            field = `$${numFormatting(field)}`;
            break;
          case 'percent':
            field = `${field.toFixed(2)}%`;
            break;
          case 'date':
            field = moment(field).format(
              isInCard ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mma',
            );
            break;
          default:
            break;
        }
      }

      return (
        <MetaViewRow
          key={metaDataField.field}
          metaDataField={metaDataField}
          field={field}
          isInCard={isInCard}
          downloadUrl={downloadUrl}
          onFieldClicked={onFieldClicked}
        />
      );
    });

    return metaViewList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listOfFields), isInCard]);

  return <>{getMetaView}</>;
}

/**
 * MetaViewRow Component to allow details view on hover as well as a export button to download on local machines.
 * */
function MetaViewRow({
  metaDataField,
  field,
  isInCard,
  downloadUrl,
  onFieldClicked,
}) {
  const [hoverRef, isHovered] = useHover();
  const downloadField = metaDataField.title.toLowerCase().includes('filename');

  return (
    <div className={styles.detailsContainer} ref={!isInCard ? hoverRef : null}>
      <Text
        text={`${metaDataField.title} :`}
        type={isInCard ? 'paragrahlight' : 'paragrahlight'}
      />
      <span id="detailsContainerValue" className={styles.metadataFieldValue}>
        <Text
          onClick={
            onFieldClicked
              ? () => onFieldClicked(metaDataField.field, field)
              : null
          }
          text={field}
          className={
            ['borrowerAccount', 'phoneNumber'].includes(metaDataField.field)
              ? 'cursor-pointer hover-orange'
              : ''
          }
          type={isInCard ? 'paragrahlight' : 'paragraph'}
        />
        <span
          role="button"
          style={{
            visibility: isHovered && field !== '-' ? 'visible' : 'hidden',
          }}
          onClick={e => {
            // e.preventDefault(); // TODO: Commented out to fix audio download functionality
            e.stopPropagation();
          }}
          aria-hidden="true"
        >
          {!downloadField ? (
            <CopyToClipboard
              text={field}
              onCopy={() => {
                mixpanel(`Metadata Copied`, {
                  [mixpanelKeys.metadata]: metaDataField.field,
                });
                message.success('Copied to clipboard!!');
              }}
            >
              <Icon className={styles.copyIcon} type="copy" showPointer />
            </CopyToClipboard>
          ) : (
            <a
              onClick={() =>
                mixpanel('Download', {
                  [mixpanelKeys.callId]: metaDataField.value,
                })
              }
              className={hasExportAudioCallsScope ? styles.disabled : ''}
              href={hasExportAudioCallsScope ? downloadUrl : ''}
              download
            >
              <Icon type="download" size={16} />
            </a>
          )}
        </span>
      </span>
    </div>
  );
}

MetaView.propTypes = {
  listOfFields: PropTypes.array,
  isInCard: PropTypes.bool,
  downloadUrl: PropTypes.string,
  onFieldClicked: PropTypes.func,
};

MetaViewRow.propTypes = {
  metaDataField: PropTypes.object,
  field: PropTypes.string,
  isInCard: PropTypes.bool,
  downloadUrl: PropTypes.string,
  onFieldClicked: PropTypes.func,
};

export default MetaView;
