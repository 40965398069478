/**
 *
 * Popconfirm
 *
 */

import React from 'react';
import { Popconfirm as CustomPopconfirm } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function Popconfirm({ ...rest }) {
  return <CustomPopconfirm {...rest} />;
}

Popconfirm.propTypes = {};

export default Popconfirm;
