/**
 *
 * Popover
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Popover as CustomPopover } from 'antd';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Popover({
  title = '',
  content,
  position = 'top',
  overlayClassName,
  ...rest
}) {
  return (
    <CustomPopover
      title={title}
      content={content}
      placement={position}
      overlayClassName={overlayClassName}
    >
      {rest.children}
    </CustomPopover>
  );
}

Popover.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.string,
  overlayClassName: PropTypes.string,
};

export default Popover;
