import axios from 'axios';
import JSON5 from 'json5';

import mixpanel from 'utils/mixpanel';
import { extractKeys, mixpanelApiParamMap } from 'utils/mixpanelKeys';
import Auth from 'auth0-react';

import { notificationError } from './notification';
import { getBaseUrl, getPageName } from './commonFunctions';
const auth = new Auth();
const { isAuthenticated } = auth;

const fetch = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Content-Encoding': 'gzip',
  },
});

fetch.interceptors.response.use(
  response => {
    const event = 'API SUCCESS';
    let api = response.config.url;
    const type = response.config.method;
    const apiParts = api.split('/');
    let apiParam = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(API)) {
      if (value === api) {
        api = key;
      } else if (value === apiParts[0]) {
        api = key;
        apiParam = { [mixpanelApiParamMap[key]]: apiParts[1] };
      }
    }
    const body = response.config.data ? response.config.data : {};
    const requestBody = extractKeys(body); // Parse needed fields in body by filtering it against mixpanel keys
    const properties = getPageName();
    mixpanel(event, {
      ...requestBody,
      ...apiParam,
      api,
      type,
      ...properties,
    });

    // when the JSON is broken and is not able to parse then it comes as string, so will use JSON5 to parse those kind of responses
    if (
      response.headers['content-type'] === 'application/json' &&
      typeof response.data === 'string'
    ) {
      mixpanel('NaN Detected', {
        ...requestBody,
        ...apiParam,
        api,
        type,
        ...properties,
      });
      return { ...response, data: JSON5.parse(response.data) };
    }
    return response;
  },
  error => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.data.code === 'token_expired'
      ) {
        // auth.renewSession();
      } else {
        const event = 'API ERROR';
        let api = error.response.config.url;
        const type = error.response.config.method;

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(API)) {
          if (value === api) api = key;
        }
        const body = error.response.config.data
          ? JSON.parse(error.response.config.data)
          : {};
        const requestBody = extractKeys(body); // Parse needed fields in body by filtering it against mixpanel keys
        const properties = getPageName();
        mixpanel(event, {
          ...requestBody,
          api,
          type,
          status: error.response.status,
          ...properties,
        });
        if (error.response.data) {
          return Promise.reject(error);
        }
        if (error.response.status === 403) {
          notificationError('Unauthorized', error.response.status, true);
        }
      }
    }
    return Promise.reject(error);
  },
);

fetch.interceptors.request.use(
  config => {
    if (isAuthenticated()) {
      const accessToken = localStorage.getItem('access_token');
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${accessToken}`;
      const baseUrl = getBaseUrl('api');
      // eslint-disable-next-line no-param-reassign
      config.url = baseUrl + config.url;
    }
    return config;
  },
  error => Promise.reject(error),
);

export const fetcher = () => {
  const accessToken = localStorage.getItem('access_token');

  if (accessToken) {
    return fetch;
  }
  return {
    post: () => new Promise(resolve => resolve('')),
    get: () => new Promise(resolve => resolve('')),
    delete: () => new Promise(resolve => resolve('')),
    patch: () => new Promise(resolve => resolve('')),
    put: () => new Promise(resolve => resolve('')),
  };
};
export const getRequest = async url => fetcher().get(url);
export const postRequest = async (url, options) => fetcher().post(url, options);
export const patchRequest = async (url, options) =>
  fetcher().patch(url, options);
export const deleteRequest = async (url, options) =>
  fetcher().delete(url, options);
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}

export const API = {
  uploadAudioCall: `${getBaseUrl()}uploadMediaFile`,
  getAudioPath: `${getBaseUrl()}getAudioFile`,
  fetchSingleCall: 'singleCallDetailV2',
  fetchSingleCallV2: 'singleCallDetailV2',
  fetchCallsPageData: 'listAllCalls',
  fetchCalls: 'fetchCalls',
  getAgentList: 'getAgentList',
  fetchLeftPanelNumbers: 'getLeftPanelNumbers',
  fetchDashoardDetails: 'getDashboardDetails',
  customStatsSearch: 'search_v3/adhoc_stats',
  customSearch: 'search_v3/adhoc_search',
  customSearchNarrow: 'adhocSearchNarrow',
  getViolationList: 'getViolationList',
  getSearchSnippet: 'getSearchSnippet',
  fetchAgentWiseCalls: 'fetchAgentWiseCalls',
  fetchNonCompliantCalls: 'nonCompliantCalls',
  getCallProcessStatus: 'getCallProcessStatus',
  getTranscriptionConfigForCall: 'getTranscriptionConfigForCall',
  modifyCall: 'modifyCall',
  rerunTranscription: 'rerunTranscription',
  getEventsForReview: 'getEventsForReview',
  getGlobalTranscriptionConfig: 'getGlobalTranscriptionConfig',
  modifyGlobalTranscriptionConfig: 'modifyGlobalTranscriptionConfig',
  getTagList: 'getTagList',
  getSavedSearchesList: 'getSavedSearchesList',
  getSavedSearch: 'getSavedSearchV2',
  getSnippets: 'getSnippets',
  saveSnippets: 'saveValidatedSnippets',
  saveEvents: 'saveValidatedSnippets',
  getDialerServicesList: 'getDialerServicesList',
  getAgentResultList: 'getAgentResultList',
  getDialerCampaignList: 'getDialerCampaignList',
  getListsForSearchPage: 'getListsForFilters',
  modifytags: 'tags_v3/',
  gettags: 'tags_v3/',
  dummyApi: 'mirror_USHs2U8ks6',
  managetags: 'tags_v3/manage_tags',
  getVisualizations: 'get_visualizations_v2',
  downloadAudioFile: 'downloadAudioFile',
  getSmartReportList: 'get_smart_report_list_v4',
  createSmartReport: 'create_smart_report_v4',
  getSmartReportDetails: 'get_smart_report_details_v4',
  editSmartReport: 'edit_smart_report_v4',
  deleteSmartReport: 'smart_reports_v4',
  createEventsFromRegex: 'createEventsFromRegex',
  applyTagsToCalls: 'applyTagsToCalls',
  changePassword: 'changePassword',
  downloadMultipleAudioFile: 'download_multiple_audio_file_v2',
  downloadAudioFileArchive: 'downloadAudioFileArchive',
  reorderSmartReportUpdateStatus: 'reorder_and_update_status_v4',
  getSingleVisualization: 'getSingleVisualization',
  notes: 'notes',
  note: 'notes_v3',
  version: 'version/',
  accountView: 'account_view/',
  singleAccountView: 'account_view',
  accountViewFilters: 'account_view/list/filters',
  accountTags: 'account_tags/',
  recentSearch: 'search/recent',
  exportFiles: 'exportFiles_v3/',
  selectAll: 'select_all_v2/',
  getLeftNavConfig: 'init/getLeftNavConfig',
  getAgentVisualizations: 'agent_visualizations_v2/',
  getTenantUsers: 'tenants_v2/user_details',
  getTranscriptFile: 'calls_v3/call_transcript',
  annotations: 'annotations/',
  getUsers: 'tenants/user_list',
  scorecards: 'scorecards_v2',
  scorecardFilters: 'scorecard_search_v2/list/filters?templatesIds=',
  getAggregatedScorecardFilters:
    'scorecard_search_v3/list/aggregate_filters?templatesIds=',
  scorecardSearch: 'scorecard_search_v4/scorecard_search',
  exportScorecard: 'exportFiles_v3/scorecard',
  selectAllReviewScorecard: 'select_all_v2/scorecard',
  selectAllApproveScorecard: 'select_all_v2/approve_scorecard',
  selectAllAggregatedScorecard: 'select_all_v2/scorecard_aggregate',
  approveSelectedScorecard: 'scorecard_search_v3/approve',
  postNewScorecardTemplate: 'scorecards_v2/generate_template',
  updateScorecardTemplateStatus: 'scorecards_v2/update_template/status',
  getAggregatedScorecard: 'scorecard_search_v4/scorecard_aggregate',
  exportAggregatedScorecard: 'export_files_v4/scorecard_aggregate',
  exportPDFScorecard: 'scorecard_pdfs_v3/',
  exportAllPDFScorecard: 'scorecard_pdfs_v3/',
  getAllConfigs: 'config_v2/all',
  getConfig: 'config_v2',
  modifyConfig: 'config_v2/',
  uploadConfig: 'config_v2/thumbail_upload',
  singleCallFeedback: 'analytics_feedback_v2',
  mixpanel: 'mixpanel/',
  getAppConfig: 'app_config_v2/',
  getScorecardTemplateDetails: 'scorecard_search_v3/scorecard_template_details',
  getScorecartTemplateList: 'scorecard_search_v3/list/values',
  getAggregationDimension: 'getAggregationDimension',
  config: 'config_v2',
  inbox: 'inbox_v2',
  freshLogin: 'user/metadata',
  inboxCount: 'inbox_v2/count',
  inboxAll: 'inbox_v2/all',
  userMetaData: 'user-permissions',
};
