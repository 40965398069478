/*
 *
 * SmartReport constants
 *
 */
const dir = 'smartReport';
const constant = type => `prodigalVoice/${dir}/${type}`;
export default constant;
const FETCHING_ADHOC_SEARCH = constant('FETCHING_ADHOC_SEARCH');
const FETCHED_ADHOC_SEARCH = constant('FETCHED_ADHOC_SEARCH');
const ERROR_FETCHING_ADHOC_SEARCH = constant('ERROR_FETCHING_ADHOC_SEARCH');
const FETCH_ADHOC_SEARCH = constant('FETCH_ADHOC_SEARCH');
const FETCHING_FILTER = constant('FETCHING_FILTER');
const FETCHED_FILTER = constant('FETCHED_FILTER');
const ERROR_FETCHING_FILTER = constant('ERROR_FETCHING_FILTER');
const FETCH_FILTER = constant('FETCH_FILTER');
const CLEAR_SELECTION = constant('CLEAR_SELECTION');
const SET_STATS_PAGE_WIDTH = constant('SET_STATS_PAGE_WIDTH');
const RENAMED_SMART_REPORT = constant('RENAMED_SMART_REPORT');
const RENAME_SMART_REPORT = constant('RENAME_SMART_REPORT');
const ERROR_RENAMING_SMART_REPORT = constant('ERROR_RENAMING_SMART_REPORT');
const MODIFY_SMART_REPORT = constant('MODIFY_SMART_REPORT');
const CLEAR_SEARCH = constant('CLEAR_SEARCH');
const ERROR_MODIFYING_SMART_REPORT = constant('ERROR_MODIFYING_SMART_REPORT');
const MODIFIED_SMART_REPORT = constant('MODIFIED_SMART_REPORT');
const ON_CALL_SELECT = constant('ON_CALL_SELECT');
const ON_CHECKBOX_CLICKED = constant('ON_CHECKBOX_CLICKED');
const ON_SELECTALL_CHECKBOX_CHANGE = constant('ON_SELECTALL_CHECKBOX_CHANGE');
const ON_APPLY_TAGS = constant('ON_APPLY_TAGS');
const ONSORT_CLICKED = constant('ONSORT_CLICKED');
const HANDLE_SELECTALL = constant('HANDLE_SELECTALL');
const HANDLE_SHOW_STATS_CLICK = constant('HANDLE_SHOW_STATS_CLICK');
const MODIFY_SEARCH_CONFIG = constant('MODIFY_SEARCH_CONFIG');
const REORDER_STATS_DATA = constant('REORDER_STATS_DATA');
const SET_SEARCH_ID = constant('SET_SEARCH_ID');
const CANCEL_SEARCH = constant('CANCEL_SEARCH');
const RESET_SELECTED_CALLS = constant('RESET_SELECTED_CALLS');
const HANDLE_STATS_LOAD = constant('HANDLE_STATS_LOAD');
const HANDLE_FETCH_STATS = constant('HANDLE_FETCH_STATS');
const ERROR_FETCHING_ADHOC_CALL_STATS = constant(
  'ERROR_FETCHING_ADHOC_CALL_STATS',
);
export {
  dir,
  FETCHING_ADHOC_SEARCH,
  FETCHED_ADHOC_SEARCH,
  ERROR_FETCHING_ADHOC_SEARCH,
  FETCH_ADHOC_SEARCH,
  FETCHING_FILTER,
  FETCHED_FILTER,
  ERROR_FETCHING_FILTER,
  FETCH_FILTER,
  CLEAR_SELECTION,
  SET_STATS_PAGE_WIDTH,
  RENAMED_SMART_REPORT,
  RENAME_SMART_REPORT,
  ERROR_RENAMING_SMART_REPORT,
  MODIFY_SMART_REPORT,
  CLEAR_SEARCH,
  ERROR_MODIFYING_SMART_REPORT,
  MODIFIED_SMART_REPORT,
  ON_CALL_SELECT,
  ON_CHECKBOX_CLICKED,
  ON_SELECTALL_CHECKBOX_CHANGE,
  ON_APPLY_TAGS,
  ONSORT_CLICKED,
  HANDLE_SELECTALL,
  HANDLE_SHOW_STATS_CLICK,
  MODIFY_SEARCH_CONFIG,
  REORDER_STATS_DATA,
  SET_SEARCH_ID,
  CANCEL_SEARCH,
  RESET_SELECTED_CALLS,
  HANDLE_STATS_LOAD,
  HANDLE_FETCH_STATS,
  ERROR_FETCHING_ADHOC_CALL_STATS,
};
