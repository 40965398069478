import { getRequest, API } from 'utils/request';
const data = {
  unique_tags: [
    {
      _id: 'automatic/violation:Do not call',
      color: 'volcano',
      description: 'This is a wonderful Tag!',
      displayPriority: 10,
      group: 'this is a very long group name im not su',
      name: 'violation:Do not call',
      type: 'user',
    },
    {
      _id:
        'custom tag/This is a Tag with a long name, just look how long it is!  Ridiculous!!!!',
      color: 'orange',
      description:
        'Sangram: edited the ridiculous long name of the tag to check what happens when we reduce the length. Pretty clean, works!',
      displayPriority: 10,
      group: 'custom tag',
      name: 'This is a Tag with a long name',
      type: 'user',
    },
    {
      _id: 'automatic/violation:MMD',
      color: 'red',
      description: "Violated MMD?  That's a paddlin'.",
      displayPriority: 10,
      group: 'automatic',
      name: 'violation:MMD',
      type: 'user',
    },
    {
      _id: 'custom tag/Please Review Me',
      color: 'orange',
      displayPriority: 10,
      group: 'custom tag',
      name: 'Please Review Me',
      type: 'user',
    },
  ],
};
const fetchAllTags = async () => {
  const res = await getRequest(`${API.gettags}`);
  if (window.Cypress) {
    return data.unique_tags;
  }
  return res.data.data.unique_tags;
};

// const data = {
//   "unique_tags":
//   [{
//      "_id":"automatic/violation:Do not call",
//      "color":"volcano",
//      "description":"This is a wonderful Tag!",
//      "displayPriority":10,
//      "group":"this is a very long group name im not su",
//      "name":"violation:Do not call",
//      "type":"user"
//     }]
// }
// }
// const tag = ()=>{
//   return data.unique_tags};

// if(window.Cypress)
// {
// export {tag}}
// if(!window.Cypress){

export { fetchAllTags };
