/**
 *
 * Component: QuestionnaireFreeform
 * Date: 20/7/2020
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text, Input } from 'components/common';

import style from './style.css';
function QuestionnaireFreeform({ question, onChange, sectionName, editable }) {
  const [textCount, settextCount] = useState(question.value?.length);
  const [text, setText] = useState(question.value);
  if (window.Cypress) {
    window.textCount = textCount;
  }
  useEffect(() => {
    if (question.value) {
      setText(question.value);
      settextCount(question.value.length);
    }
  }, [question.value]);

  return (
    <div className={style.textAreaQuestion}>
      <Input
        data-testid="input-cy"
        maxLength={4000}
        id={question.name + sectionName}
        enableAutoLength={question.name + sectionName}
        disabled={!editable}
        type="text-area"
        defaultValue={question.value}
        value={text}
        autoSize={{ minRows: 2, maxRows: 10 }}
        onChange={e => {
          settextCount(e.target.value.length);
          setText(e.target.value);
        }}
        onBlur={() => onChange(text, question)}
      />
      <div className={style.counter}>
        <Text type="TagGrey" text={`${textCount || 0} / 4000 `} />
      </div>
    </div>
  );
}

QuestionnaireFreeform.propTypes = {
  question: PropTypes.func,
  onChange: PropTypes.func,
  sectionName: PropTypes.string,
  editable: PropTypes.bool,
};

export default QuestionnaireFreeform;
