/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
const dir = 'app';
const constant = type => `prodigalVoice/${dir}/${type}`;
const HANDLE_MODAL_VISIBLE = constant('HANDLE_MODAL_VISIBLE');
const HANDLE_USER = constant('HANDLE_USER');
const CHANGE_NEW_VERSION = constant('CHANGE_NEW_VERSION');
const CHANGE_ONLINE = constant('CHANGE_ONLINE');
const HANDLE_CANCLE_BUTTON = constant('HANDLE_CANCLE_BUTTON');
const CHANGE_ACCESS_TOKEN = constant('CHANGE_ACCESS_TOKEN');
const ADMIN_PATHS = [
  '/users',
  '/configs',
  '/manage-tags',
  '/scorecard-templates',
  '/user-permission',
];

export {
  dir,
  HANDLE_MODAL_VISIBLE,
  HANDLE_USER,
  CHANGE_NEW_VERSION,
  CHANGE_ONLINE,
  HANDLE_CANCLE_BUTTON,
  CHANGE_ACCESS_TOKEN,
  ADMIN_PATHS,
};
