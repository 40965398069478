/**
 *
 * Tooltip
 *
 */

import React from 'react';
import { Tooltip as CustomTooltip } from 'antd';
import PropTypes from 'prop-types';

import {
  BUTTON_TOOLTIP_DELAY,
  ICON_TOOLTIP_DELAY,
  TEXT_TOOLTIP_DELAY,
  ICON_TOOLTIP,
  TEXT_TOOLTIP,
  BUTTON_TOOLTIP,
} from 'utils/commonConstants';

function Tooltip({ type, disabled = false, ...rest }) {
  switch (type) {
    case ICON_TOOLTIP:
      return (
        <CustomTooltip
          {...rest}
          mouseEnterDelay={ICON_TOOLTIP_DELAY}
          title={disabled ? '' : rest.title}
        />
      );
    case TEXT_TOOLTIP:
      return (
        <CustomTooltip
          {...rest}
          mouseEnterDelay={TEXT_TOOLTIP_DELAY}
          title={disabled ? '' : rest.title}
        />
      );
    case BUTTON_TOOLTIP:
      return (
        <CustomTooltip
          {...rest}
          mouseEnterDelay={BUTTON_TOOLTIP_DELAY}
          title={disabled ? '' : rest.title}
        />
      );

    default:
      return <CustomTooltip {...rest} title={disabled ? '' : rest.title} />;
  }
}

Tooltip.propTypes = { type: PropTypes.string, disabled: PropTypes.bool };

export default Tooltip;
