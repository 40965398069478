const SET_TALK_METRICS = 'setTalkMetrics';
const CREATE_SPANS = 'createOvertalkSilenceSpans';
const SET_SOURCE_URL = 'setSourceURL';
const CREATE_AUDIO_BARS = 'createAudioBars';
const CHANGE_PLAY_RATE = 'changePlaybackRate';
const CHANGE_VOLUME = 'changeVolume';
const SET_HOVER_STATES = 'sethoverStates';
const SET_SKIP_SILENCE = 'setSkipSilence';
const OVERTALK_COLOR = '#d0021b';
const SILENCE_COLOR = '#FFD401';

export {
  SET_HOVER_STATES,
  SET_SKIP_SILENCE,
  SET_TALK_METRICS,
  CREATE_SPANS,
  SET_SOURCE_URL,
  CREATE_AUDIO_BARS,
  CHANGE_PLAY_RATE,
  CHANGE_VOLUME,
  OVERTALK_COLOR,
  SILENCE_COLOR,
};
