/**
 *
 * Component: VisualConfigCard
 * Date: 14/7/2020
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDrag, useDrop } from 'react-dnd';

import { API } from 'utils/request';
import {
  Input,
  Card,
  Text,
  Switch,
  Modal,
  Avatar,
  Icon,
  FileUploader,
} from 'components/common';
import { getBaseUrl } from 'utils/commonFunctions';

import style from './style.css';

/**
 * Exceptional Component making an API calls.
 */
function VisualConfigCard({
  handleDrop,
  visual,
  visualIndex,
  handleRefChange,
  deleteVisual,
  acceptDropList,
  fetchList,
}) {
  const [visualState, setVisualState] = useState(visual);
  const [loading, setLoading] = useState(false);
  const [visibleConfirm, setvisibleConfirm] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    item: { type: visualState.id, index: visualIndex },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    setVisualState(visual);
  }, [visual]);

  // currently having issues with acceptDropList updation

  const [{ isOver }, drop] = useDrop({
    accept: acceptDropList,
    drop: item => handleDrop(item.index, visualIndex),
    collect: mon => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
    }),
  });

  const handleInputChange = (e, type) => {
    handleRefChange(e, type);
  };

  /**
   * Gets Base64 after setting FileReaderAPI.
   * @param {*} img : Image data.
   * @param {*} callback : Callback to use results, after read.
   */
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Sets the loading indicator for file upload else, on completion, gets the Base64.
   * @param {Object} info : FileUpload Info:
   * @returns nothing.
   */
  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      /**
       * Get this url from response in real world.
       * */
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false);
        const newVisualState = _.cloneDeep(visualState);
        /**
         * to display image on upload without getting it from S3 bucket
         * */
        newVisualState.thumbnailUrl = imageUrl;
        setVisualState(newVisualState);
        fetchList();
      });
    }
  };

  const uploadButton = (
    <div className={style.uploadButton}>
      <Icon size={30} type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div key={visualState.id} className={isDragging ? style.isDragging : ''}>
      <div ref={drop} className={isOver ? style.isOver : ''}>
        <Card className={style.visualConfigCard}>
          <div className={style.deleteIcon}>
            <Icon
              size={20}
              type="delete"
              onClick={() => visual.id && setvisibleConfirm(visual.id)}
            />
          </div>
          <div ref={drag} className={style.isDraggable} />
          <div className={style.visualConfigThumbnail}>
            <FileUploader
              acceptType="png,jpg,jpeg"
              uploadButton={
                visualState.thumbnailUrl && !loading ? (
                  <Avatar
                    size={280}
                    shape="square"
                    src={visualState.thumbnailUrl}
                  />
                ) : (
                  uploadButton
                )
              }
              name="avatar"
              listType="picture-card"
              showUploadList={false}
              action={`${getBaseUrl()}${API.uploadConfig}?thumbnail_id=${
                visualState.id
              }&config_id=${window.location.pathname.replace(
                '/configs/',
                '',
              )}&token=${`Bearer ${localStorage.getItem('access_token')}`}`}
              onChange={handleChange}
            />
          </div>
          <div className={style.visualDetails}>
            <div>
              <Text text="ID" type="label" />
              <Input
                onChange={e => handleInputChange(e, 'id')}
                defaultValue={visualState.id}
              />
            </div>
            <div className={style.switchDiv}>
              <Text text="Public" type="label" />
              <Switch
                size="small"
                onChange={e =>
                  handleInputChange({ target: { value: e } }, 'isPublic')
                }
                defaultChecked={visualState.isPublic}
              />
            </div>
            <div>
              <Text text="Name" type="label" />
              <Input
                onChange={e => handleInputChange(e, 'name')}
                defaultValue={visualState.name}
              />
            </div>
            <div>
              <Text text="Tableau URL" type="label" />
              <Input
                onChange={e => handleInputChange(e, 'url')}
                defaultValue={visualState.url}
              />
            </div>
          </div>
        </Card>
      </div>
      <Modal
        title={`confirm delete ${visibleConfirm}?`}
        visible={visibleConfirm}
        onAccept={() => {
          deleteVisual(visibleConfirm);
          setvisibleConfirm(false);
        }}
        onCancel={() => setvisibleConfirm(false)}
        footer
        acceptButtonText="Confirm"
        width="500px"
      />
    </div>
  );
}

VisualConfigCard.propTypes = {
  handleDrop: PropTypes.func,
  handleRefChange: PropTypes.func,
  deleteVisual: PropTypes.func,
  visual: PropTypes.object,
  visualIndex: PropTypes.number,
  acceptDropList: PropTypes.array,
  fetchList: PropTypes.func,
};

export default VisualConfigCard;
