/**
 *
 * Component: EventsTab
 * Date: 4/4/2022
 *
 */

// import styles from './style.css'

import PropTypes from 'prop-types';
import React, { useEffect, useState, forwardRef, useRef } from 'react';

import { groupCallEvents, formatTimeAddPadding } from 'utils/commonFunctions';
import {
  Text,
  List,
  Tag,
  Button,
  Icon,
  Tooltip,
  Popover,
} from 'components/common';
import Reactions from 'components/Reactions';
import Auth from 'auth0-react';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import mixpanel from 'utils/mixpanel';
import useElementSize from 'utils/hooks/useElementSize';
import { FIXED_HEIGHT_TAGBOX } from 'utils/commonConstants';

import { AUDIO_JUMP_SLACK } from './constants';
import styles from './style.css';
const { isScopePresent } = new Auth();
const hasFeedbackEvents = isScopePresent('feedback_tags');

const EventsTab = forwardRef(
  (
    {
      call,
      loading,
      handleReaction,
      HowlerPlayId,
      setIsPlaying,
      showMore,
      showExtraMetaData,
      showExpandTranscript,
    },
    HowlerRef,
  ) => {
    const [processedEvents, setProcessedEvents] = useState([]);
    const [analyticsFeedback, setAnalyticsFeedback] = useState(
      call.analyticsFeedback || [],
    );
    const eventsTabRef = useRef();
    const { height: elementHeight, width: elementWidth } = useElementSize(
      eventsTabRef,
    );

    useEffect(() => {
      if (eventsTabRef.current?.style) {
        eventsTabRef.current.style.display = 'inline-block';
      }
      if (
        eventsTabRef.current?.getBoundingClientRect()?.height <
          FIXED_HEIGHT_TAGBOX &&
        eventsTabRef.current?.style
      ) {
        eventsTabRef.current.style.display = 'none';
      }
    }, [
      elementHeight,
      elementWidth,
      showMore,
      showExtraMetaData,
      showExpandTranscript,
    ]);

    useEffect(() => {
      setAnalyticsFeedback(call.analyticsFeedback || []);
    }, [call]);

    useEffect(() => {
      if (call.groupEvents) {
        setProcessedEvents(groupCallEvents(call.events));
      } else {
        setProcessedEvents(call.events);
      }
    }, [call.events, call.groupEvents]);

    /**
     * Handles click when we want to change audio time
     * @param {*} event details of the event
     */
    const handleEventClick = event => {
      const time = event.stime - AUDIO_JUMP_SLACK;

      if (HowlerRef.current.playing(HowlerPlayId.current || null)) {
        HowlerRef.current.seek(time);
      } else {
        if (HowlerPlayId.current) {
          HowlerRef.current.seek(time);
          HowlerRef.current.play(HowlerPlayId.current);
        }
        if (!HowlerPlayId.current) {
          HowlerRef.current.seek(time);
          // eslint-disable-next-line no-param-reassign
          HowlerPlayId.current = HowlerRef.current.play();
        }
        setIsPlaying(true);
        mixpanel('Clicked on event', {
          [mixpanelKeys.callId]: call.id,
          [mixpanelKeys.eventType]: event.eventType,
        });
      }
    };

    /**
     * helps in capturing feedback for the accuracy of events
     * @param {*} id
     * @returns
     */
    const getReactionData = id => {
      const idx = analyticsFeedback.findIndex(
        feedback => feedback?.capsule?.name === id,
      );
      if (idx !== -1) return analyticsFeedback[idx];
      return {};
    };

    /**
     * gets relevant feedback Icon
     */
    const getReactionIcon = id => {
      const reactionIconMap = {
        UP: <Icon size={10} type="thumbsUpFilled" />,
        DOWN: <Icon size={10} type="thumbsDownFilled" />,
        NONE: null,
      };
      const idx = analyticsFeedback?.findIndex(
        feedback => feedback?.capsule?.name === id,
      );
      if (idx !== -1)
        return reactionIconMap[analyticsFeedback[idx]?.vote || 'NONE'];
      return reactionIconMap.NONE;
    };

    /**
     * show popover only when user has required permissions
     */

    const getEventByAccess = (
      eventType,
      getReactionArg,
      handleReactionArg,
      tagNode,
    ) => {
      if (hasFeedbackEvents)
        return (
          <Popover
            content={
              <>
                <div className={styles.titleText}>
                  <Text type="subtitle" text={eventType} />
                </div>
                <div className={styles.reactionIcons}>
                  <Reactions
                    {...getReactionData(getReactionArg)}
                    onReaction={handleReaction('Event', handleReactionArg)}
                  />
                </div>
              </>
            }
          >
            {tagNode}
          </Popover>
        );
      return tagNode;
    };

    /**
     * used to set state to show children are visible
     */

    const handleParentEventClick = event => {
      const events = [...processedEvents];
      events.map(e => {
        if (e.eventType === event.eventType) {
          // eslint-disable-next-line no-param-reassign
          e.showChildren = !e.showChildren;
        }
        return null;
      });
      setProcessedEvents(events);
    };

    /**
     * List of events when they have a etime is defined
     * @param {*} event main/parent event
     * @returns List of Top level events
     */

    const showEventList = event =>
      event.children && event.children.length ? (
        <List.Item>
          <div
            style={{
              display: 'inline-block',
              width: '100%',
            }}
          >
            <Button
              onClick={() => {
                handleParentEventClick(event);
              }}
              className={styles.eventItem}
            >
              <div>
                <div className={styles.eventDuration}>
                  {`${formatTimeAddPadding(event.stime)}`}
                </div>
                <div className={styles.eventType}>
                  <Tag className="cursor-pointer" color={event.color || 'blue'}>
                    {event.eventType}
                  </Tag>
                </div>
                <div>
                  {event.showChildren ? (
                    <Icon
                      width={1.5}
                      height={1.5}
                      className={styles.parentEvent}
                      type="plusCircle"
                    />
                  ) : (
                    <Icon className={styles.parentEvent} type="minusCircle" />
                  )}
                </div>
              </div>
            </Button>
            {event.showChildren ? showChildren(event) : null}
          </div>
        </List.Item>
      ) : (
        <List.Item onClick={() => handleEventClick(event)}>
          <Tooltip placement="right" title={event.phrase}>
            <Button className={styles.eventItem}>
              <div>
                <div className={styles.eventDuration}>
                  {`${formatTimeAddPadding(event.stime)}`}
                </div>
                {getEventByAccess(
                  event.eventType,
                  `${event.eventType}-${event.stime}-${event.etime}`,
                  `${event.eventType}-${event.stime}-${event.etime}`,
                  <div className={styles.eventType}>
                    <Tag
                      className="cursor-pointer"
                      color={event.color || 'blue'}
                    >
                      {`${event.eventType} `}
                      {getReactionIcon(
                        `${event.eventType}-${event.stime}-${event.etime}`,
                      )}
                    </Tag>
                  </div>,
                )}
                <div />
              </div>
            </Button>
          </Tooltip>
        </List.Item>
      );

    /**
     * shows children in case of nested events
     * @param {*} event parent event
     * @returns List of child events
     */

    const showChildren = event => (
      <List
        style={{ marginLeft: '15px' }}
        dataSource={event.children}
        renderItem={childEvent => (
          <List.Item onClick={() => handleEventClick(childEvent)}>
            <Tooltip placement="right" title={childEvent.phrase}>
              <Button className={styles.eventItem}>
                <div>
                  <div className={styles.eventDuration}>
                    {`${formatTimeAddPadding(childEvent.stime)}`}
                  </div>

                  {getEventByAccess(
                    event.eventType,
                    `${childEvent.eventType}-${childEvent.stime}-${
                      childEvent.etime
                    }`,
                    `${childEvent.eventType}-${childEvent.stime}-${
                      childEvent.etime
                    }`,
                    <div className={styles.eventType}>
                      <Tag
                        className="cursor-pointer"
                        color={childEvent.color || 'blue'}
                      >
                        {`${childEvent.eventType} `}
                        {getReactionIcon(
                          `${childEvent.eventType}-${childEvent.stime}-${
                            childEvent.etime
                          }`,
                        )}
                      </Tag>
                    </div>,
                  )}
                </div>
              </Button>
            </Tooltip>
          </List.Item>
        )}
      />
    );

    /**
     * List item to be shown when even etime is not defined
     * @param {*} event details of event
     * @returns list event disabled
     */

    const showDisabledEvent = event => (
      <List.Item disabled>
        <Button className={styles.eventItem} style={{ cursor: 'default' }}>
          <div>
            <div className={styles.eventDuration}>--:--</div>
            <div className={styles.eventType}>
              <div>
                {getEventByAccess(
                  event.eventType,
                  `${event.eventType}-disabled`,
                  `${event.eventType}-disabled`,
                  <Tag className="cursor-pointer" color={event.color || 'blue'}>
                    {`${event.eventType} `}
                    {getReactionIcon(`${event.eventType}-disabled`)}
                  </Tag>,
                )}
              </div>
            </div>
            <div />
          </div>
        </Button>
      </List.Item>
    );

    return (
      <>
        <div className={styles.events} ref={eventsTabRef}>
          <div className={styles.eventsHeading}>
            <Text
              text={`Events ${
                call.events?.length ? `(${call.events?.length})` : ''
              }`}
              type="subtitle"
              className="bold"
            />
          </div>
          <List
            dataSource={[
              ...(call.violations
                ? call.violations.map(v => ({ eventType: v }))
                : []),
              ...(!processedEvents ? [] : processedEvents),
            ]}
            locale={{
              emptyText: loading ? 'Fetching Events' : 'No Event',
            }}
            renderItem={event =>
              event.etime ? showEventList(event) : showDisabledEvent(event)
            }
          />
        </div>
      </>
    );
  },
);

export default EventsTab;

EventsTab.propTypes = {
  call: PropTypes.object,
  loading: PropTypes.bool,

  handleReaction: PropTypes.func,
  HowlerPlayId: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  setIsPlaying: PropTypes.func,
  dimensions: PropTypes.object,
  showMore: PropTypes.bool,
  setShowMore: PropTypes.object,
  showExtraMetaData: PropTypes.bool,
  showExpandTranscript: PropTypes.bool,
};
