import AddTag from './add-tag.svg';
import Export from './export.svg';
import Edit from './edit.svg';
import Link from './link.svg';
import Tick from './tick.svg';
import Close from './close.svg';
import CloseOrange from './close-orange.svg';
import CopyBlue from './copy-blue.svg';
import DownloadBlue from './download-blue.svg';
import Delete from './delete.svg';
import DeleteBlack from './delete-black.svg';
import DownloadOrange from './download-orange.svg';
import ArrowLeft from './arrow-left.svg';
import SiderArrowLeft from './arrow-sider-left.svg';
import SiderArrowRight from './arrow-sider-right.svg';
import Search from './search.svg';
import Play from './play.svg';
import Plus from './plus.svg';
import Pause from './pause.svg';
import Notes from './notes.svg';
import NewVersion from './new-version.svg';
import VolumeDown from './volume-down.svg';
import VoulmeUp from './volume-up.svg';
import Volumenute from './volume-mute.svg';
import Volume from './volume.svg';
import Redo from './redo.svg';
import Undo from './undo.svg';
import MinusSign from './minus-sign.svg';
import External from './external.svg';
import ConfirmDelete from './confirm-delete.svg';
import ConfirmExport from './confirm-export.svg';
import EmptyState from './empty-state.svg';
import CallIcon from './call-phone.svg';
import CallIconGreen from './call-phone-green.svg';
import Accounts from './accounts-icon.svg';
import SmartReports from './smart-reports.svg';
import Admin from './admin.svg';
import Calls from './call-sider.svg';
import Visuals from './visuals.svg';
import Mail from './mail.svg';
import ChangePassword from './cog-change-password.svg';
import Logout from './logout.svg';
import ShowMore from './show-more.svg';
import InboundCall from './call-inbound.svg';
import OutboundCall from './call-outbound.svg';
import TickGreen from './tick-green.svg';
import CloseRed from './close-red.svg';
import MagicWand from './magic.svg';
import Preview from './preview.svg';
import Table from './table.svg';
import Aggregate from './aggregate.svg';
import Agent from './agents.svg';
import ThumbsUp from './thumbs-up.svg';
import ThumbsUpFilled from './thumbs-up-filled.svg';
import ThumbsDown from './thumbs-down.svg';
import ThumbsDownFilled from './thumbs-down-filled.svg';
import Send from './send.svg';
import Save from './save.svg';
import Printer from './printer.svg';
import FileText from './file.svg';
import DownloadBlack from './download-black.svg';
import Drag from './drag.svg';
import Up from './up.svg';
import UpArrow from './up-arrow.svg';
import Down from './down.svg';
import DownArrow from './down-arrow.svg';
import AreaChart from './area-chart.svg';
import Swap from './swap.svg';
import MinusCircle from './plus-circle.svg';
import PlusCircle from './minus-circle.svg';
import TickYellow from './tick-yellow.svg';
import Expand from './expand-two-arrows.svg';
import Collapse from './collapse-two-arrows.svg';
import ExclaimationMark from './exclamation-mark.svg';
import SwitchOn from './switch-on.svg';
import SwitchOff from './switch-off.svg';
import PlayBlack from './play-black.svg';
import PauseBlack from './pause-black.svg';
import Warning from './warning.svg';
import Help from './help.svg';
import Archive from './archive.svg';
import MarkRead from './mark-read.svg';
import MarkUnread from './mark-unread.svg';
import JoeyRefresh from './Joey-refresh.svg';
import JoeyHardReset from './joey-hard-reset.svg';
import CreateSmartReportIllustration from './create-smart-report-illustration.svg';
import ExportFileIllustration from './export-file-illustration.svg';
import ScoreCardIllustration from './scorecard-illustration.svg';
import NotesIllustration from './notes-illustration.svg';
import ChangePassward from './change-passward.svg';
import EditSmartReport from './edit-smart-report.svg';
import ChartStatsWhite from './chart-stats-white.svg';
import ChartStatsBlue from './chart-stats-blue.svg';
import StatsTimeout from './stats-timeout.svg';
import ExpandTranscript from './expand-transcript.svg';
import Update from './update.svg';

export default {
  update: Update,
  statsTimeout: StatsTimeout,
  chartStatsWhite: ChartStatsWhite,
  chartStatsBlue: ChartStatsBlue,
  editSmartReport: EditSmartReport,
  changePassward: ChangePassward,
  createSmartReportIllustration: CreateSmartReportIllustration,
  exportFileIllustration: ExportFileIllustration,
  scoreCardIllustration: ScoreCardIllustration,
  joeyRefresh: JoeyRefresh,
  joeyHardReset: JoeyHardReset,
  notesIllustraion: NotesIllustration,
  addTag: AddTag,
  export: Export,
  close: Close,
  closeRed: CloseRed,
  closeOrange: CloseOrange,
  copy: CopyBlue,
  downloadBlue: DownloadBlue,
  download: DownloadOrange,
  delete: Delete,
  deleteBlack: DeleteBlack,
  link: Link,
  tick: Tick,
  arrowLeft: ArrowLeft,
  siderArrowLeft: SiderArrowLeft,
  siderArrowRight: SiderArrowRight,
  search: Search,
  play: Play,
  undo: Undo,
  redo: Redo,
  pause: Pause,
  notes: Notes,
  volumeDown: VolumeDown,
  volumeUp: VoulmeUp,
  volumeMute: Volumenute,
  volume: Volume,
  minusSign: MinusSign,
  external: External,
  emptyState: EmptyState,
  confirmDelete: ConfirmDelete,
  confirmExport: ConfirmExport,
  callIcon: CallIcon,
  callIconGreen: CallIconGreen,
  newVersion: NewVersion,
  edit: Edit,
  idcard: Accounts,
  smartReports: SmartReports,
  admin: Admin,
  calls: Calls,
  visuals: Visuals,
  mail: Mail,
  settings: ChangePassword,
  logout: Logout,
  showMore: ShowMore,
  inboundCall: InboundCall,
  outboundCall: OutboundCall,
  plus: Plus,
  tickGreen: TickGreen,
  magicWand: MagicWand,
  preview: Preview,
  table: Table,
  aggregate: Aggregate,
  agent: Agent,
  thumbsUp: ThumbsUp,
  thumbsUpFilled: ThumbsUpFilled,
  thumbsDown: ThumbsDown,
  thumbsDownFilled: ThumbsDownFilled,
  send: Send,
  save: Save,
  printer: Printer,
  fileText: FileText,
  drag: Drag,
  downloadBlack: DownloadBlack,
  up: Up,
  arrowUp: UpArrow,
  down: Down,
  arrowDown: DownArrow,
  areaChart: AreaChart,
  swap: Swap,
  minusCircle: MinusCircle,
  plusCircle: PlusCircle,
  tickYellow: TickYellow,
  expand: Expand,
  collapse: Collapse,
  exclaimationMark: ExclaimationMark,
  switchOn: SwitchOn,
  switchOff: SwitchOff,
  playBlack: PlayBlack,
  pauseBlack: PauseBlack,
  warning: Warning,
  help: Help,
  archive: Archive,
  markRead: MarkRead,
  markUnread: MarkUnread,
  expandTranscript: ExpandTranscript,
};
