/**
 *
 * Component: SortBy
 * Date: 17/6/2020
 *
 */

import React, { useEffect, useState } from 'react';
import './style.css';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Icon, Button, Tooltip } from 'components/common';
import { BUTTON_TOOLTIP } from 'utils/commonConstants';

import SortSelection from '../SortSelection';

function SortBy({
  selectedSort,
  showFilterKey,
  useOriginalSortList,
  sortList,
  onClickOutside,
  onSortClicked,
  onSortApplied,
  loading,
}) {
  const [showFilterKeyState, setshowFilterKey] = useState(showFilterKey);
  const [sortListState, setsortListState] = useState(useOriginalSortList);

  useEffect(() => {
    if (!_.isEqual(sortListState, sortList)) {
      setsortListState(
        useOriginalSortList
          ? sortList
          : sortList.map(sortItem => ({
              sortType:
                sortItem.sortType ??
                sortListState?.find(
                  sortStateItem => sortStateItem._id === sortItem.dataIndex,
                )?.sortType ??
                1,
              name: sortItem.title,
              _id:
                sortItem.dataIndex === '_id'
                  ? sortItem.key
                  : sortItem.dataIndex,
              // added sortItem.key because usually dataIndex will be same as key but in aggregated dimension
              // dataIndex will be "_id" and key will have the actual value which will be useful in sort
            })),
      );
    }
  }, [sortList]);

  /**
   * selectes the items as selectedSort and has _id as filed.
   * @returns {JSX} renders a sorting button.
   */
  const getSortButtonLayout = () => {
    let selectedOrderItem = null;
    sortListState.forEach(sortItem => {
      if (
        selectedSort &&
        selectedSort[0] &&
        sortItem._id === selectedSort[0].field
      ) {
        selectedOrderItem = sortItem;
      }
    });
    return sortListState?.length ? (
      <Tooltip
        title="Click here to change sort orders"
        type={BUTTON_TOOLTIP}
        disabled={loading}
      >
        <Button
          id="sort"
          type="link"
          width="max-content"
          onClick={onSortClick}
          className="sort-btn-no-padding"
          disabled={loading}
        >
          Sort by{selectedOrderItem ? `: ${selectedOrderItem.name}` : ''}
          {selectedOrderItem ? (
            <Icon
              className="sort-img"
              size={12}
              useAntd
              type={
                selectedSort && selectedSort[0] && selectedSort[0].order === 1
                  ? 'arrowUp'
                  : 'arrowDown'
              }
            />
          ) : (
            ''
          )}
        </Button>
      </Tooltip>
    ) : null;
  };

  /**
   * sets the key, and based on showFilterKeyState based on outside click or sets SortClick as sort.
   * @param {*} e
   */
  const onSortClick = e => {
    e.stopPropagation();
    let key = '';
    if (showFilterKeyState === 'sort') {
      onClickOutside('sort');
      key = '';
    } else {
      onSortClicked('sort');
      key = 'sort';
    }
    setshowFilterKey(key);
  };

  /**
   * onShortApplied callback for SortSelection.
   * @param {String} key
   * @param {String} selectedValues : Id of selected item.
   *
   * @returns nothing.
   */
  const onSort = async (key, selectedValues) => {
    if (selectedValues) {
      let selectedSortItem = null;
      const newOldSortList = await sortListState.map(sortItem => {
        if (sortItem._id === selectedValues) {
          const newSortItem = { ...sortItem, sortType: -1 * sortItem.sortType };
          selectedSortItem = { ...newSortItem };
          return newSortItem;
        }
        return sortItem;
      });
      onSortApplied({
        field: selectedSortItem._id,
        order: selectedSortItem.sortType,
      });
      setsortListState(_.cloneDeep(newOldSortList));
      setshowFilterKey('');
    }
  };

  if (sortListState?.length > 0) {
    return (
      <div>
        <div className="">
          <div style={{ textAlign: 'end' }} data-testid="sortlist-cy">
            {getSortButtonLayout()}
            {showFilterKeyState === 'sort' && (
              <SortSelection
                key={showFilterKeyState}
                sortKeyId={showFilterKeyState}
                onClickOutside={onClickOutside}
                showSort
                selectedValue={
                  selectedSort?.length ? selectedSort[0].field : null
                }
                sortList={sortListState}
                onSortApplied={onSort}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return <div />;
}

export default SortBy;

SortBy.propTypes = {
  selectedSort: PropTypes.string,
  showFilterKey: PropTypes.bool,
  useOriginalSortList: PropTypes.array,
  sortList: PropTypes.array,
  onClickOutside: PropTypes.func,
  onSortClicked: PropTypes.func,
  onSortApplied: PropTypes.func,
  loading: PropTypes.bool,
};
