/**
 *
 * Table
 *
 */

import React from 'react';
import { Table as CustomTable } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Table({ ...rest }) {
  return (
    <CustomTable
      {...rest}
      pagination={
        rest.pagination ? { ...rest.pagination, showSizeChanger: false } : false
      }
    />
  );
}

Table.propTypes = {};

export default Table;
