/**
 *
 * Component: HeaderUserOptions
 * Date: 30/6/2021
 *
 */

import React, { useReducer } from 'react';
// import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useIntercom } from 'react-use-intercom';

import { stringCaptialize } from 'utils/commonFunctions';
import { Avatar, Text, Dropdown, Modal, Form, Icon } from 'components/common';
import mixpanelFunction, { mixpanel } from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import Auth from 'auth0-react';

import { postChangePassword } from './api';
import styles from './style.css';
const { logout } = new Auth();

/**
 * An Exception Component making a Network Request, as well as its own redux store.
 * Component for Entering User Information, in a popup modal form for password change.
 * */
function HeaderUserOptions() {
  const username = localStorage.getItem('user_name');
  const { hardShutdown } = useIntercom();

  const initialState = {
    items: [
      {
        type: 'password',
        placeholder: 'Current Password',
        key: 'origPassword',
        required: true,
      },
      {
        type: 'password',
        placeholder: 'New Password',
        key: 'newPassword',
        required: true,
        validator: 'validateToNextPassword',
      },
      {
        type: 'password',
        placeholder: 'Confirm Password',
        key: 'confirm',
        required: true,
        validator: 'compareToFirstPassword',
      },
    ],
    initialValues: {},
    newPassword: '',
    origPassword: '',
    changePasswordError: '',
    showChangePasswordModal: false,
    changingPassword: false,
  };

  function headerReducer(state, action) {
    switch (action.type) {
      case 'SET_PASSWORD_STATE': {
        const { origPassword, newPassword } = action.payload;
        return {
          ...state,
          origPassword,
          newPassword,
        };
      }
      case 'ERROR_FETCHING_CHANGEPASSWORD':
        return {
          ...state,
          changePasswordError:
            typeof action.payload === 'string'
              ? action.payload
              : 'something went wrong',
          changingPassword: false,
        };
      case 'SET_PASSOWRD_MODAL_STATE':
        return {
          ...state,
          showChangePasswordModal: action.payload,
          changePasswordError: '',
        };
      case 'FETCHED_CHANGEPASSWORD':
        return {
          ...state,
          showChangePasswordModal: false,
          changePasswordError: '',
          changingPassword: false,
        };
      case 'ON_SUBMIT':
        return { ...state, changingPassword: true };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(headerReducer, initialState);
  const {
    items,
    initialValues,
    changePasswordError,
    showChangePasswordModal,
  } = state;

  /**
   * API call to change password for the user, and update store on success.
   * */
  const { mutate: changePassword, isLoading: changingPassword } = useMutation(
    postChangePassword,
    {
      onSuccess: res => {
        if (res.data.response.error) {
          return dispatch({
            type: 'ERROR_FETCHING_CHANGEPASSWORD',
            payload:
              res.data.response?.message?.replace(
                'PasswordHistoryError:',
                '',
              ) || res.data.response?.error,
          });
        }
        return dispatch({ type: 'FETCHED_CHANGEPASSWORD', payload: res.data });
      },
    },
  );

  /**
   * Update Store state to either view modal or not.
   * */
  const setShowPasswordModal = e =>
    dispatch({ type: 'SET_PASSOWRD_MODAL_STATE', payload: e });

  /**
   * Event Handler for passwords change.
   * */
  const handleValues = v => {
    const { newPassword, origPassword } = v;
    changePassword({ newPassword, origPassword });
  };

  /**
   * Makes User logout based on index value being non-zero, else enables password modal.
   * */
  const onItemClicked = index => {
    if (index === 0) {
      dispatch({ type: 'SET_PASSOWRD_MODAL_STATE', payload: true });
    } else {
      logout(true);
      mixpanelFunction('Sidebar clicked', {
        [mixpanelKeys.iconName]: 'Logout',
        [mixpanelKeys.menuName]: 'Heading',
      });
      mixpanel.opt_out_tracking();
      hardShutdown();
    }
  };

  const dropdownButton = (
    <div className={styles.userProfile}>
      <Avatar
        size="small"
        className={styles.userAvatar}
        style={{
          marginRight: username ? 5 : 0,
        }}
      >
        {username?.charAt(0)?.toUpperCase()}
      </Avatar>
      {stringCaptialize(username)}
    </div>
  );
  return (
    <div className={styles.container}>
      <div className="dropdown">
        <Dropdown
          button={dropdownButton}
          placement="bottomRight"
          rowItems={[
            { name: 'Change Password', icon: 'settings' },
            { name: 'Logout', icon: 'logout' },
          ]}
          onRowItemClicked={onItemClicked}
        />
      </div>
      <Modal
        visible={showChangePasswordModal}
        destroyOnClose
        onCancel={() => {
          setShowPasswordModal(false);
        }}
      >
        {
          // TODO: Update error on form change - Requires form to be moved to functional comp
        }
        <Icon
          type="changePassward"
          className={styles.modalIllustration}
          showPointer={false}
        />
        <Text text="Change Password" type="modal-title" />
        <Text text={changePasswordError} type="Error" className="capitalize" />
        <div className="header-change-pass-modal">
          {items && (
            <Form
              layout="vertical"
              items={items}
              formName="Change Password"
              initialValues={initialValues}
              handleFormSubmit={handleValues}
              onClose={() => setShowPasswordModal(false)}
              loading={changingPassword}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

HeaderUserOptions.propTypes = {};

export default HeaderUserOptions;
