import React from 'react';
import PropTypes from 'prop-types';
import CustomIcon from '@ant-design/icons';

import iconMap from 'assets/icons/icon-map';

function Icon({
  type,
  size,
  color,
  onClick,
  useAntd = false,
  showPointer = true,
  ...rest
}) {
  const classNames = [];
  const customClassName = rest.className;
  let width = 16;
  let height = 16;
  if (size && typeof size === 'object') {
    ({ width, height } = size);
  } else if (size) {
    width = size;
    height = size;
  }
  const NewComponent = iconMap[type];
  if (customClassName) classNames.push(customClassName);
  const props = {
    ...rest,
    className: classNames.join(' '),
    height,
    width,
    color,
    cursor: showPointer ? 'pointer' : 'auto',
    onClick,
  };
  if (iconMap[type] && !useAntd) {
    return <NewComponent {...props} />;
    // return React.createElement('svg', iconMap[type], props);
  }

  // if(!iconMap[type] && !useAntd){

  // }

  return (
    <CustomIcon
      data-testid="icon-cy"
      className={classNames.join(' ')}
      style={{
        fontSize: `${size || 16}`,
        cursor: showPointer ? 'pointer' : 'auto',
        color,
      }}
      type={useAntd ? null : type}
      component={useAntd ? iconMap[type] : null}
      onClick={onClick}
      {...rest}
    />
  );
}

Icon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  color: PropTypes.string,
  onClick: PropTypes.func,
  useAntd: PropTypes.bool,
  showPointer: PropTypes.bool,
};

export default Icon;
