/**
 *
 * Sider
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Badge, Menu } from 'antd';
import _ from 'lodash';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import Auth from 'auth0-react';
import { Icon, Layout, Text, Button, Tooltip } from 'components/common';
import mixpanelFunction from 'utils/mixpanel';
import { PRODIGAL_LOGO_DARK, TEXT_TOOLTIP } from 'utils/commonConstants';

import styles from './style.module.css';
import actions from './actions';
import saga from './saga';
import reducer from './reducer';
import makeSelectSider from './selectors';
const { SubMenu } = Menu;
const { isAuthenticated } = new Auth();

export function Sider({
  handleCollapse,
  visuals,
  leftNavConfig,
  collapsed,
  defaultCollapsed,
  siderMenu,
  renderSidebar,
  runtime,
  setModal1Visible,
  cardVisible,
  inboxCounter,
}) {
  useInjectReducer({ key: 'sider', reducer });
  useInjectSaga({ key: 'sider', saga });

  const location = useLocation();
  const [hideSider, setHideSider] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  /**
   * Selects an initial option from the menu.
   */
  const handleInitialSelectedKey = useCallback(() => {
    const path = location.pathname;
    let isSiderLinkPresent = false;
    siderMenu.forEach(sider => {
      if (sider.path) {
        if (path.startsWith(sider.path)) {
          setSelectedKeys([sider.key]);
          isSiderLinkPresent = true;
        }
      } else {
        sider.subMenu.forEach(submenu => {
          if (path.startsWith(submenu.path)) {
            setSelectedKeys([submenu.key]);
            isSiderLinkPresent = true;
            const newOpenKeys = [
              ...openKeys.filter(o => o !== sider.key),
              sider.key,
            ];
            if (!_.isEqual(newOpenKeys, openKeys)) setOpenKeys(newOpenKeys);
          }
        });
      }
    });
    if (!isSiderLinkPresent) {
      setSelectedKeys([]);
    }
  }, [location, openKeys, siderMenu]);

  useEffect(() => {
    handleInitialSelectedKey();
  }, [location, handleInitialSelectedKey]);

  // Sets status of collapse for sidebar menu.
  useEffect(() => {
    localStorage.setItem('collapsed', collapsed);
  }, [collapsed]);

  useEffect(() => {
    renderSidebar(leftNavConfig);
  }, [collapsed, visuals, renderSidebar, leftNavConfig]);

  useEffect(() => {
    const urlSegments = location.pathname.split('/');
    if (urlSegments.length && checkForSegments(urlSegments)) setHideSider(true);
    else setHideSider(false);
  }, [location]);

  /**
   *
   * @param {Array} segments
   * @returns {Boolean} true if segments[1] is login or calls else false.
   */
  const checkForSegments = segments => {
    const excludeList = ['login', 'calls'];
    for (let i = 0; i < excludeList.length; i += 1) {
      const path = excludeList[i];
      if (segments[1] === path) return true;
    }
    return false;
  };

  // for not auth users no-sidebar.
  if (!isAuthenticated() || hideSider) {
    return <div id="no-sidebar-on-this-page" />;
  }
  // create a submenu in the sidebar.
  const menuList = siderMenu.map(menu =>
    createSubMenuList(menu, collapsed, inboxCounter),
  );

  /**
   * selects the selected option from sidebar.
   * @param {Object} selectedMenu
   */
  const onSelect = selectedMenu => {
    setSelectedKeys([selectedMenu.key]);
  };

  /**
   * sets the submenu selected from sidebar menu keys.
   * @param {Object} selectedOpenKeys : submenu keys.
   */
  const onOpenChange = selectedOpenKeys => {
    setOpenKeys(selectedOpenKeys);
  };
  return (
    <Layout.Sider
      collapsible
      defaultCollapsed={defaultCollapsed}
      collapsed={collapsed}
      trigger={null}
      className={`${
        collapsed ? styles.fixedSiderCollapsed : styles.fixedSider
      }`}
    >
      <div
        className={`${styles.logo} ${
          collapsed ? styles.centered : styles.leftAligned
        }`}
      >
        <div
          className={`${styles.logoImg} ${
            collapsed ? styles.hide : styles.show
          }`}
        >
          <img
            src={PRODIGAL_LOGO_DARK}
            className={styles.prodigalImage}
            alt="prodigal-logo"
          />
        </div>
        <div
          role="button"
          onClick={e => {
            e.stopPropagation();
            handleCollapse({
              collapsed: !collapsed,
              defaultCollapsed: !defaultCollapsed,
            });
          }}
          aria-hidden="true"
          className={styles.siderCollapseTrigger}
        >
          <Icon
            type="siderArrowLeft"
            size={10}
            rotate={collapsed ? 180 : 0}
            style={{
              fontSize: 10,
              display: 'block',
              padding: '2px',
            }}
            useAntd
          />
        </div>
      </div>
      {
        // custom openKeys functionality has some css bugs in Antd in collapsed state so not handling it in collapsed state
      }
      {collapsed ? (
        <Menu
          theme="dark"
          mode="inline"
          inlineIndent={24}
          onSelect={onSelect}
          selectable
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          inlineCollapsed={collapsed}
          triggerSubMenuAction="hover"
        >
          {menuList}
        </Menu>
      ) : (
        <Menu
          theme="dark"
          mode="inline"
          inlineIndent={24}
          onSelect={onSelect}
          selectable
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          inlineCollapsed={collapsed}
          triggerSubMenuAction="hover"
        >
          {menuList}
        </Menu>
      )}
      {cardVisible &&
        !['/login', '/callback'].includes(window.location.pathname) && (
          <div className={styles.newVersionMinimizedDiv}>
            <div className={styles.updateIcon}>
              <Icon
                size={20}
                style={{ marginTop: '-2px' }}
                type="update"
                onClick={() => {
                  runtime.applyUpdate();
                  setModal1Visible(false, 'modal');
                  setTimeout(() => window.location.reload(), 1000);
                  localStorage.setItem('newVersionInstalled', true);
                }}
              />
            </div>
            {collapsed ? (
              <></>
            ) : (
              <Text text="New Version Available" type="updateTextSider" />
            )}
            {!collapsed && (
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => {
                  runtime.applyUpdate();
                  setModal1Visible(false, 'modal');
                  setTimeout(() => window.location.reload(), 1000);
                  localStorage.setItem('newVersionInstalled', true);
                  // setTimeout(() => window.location.reload(), 1100);
                }}
              >
                Upgrade
              </Button>
            )}
          </div>
        )}
    </Layout.Sider>
  );
}
/**
 * Creates the submenu from the menu for the sidebar.
 * @param {Object} menu : menu object storing menu and subMenus info.
 * @param {Boolean} collapsed : sidebar collapsed or not.
 * @returns {JSX} for Menu.Item to renderer a submenu.
 */
function createSubMenuList(menu, collapsed, inboxCounter) {
  const getCounts = () => {
    if (menu.counter) {
      if (inboxCounter > 100) {
        return 99;
      }
      if (inboxCounter) {
        return inboxCounter;
      }
    }
    return null;
  };
  if (menu.subMenu && menu.subMenu.length > 0) {
    const subMenuLists =
      menu.subMenu.length &&
      menu.subMenu.map(subMenu => {
        if (subMenu.subMenu && subMenu.subMenu.length) {
          return createSubMenuList(subMenu);
        }
        return (
          <Menu.Item key={subMenu.key}>
            <Link
              onClick={() =>
                mixpanelFunction('Sidebar clicked', {
                  [mixpanelKeys.iconName]: menu.title,
                  [mixpanelKeys.menuName]: subMenu.title,
                })
              }
              to={{
                pathname: subMenu.path,
                state: { title: subMenu.title, isSmartReport: true },
              }}
            >
              <Text type="Sider" text={subMenu.title} />
            </Link>
          </Menu.Item>
        );
      });

    return (
      <SubMenu
        className="sider-sub-menu"
        key={menu.key}
        title={
          <>
            {menu.icon === 'custom' ? (
              <Icon
                component={menu.customIcon}
                size={20}
                className={styles.iconStyle}
              />
            ) : (
              <Icon type={menu.icon} size={20} className={styles.iconStyle} />
            )}
            <Tooltip
              title={menu.toolTip}
              type={TEXT_TOOLTIP}
              placement="bottomLeft"
            >
              {collapsed ? <></> : <Text type="Sider" text={menu.title} />}
            </Tooltip>
            {menu.new ? (
              <span
                className="new-icon"
                style={{
                  marginLeft: collapsed ? -74 : 3,
                  marginTop: collapsed ? -15 : 0,
                }}
              >
                New!
              </span>
            ) : null}
          </>
        }
      >
        {subMenuLists}
      </SubMenu>
    );
  }
  if (menu) {
    return (
      <Menu.Item key={menu.key}>
        <Link
          onClick={() =>
            mixpanelFunction('Sidebar clicked', {
              [mixpanelKeys.iconName]: menu.title,
              [mixpanelKeys.menuName]: 'heading',
            })
          }
          to={menu.path}
        >
          <div className={styles.menuItemBadge}>
            <Icon type={menu.icon} size={20} useAntd />
            <Badge
              count={getCounts()}
              offset={inboxCounter > 9 ? [-8, 8] : [0, 8]}
              size="small"
              style={{
                background: '#ec792b',
              }}
            >
              <Tooltip
                title={menu.toolTip}
                type={TEXT_TOOLTIP}
                placement="bottomLeft"
              >
                <Text type="Sider" text={menu.title} />
              </Tooltip>
            </Badge>
            {menu.beta ? (
              <span
                className="beta-icon"
                style={{
                  marginLeft: collapsed ? -15 : 0,
                  marginTop: collapsed ? -15 : -10,
                }}
              >
                BETA
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
    );
  }
  return <div />;
}

Sider.propTypes = {
  collapsed: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  leftNavConfig: PropTypes.array,
  visuals: PropTypes.array,
  siderMenu: PropTypes.array,
  handleCollapse: PropTypes.func,
  renderSidebar: PropTypes.func,
  runtime: PropTypes.object,
  setModal1Visible: PropTypes.func,
  cardVisible: PropTypes.bool,
  inboxCounter: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  sider: makeSelectSider(),
  collapsed: makeSelectSider('collapsed'),
  visuals: makeSelectSider('visuals'),
  defaultCollapsed: makeSelectSider('defaultCollapsed'),
  siderMenu: makeSelectSider('siderMenu'),
  siderBottomMenu: makeSelectSider('siderBottomMenu'),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    handleCollapse: e => dispatch(actions('COLLAPSE', e)),
    renderSidebar: e => dispatch(actions('RENDER_SIDEBAR', e)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(Sider);
