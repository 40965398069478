/**
 *
 * Select
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Select as CustomSelect } from 'antd';
import './style.less';

// eslint-disable-next-line no-unused-vars
const { Option } = Select;
const SelectOption = Option;
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Select({
  uniqueKey,
  displayKey,
  type = '',
  options,
  selected,
  children,
  ...rest
}) {
  // const children = [];
  // options.map(option => {
  //   children.push(
  //     <Option key={uniqueKey ? option[uniqueKey] : option}>
  //       {displayKey ? option[displayKey] : option}
  //     </Option>,
  //   );
  // });
  return (
    <CustomSelect mode={type} {...rest} style={{ height: '100%' }}>
      {children}
    </CustomSelect>
  );
}

Select.propTypes = {
  uniqueKey: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  displayKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Select;
export { SelectOption };
