const FLOOR_TIME = 'setFloorTime';
const SET_AUTO_SCROLL = 'setAutoScroll';
const SET_SEARCH_INDEX = 'setSearchIndex';
const ON_SEARCH_CLOSE = 'onSearchClose';
const ON_SEARCH_CLICK = 'onSearchClick';
const ON_SEARCH = 'onSearch';
const LINE_HEIGHT = 17;
const MIN_TRANSCRIPT_HEIGHT = 49;
const FONT_SIZE = 11;
const FONT_CONSTANT = 2.17;
const LAST_TRANSCRIPT_PADDING = 15;
const ON_FIRST_SCROLL = 'ON_FIRST_SCROLL';

export {
  FLOOR_TIME,
  SET_AUTO_SCROLL,
  SET_SEARCH_INDEX,
  ON_SEARCH_CLICK,
  ON_SEARCH_CLOSE,
  ON_SEARCH,
  LINE_HEIGHT,
  MIN_TRANSCRIPT_HEIGHT,
  FONT_SIZE,
  FONT_CONSTANT,
  LAST_TRANSCRIPT_PADDING,
  ON_FIRST_SCROLL,
};
