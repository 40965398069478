/* eslint-disable no-unused-expressions */
/**
 *
 * Component: AudioPlayer
 * Date: 4/4/2022
 *
 */

import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer,
  forwardRef,
} from 'react';
import ReactSlider from 'react-input-slider';
import _ from 'lodash';

import { mixpanelKeys } from 'utils/mixpanelKeys';
import mixpanel from 'utils/mixpanel';
import { Slider, Icon, Popover, Tooltip } from 'components/common';
import HowlerPlayer from 'components/HowlerPlayer';
import { formatTimeAddPadding } from 'utils/commonFunctions';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { ICON_TOOLTIP } from 'utils/commonConstants';

import audioReducer from './reducer';
import styles from './style.css';
import {
  SET_HOVER_STATES,
  SET_SKIP_SILENCE,
  SET_TALK_METRICS,
  CREATE_SPANS,
  CREATE_AUDIO_BARS,
  CHANGE_PLAY_RATE,
  CHANGE_VOLUME,
  SILENCE_COLOR,
  OVERTALK_COLOR,
} from './constants';

const AudioPlayer = forwardRef(
  (
    {
      audioCurrentTime,
      setAudioCurrentTime,
      call,
      isPlaying,
      setIsPlaying,
      audioBarLocations,
      isHowlerLoading,
      setIsHowlerLoading,
    },
    HowlerRef,
  ) => {
    const [userEmail] = useLocalStorage('userEmail');
    const [localVolume, setlocalVolume] = useLocalStorage(
      `clientAudioVolume$${userEmail}`,
    );
    const [localSkipSilence, setLocalSkipSilence] = useLocalStorage(
      'skipingSilence',
    );
    const lastVolumeRef = useRef(volume);
    const nextSilenceRef = useRef(null);
    const spanKeysRef = useRef(0);
    const audioHoverRef = useRef();
    const sliderWidthRef = useRef();
    const sliderRef = useRef();

    const initialAudioPlayerState = {
      playbackRate: 1,
      volume: Number(localVolume) || 1,
      talkMetrics,
      overtalkSpans: [],
      silenceSpans: [],
      audioHoverStates: {},
      overtalkHover: [],
      silenceHover: [],
      audioBars: [],
      skipSilence: localSkipSilence !== 'false',
    };

    const [state, dispatch] = useReducer(audioReducer, initialAudioPlayerState);

    const {
      playbackRate,
      volume,
      talkMetrics,
      overtalkSpans,
      silenceSpans,
      audioHoverStates,
      overtalkHover,
      silenceHover,
      audioBars,
      skipSilence,
    } = state;

    useEffect(() => {
      dispatch({
        type: SET_TALK_METRICS,
        payload: { talkMetrics: call?.talkMetrics },
      });
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (!isHowlerLoading && HowlerRef.current.playing()) {
          mixpanel('Closed without pausing', {
            [mixpanelKeys.callId]: call?.id,
          });
        }
      };
    }, [call]);

    useEffect(() => {
      createOvertalkSilenceSpans();
    }, [talkMetrics, createOvertalkSilenceSpans]);

    useEffect(() => {
      createAudioBars();
    }, [audioBarLocations, createAudioBars]);

    useEffect(() => {
      handleSkipSilence();
    }, [audioCurrentTime, handleSkipSilence]);

    useEffect(() => {
      if (
        HowlerRef.current?.seek() === call.audioDuration ||
        HowlerRef.current?.seek() > call.audioDuration
      ) {
        HowlerRef.current?.pause();
        HowlerRef.current?.seek(0);
        setAudioCurrentTime(0);
        setIsPlaying(false);
      }
      mustPauseAudio();
    }, [audioCurrentTime, HowlerRef, call, setIsPlaying, setAudioCurrentTime]);

    const getNextSilence = useCallback(() => {
      let returnValue = null;
      // eslint-disable-next-line no-unused-expressions
      talkMetrics?.silence.some((eachSilence, i) => {
        if (
          returnValue === null &&
          eachSilence.stime < audioCurrentTime &&
          audioCurrentTime < eachSilence.etime
        ) {
          returnValue = {
            index: i,
            silence: eachSilence,
          };
          return true;
        }
        if (returnValue === null && eachSilence.stime > audioCurrentTime) {
          returnValue = {
            index: i,
            silence: eachSilence,
          };
          return true;
        }
        return false;
      });
      return returnValue;
    }, [audioCurrentTime, talkMetrics]);

    const handleSkipSilence = useCallback(() => {
      let nextSilenceState;

      if (skipSilence && audioCurrentTime >= 0) {
        if (nextSilenceRef.current === null) {
          nextSilenceState = getNextSilence(audioCurrentTime);
        } else if (
          nextSilenceRef.current !== null &&
          audioCurrentTime > nextSilenceRef.current?.silence.stime + 0.5 &&
          audioCurrentTime < nextSilenceRef.current?.silence.etime - 0.5
        ) {
          HowlerRef.current.seek(nextSilenceRef.current?.silence.etime);
          setAudioCurrentTime(nextSilenceRef.current?.silence.etime);
        } else if (
          nextSilenceRef.current !== null &&
          (audioCurrentTime > nextSilenceRef.current?.silence.etime ||
            audioCurrentTime < nextSilenceRef.current?.silence.etime)
        ) {
          nextSilenceState = getNextSilence(audioCurrentTime);
        }
      }

      if (nextSilenceState) {
        nextSilenceRef.current = nextSilenceState;
      }
    }, [
      getNextSilence,
      HowlerRef,
      audioCurrentTime,
      setAudioCurrentTime,
      skipSilence,
    ]);

    const mustPauseAudio = () => {
      // handles a edge case when audio jumps over from last to start and keeps playing
      // mostly happens on low end Systems

      if (!isPlaying && HowlerRef.current?.playing()) {
        HowlerRef.current?.pause();
      }
    };

    const createAudioBars = useCallback(() => {
      const audioBarsArr = [];
      if (call?.audioDuration && call?.audioDuration !== 0) {
        audioBarLocations.map(barLocation => {
          const calculatedLeftPosition =
            (barLocation * 100) / call?.audioDuration;

          audioBarsArr.push(
            <span
              key={barLocation}
              style={{
                left: `${calculatedLeftPosition}%`,
                height: '17px',
                top: '-7px',
                width: '3px',
                backgroundColor: 'orange',
              }}
            />,
          );

          return null;
        });
      }

      dispatch({
        type: CREATE_AUDIO_BARS,
        payload: { audioBars: audioBarsArr },
      });
    }, [audioBarLocations, call.audioDuration]);

    const sliderChange = x => {
      setAudioCurrentTime(x);
      HowlerRef.current.seek(x);
      mixpanel('Seeked audio', {
        [mixpanelKeys.callId]: call?.id,
        [mixpanelKeys.time]: x,
      });
    };

    /**
     * helps avoid weird state when bar keeps moving on its own
     * This was solving the case when someone tries too hard to move around the audio bar,
     * It was causing discrepancies between UI and audio, also seek was jumping around in such a case
     */

    const debouncedSliderChange = _.debounce(sliderChange, 20);

    /**
     * Helps manage playback rate, it oscillates between 1 to 2 with a inc of 0.5
     */
    const handlePlaybackRate = useCallback(() => {
      let currentPlaybackRate = playbackRate;
      if (playbackRate === 2) {
        currentPlaybackRate = 1;
      } else {
        currentPlaybackRate += 0.5;
      }
      HowlerRef.current.rate(currentPlaybackRate);

      mixpanel('Playback rate', {
        [mixpanelKeys.callId]: call?.id,
        [mixpanelKeys.rate]: currentPlaybackRate,
      });
      dispatch({
        type: CHANGE_PLAY_RATE,
        payload: { playbackRate: currentPlaybackRate },
      });
    }, [call, playbackRate, HowlerRef]);

    /**
     * Helper function to update localStorage and howler volume values
     * @param {} lastVolume
     */
    const setLocalAndHowlerVolume = lastVolume => {
      setlocalVolume(lastVolume);
      HowlerRef.current.volume(lastVolume);
    };

    /**
     * Helps manage mute setting, stores last value in local storage
     * and uses it, when toggled back
     */

    const muteToggle = useCallback(() => {
      if (volume !== 0) {
        lastVolumeRef.current = volume;
        setLocalAndHowlerVolume(0);

        dispatch({ type: 'changeVolume', payload: { volume: 0 } });
      } else {
        setLocalAndHowlerVolume(lastVolumeRef.current);

        dispatch({
          type: CHANGE_VOLUME,
          payload: { volume: lastVolumeRef.current },
        });
      }
    }, [HowlerRef, volume, setlocalVolume]);

    /**
     * Updates volume state, Howler and local volume on slider change
     */
    const changeVolume = useCallback(
      currentVolume => {
        setLocalAndHowlerVolume(currentVolume);
        dispatch({
          type: CHANGE_VOLUME,
          payload: { volume: currentVolume },
        });
        mixpanel('Volume', {
          [mixpanelKeys.callId]: call?.id,
          [mixpanelKeys.count]: currentVolume,
        });
      },
      [HowlerRef, setlocalVolume, call],
    );

    const handlePlayPause = useCallback(() => {
      if (!isPlaying && !HowlerRef.current?.playing()) {
        HowlerRef.current?.play();
        setIsPlaying(true);
      }
      if (HowlerRef.current?.playing() && isPlaying) {
        HowlerRef.current?.pause();
        setIsPlaying(false);
      }
    }, [HowlerRef, isPlaying, setIsPlaying]);

    /**
     * helper function to create spans to shown over audio player
     * @param {*} event array of events of particular type
     * @param {*} color color to shown in audio bar
     * @param {*} endTime Duration of audio, used to calculate location
     * @returns array of span that come up on audio bar for silence and overtalk
     */

    const createSpanFormMetrics = (event, color, endTime) => {
      const span = event?.map(eachEvent => {
        const calculatedLeftPosition = (eachEvent.stime * 100) / endTime;
        const calculatedWidth = (eachEvent.duration * 100) / endTime;
        const index = spanKeysRef.current;
        spanKeysRef.current += 1;
        return (
          <span
            key={`event${index + 1}`}
            style={{
              width: `${calculatedWidth}%`,
              left: `${calculatedLeftPosition}%`,
              background: color,
              zIndex: '2',
            }}
          />
        );
      });
      return span;
    };

    /**
     * Creates overtalk and silence functions and updates state with them
     */

    const createOvertalkSilenceSpans = useCallback(() => {
      dispatch({
        type: CREATE_SPANS,
        payload: {
          overtalkSpans: createSpanFormMetrics(
            talkMetrics?.overtalk,
            OVERTALK_COLOR,
            call?.audioDuration,
          ),
          silenceSpans: createSpanFormMetrics(
            talkMetrics?.silence,
            SILENCE_COLOR,
            call?.audioDuration,
          ),
        },
      });
    }, [talkMetrics, call.audioDuration]);

    /**
     * Event triggers on mouse move and updates time based on hover location
     * @param {*} e event from mouse move
     */

    const sliderMouseMove = e => {
      if (!sliderWidthRef.current) {
        sliderWidthRef.current = sliderRef.current.getBoundingClientRect().width;
      }
      const duration = call.audioDuration;
      const hoverleft = e.nativeEvent.offsetX;
      let hoverDuration =
        (e.nativeEvent.offsetX * duration) / sliderWidthRef.current;
      if (!hoverDuration || Number.isNaN(hoverDuration)) {
        hoverDuration = 0;
      } else if (hoverDuration >= duration) {
        hoverDuration = duration;
      }
      const hoverTime = formatTimeAddPadding(hoverDuration);
      if (audioHoverRef.current?.style) {
        audioHoverRef.current.style.marginLeft = `${
          audioHoverStates.hoverleft
        }px`;
      }

      dispatch({
        type: SET_HOVER_STATES,
        payload: {
          silenceHover: getSpanHover('silence'),
          overtalkHover: getSpanHover('overtalk'),
          audioHoverStates: { hoverTime, hoverleft, hoverDuration },
        },
      });
    };
    /**
     * Enables div that shows time over audio bar
     */
    const ShowTimeOnSlider = () => {
      if (audioHoverRef.current?.style) {
        audioHoverRef.current.style.display = 'flex';
        audioHoverRef.current.style.flexDirection = 'column';
        audioHoverRef.current.style.alignItems = 'center';
      }
    };

    /**
     * Hides div that shows time over audio bar
     */
    const HideTimeOnSlider = () => {
      audioHoverRef.current.style.display = 'none';
    };

    /**
     * looks for a particular type of event in the time Provided
     * @param {*} eventType can be silence or overtalk
     * @returns span to be displayed above audio bar on hover events
     */

    const getSpanHover = eventType => {
      const { hoverDuration } = audioHoverStates;
      const type = talkMetrics?.[eventType];
      const overSilence = type?.filter(
        time => hoverDuration > time.stime && hoverDuration < time.etime,
      );
      if (overSilence?.length) {
        return (
          <span className="">
            {eventType}{' '}
            {overSilence[0].duration > 60
              ? formatTimeAddPadding(overSilence[0].duration)
              : `${overSilence[0].duration.toFixed(0)}s`}
          </span>
        );
      }
      return '';
    };

    /**
     * Multiple render functions listed to save renders and changes only when necessary
     */

    const ControlsRenderer = useMemo(
      () => (
        <div className={styles.redoPlayUndo}>
          <div
            role="button"
            tabIndex={0}
            disabled={isHowlerLoading}
            onClick={() => {
              HowlerRef.current.seek(HowlerRef.current.seek() - 10);
              setAudioCurrentTime(HowlerRef.current.seek());
              mixpanel('Skip 10 seconds', {
                [mixpanelKeys.callId]: call?.id,
                [mixpanelKeys.description]: 'backward',
              });
            }}
          >
            <Icon size={{ width: '15px', height: '19px' }} type="undo" />
          </div>
          <div role="button" tabIndex={0} disabled={isHowlerLoading}>
            {isPlaying ? (
              <Icon
                type="pause"
                size={30}
                onClick={() =>
                  !isHowlerLoading
                    ? handlePlayPause() &&
                      mixpanel('Pause audio', {
                        [mixpanelKeys.callId]: call?.id,
                        [mixpanelKeys.time]: HowlerRef.current.seek() || 0,
                      })
                    : null
                }
                disabled={isHowlerLoading}
                opacity={isHowlerLoading ? 0.3 : 1}
              />
            ) : (
              <Icon
                type="play"
                size={30}
                onClick={() =>
                  !isHowlerLoading
                    ? handlePlayPause() &&
                      mixpanel('Play audio', {
                        [mixpanelKeys.callId]: call?.id,
                        [mixpanelKeys.time]: HowlerRef.current.seek() || 0,
                      })
                    : null
                }
                disabled={isHowlerLoading}
                opacity={isHowlerLoading ? 0.3 : 1}
              />
            )}
          </div>
          <div
            role="button"
            tabIndex={0}
            disabled={isHowlerLoading}
            onClick={() => {
              if (HowlerRef.current.seek() + 10 <= call?.audioDuration) {
                HowlerRef.current.seek(HowlerRef.current.seek() + 10);
                setAudioCurrentTime(HowlerRef.current.seek());
                mixpanel('Skip 10 seconds', {
                  [mixpanelKeys.callId]: call?.id,
                  [mixpanelKeys.description]: 'forward',
                });
              } else {
                HowlerRef.current?.pause();
                HowlerRef.current.seek(0);
                setAudioCurrentTime(0);
                setIsPlaying(false);
              }
            }}
          >
            <Icon size={{ width: '15px', height: '19px' }} type="redo" />
          </div>
        </div>
      ),
      [
        setAudioCurrentTime,
        HowlerRef,
        isHowlerLoading,
        isPlaying,
        handlePlayPause,
        call,
      ],
    );

    const RenderVolumeAndPlayRate = useMemo(() => {
      const RenderVolumePicker = () => (
        <div className={styles.volumeSliderWrapper}>
          <Slider
            vertical
            min={0}
            max={1}
            step={0.1}
            value={volume}
            tipFormatter={value => `${value * 100}%`}
            onChange={changeVolume}
          />
        </div>
      );
      return (
        <>
          <div className={styles.muteToggle} role="button" tabIndex={0}>
            <Popover placement="top" content={<RenderVolumePicker />}>
              <Icon
                onClick={() => muteToggle()}
                style={{ height: '15px' }}
                type={volume === 0 ? 'volumeMute' : 'volume'}
                alt="volume"
              />
            </Popover>
          </div>
          <Tooltip
            title="Increase/Decrease speed of the audio"
            type={ICON_TOOLTIP}
            placement="topRight"
          >
            <div
              role="button"
              tabIndex={0}
              onClick={handlePlaybackRate}
              className={styles.playbackRate}
            >
              {playbackRate}x
            </div>
          </Tooltip>
        </>
      );
    }, [playbackRate, volume, handlePlaybackRate, muteToggle, changeVolume]);

    const RenderSkipSilence = useMemo(
      () => (
        <div
          role="button"
          tabIndex="0"
          className={styles.skipSilence}
          style={
            call?.talkMetrics?.silence?.length > 0 ? {} : { display: 'none' }
          }
          onClick={() => {
            setLocalSkipSilence(!skipSilence);
            dispatch({
              type: SET_SKIP_SILENCE,
              payload: { skipSilence: !state.skipSilence },
            });
          }}
        >
          {skipSilence ? (
            <span>
              <Icon size={{ width: '9px', height: '7px' }} type="tickYellow" />
              <span>skipping silence</span>
            </span>
          ) : (
            <span>skip silence</span>
          )}
        </div>
      ),
      [call.talkMetrics, state.skipSilence, skipSilence, setLocalSkipSilence],
    );

    return (
      <div className={styles.player}>
        <div className={styles.audioPlayer}>
          <HowlerPlayer
            setAudioCurrentTime={setAudioCurrentTime}
            ref={HowlerRef}
            setIsHowlerLoading={setIsHowlerLoading}
          />
          {ControlsRenderer}

          <div className={styles.audioDuration}>
            {formatTimeAddPadding(audioCurrentTime)} /{' '}
            {formatTimeAddPadding(call?.audioDuration) === 'NaN:NaN'
              ? '00:00'
              : formatTimeAddPadding(call?.audioDuration)}
          </div>
          <div className={styles.customSliderWrapper}>
            <div className={styles.durationSpans} ref={audioHoverRef}>
              {silenceHover} {overtalkHover} {audioHoverStates.hoverTime}
            </div>
            <div
              className={styles.customSlider}
              ref={sliderRef}
              onMouseMove={e => sliderMouseMove(e)}
              onMouseEnter={e => ShowTimeOnSlider(e)}
              onMouseLeave={e => HideTimeOnSlider(e)}
            >
              <div className={styles.overtalkSilenceSpan}>
                {audioBars}
                {overtalkSpans}
                {silenceSpans}
                <span
                  style={{
                    width: `${
                      Number(audioCurrentTime * 100) /
                        Number(call?.audioDuration) >
                      100
                        ? 100
                        : Number(audioCurrentTime * 100) /
                          Number(call?.audioDuration)
                    }%`,
                    background: '#ef8432',
                    height: '4px',
                    top: '0px',
                    zIndex: '1',
                  }}
                />
              </div>
              <ReactSlider
                axis="x"
                x={audioCurrentTime}
                xmin={0}
                xmax={call?.audioDuration}
                onChange={({ x }) => debouncedSliderChange(x)}
                xstep={0.1}
                className={styles.reactInputSlider}
                disabled={isHowlerLoading}
                styles={{
                  active: {
                    backgroundColor: 'transparent',
                  },
                  thumb: {
                    background: '#ef8432',
                    boxShadow: 'unset',
                    width: 15,
                    height: 15,
                    opacity: 1,
                    top: '3px',
                  },
                  disabled: {
                    opacity: 0.3,
                  },
                }}
              />
            </div>
            {RenderSkipSilence}
          </div>

          {RenderVolumeAndPlayRate}
        </div>
      </div>
    );
  },
);

export default AudioPlayer;

AudioPlayer.propTypes = {
  isHowlerLoading: PropTypes.bool,
  setIsHowlerLoading: PropTypes.func,
  audioCurrentTime: PropTypes.number,
  setAudioCurrentTime: PropTypes.func,
  call: PropTypes.object,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  audioBarLocations: PropTypes.array,
};
