/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-plusplus */
/**
 *
 * Component: QuestionBuilder
 * Date: 11/6/2020
 *
 */

import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
  cloneElement,
  useMemo,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import FilterContext from 'containers/ScorecardBuilder/filterContext';
import {
  Card,
  Icon,
  Text,
  Input,
  Button,
  Select,
  Switch,
} from 'components/common';

import MultiSelectFilter from '../MultiSelectFilter/Loadable';
import OptionsBuilder from '../OptionsBuilder';
import styles from './style.css';

const QuestionBuilder = ({
  initData,
  sectionType,
  onQuestionChanged,
  onRemove,
  onDuplicate,
}) => {
  const [questionType, setQuestionType] = useState(initData.qType || 'OPTIONS');
  const [maxScore, setMaxScore] = useState(initData?.maxScore || 0);
  const [autoFillRuleList, setAutoFillRuleList] = useState([]);
  const [optionList, setOptionList] = useState(
    initData?.qOptions?.map(option => option.oText) || [],
  );
  const ruleCount = useRef(initData?.autoFillRules?.length + 1 || 1);
  const ruleListRef = useRef(initData?.autoFillRules || []);
  const { filterList: filters } = useContext(FilterContext);

  /**
   * Create OptionsTextList and get the max Score possible from each options, and then update the optionsTextList if different from previous.
   * @params {Array} options: Options array for the questions response.
   * */
  const onOptionChanged = options => {
    let newMaxScore = 0;
    const optionsTextList = [];
    options.forEach(option => {
      newMaxScore = Math.max(option.oScore, newMaxScore) || newMaxScore;
      optionsTextList.push(option.oText);
    });
    setMaxScore(newMaxScore ?? 0);
    onQuestionChanged({ qOptions: options, maxScore: newMaxScore });
    // eslint-disable-next-line
    if (optionsTextList.join() != optionList.join())
      setOptionList(optionsTextList);
  };

  /**
   * Based on question type, renders OptionsBuilder or an Input Field, else null.
   * */
  const getQuestionControl = () => {
    switch (questionType) {
      case 'OPTIONS':
        return (
          <OptionsBuilder
            onOptionSetChanged={onOptionChanged}
            showScoreInput={sectionType !== 'ALL'}
            initState={initData.qOptions}
          />
        );
      case 'FREEFORM':
        return (
          <div className={styles.freeFormInput}>
            <Input placeholder="User inputs free text here" />
          </div>
        );
      default:
        break;
    }
    return null;
  };

  /**
   * Updates rule list based on value object.
   */
  const autoFillRuleChanged = index => value => {
    if (ruleListRef.current[index]) {
      if (value?.type === 'responseOnly') {
        ruleListRef.current[index].response = value.response;
      } else if (value.response === null) {
        ruleListRef.current.splice(index, 1);
      } else {
        ruleListRef.current[index] = value;
      }
    } else {
      // If default answer rule is changed from default(OPTIONS) to NONE
      if (value.response === null) return;
      ruleListRef.current.push(value);
    }
    onQuestionChanged({ type: 'autoFillRule', value: ruleListRef.current });
  };

  /**
   * Removes rule from the rule list.
   */
  const removeAutoFillRule = useCallback(
    index => () => {
      const newList = [...autoFillRuleList];
      if (newList.length > 1) {
        newList.splice(index, 1);
        setAutoFillRuleList(newList);
        ruleListRef.current.splice(index, 1);
        onQuestionChanged({ type: 'autoFillRule', value: ruleListRef.current });
      } else {
        ruleListRef.current = [];
        onQuestionChanged({ type: 'autoFillRule', value: ruleListRef.current });
        setAutoFillRuleList([
          <AutoFillRule
            key={ruleCount.current++}
            onQuestionChanged={autoFillRuleChanged(0)}
            onRemove={removeAutoFillRule(0)}
            addNewAutoFillRule={addNewAutoFillRule}
            filters={[...filters]}
            optionList={optionList}
          />,
        ]);
      }
    },
    [autoFillRuleList.length],
  );

  /**
   * Adds up a new Rule.
   */
  const addNewAutoFillRule = useCallback(
    defaultValue => {
      setAutoFillRuleList(list => [
        ...list,
        <AutoFillRule
          key={ruleCount.current++}
          onQuestionChanged={autoFillRuleChanged(list.length)}
          onRemove={removeAutoFillRule(list.length)}
          addNewAutoFillRule={addNewAutoFillRule}
          filters={[...filters]}
          optionList={optionList}
          defaultValue={defaultValue} // Adds a default value to autofill rule
        />,
      ]);
    },
    [autoFillRuleList.length, filters, optionList],
  );

  /**
   * Fills up the auto fill rule list with initData if it exists.
   */
  const setInitialAutoFillComponent = () => {
    const initialAutoFillRuleList = [];
    let noDefaultValueSelected = true;
    if (initData?.autoFillRules?.length) {
      initData.autoFillRules.forEach((eachRule, ruleIndex) => {
        if (eachRule?.rule?.op || eachRule?.rule?.ruleType) {
          initialAutoFillRuleList.push(
            <AutoFillRule
              key={ruleCount.current++}
              onQuestionChanged={autoFillRuleChanged(ruleIndex)}
              onRemove={removeAutoFillRule(ruleIndex)}
              addNewAutoFillRule={addNewAutoFillRule}
              filters={[...filters]}
              optionList={optionList}
              initValues={eachRule}
            />,
          );
        } else {
          noDefaultValueSelected = false;
          initialAutoFillRuleList.push(
            <AutoFillRule
              key={ruleCount.current++}
              onQuestionChanged={autoFillRuleChanged(ruleIndex)}
              onRemove={removeAutoFillRule(ruleIndex)}
              addNewAutoFillRule={addNewAutoFillRule}
              filters={[...filters]}
              optionList={optionList}
              defaultValue={eachRule}
            />,
          );
        }
      });
    }
    if (noDefaultValueSelected) {
      initialAutoFillRuleList.push(
        <AutoFillRule
          key={ruleCount.current++}
          onQuestionChanged={autoFillRuleChanged(
            initialAutoFillRuleList.length,
          )}
          onRemove={removeAutoFillRule(initialAutoFillRuleList.length)}
          addNewAutoFillRule={addNewAutoFillRule}
          filters={[...filters]}
          optionList={optionList}
        />,
      );
    }
    setAutoFillRuleList(initialAutoFillRuleList);
  };

  useEffect(() => {
    if (autoFillRuleList.length) {
      setAutoFillRuleList(list =>
        list.map((rule, index) =>
          cloneElement(rule, {
            onQuestionChanged: autoFillRuleChanged(index),
            onRemove: removeAutoFillRule(index),
            addNewAutoFillRule,
            filters: [...filters],
            optionList,
          }),
        ),
      );
    } else {
      setInitialAutoFillComponent();
    }
  }, [
    filters,
    JSON.stringify(optionList),
    addNewAutoFillRule,
    removeAutoFillRule,
  ]);

  const getAutoFillRuleList = useMemo(() => {
    if (questionType === 'OPTIONS')
      return (
        <>
          <Text text="Add a new rule to the auto-fill rule list" />
          <div className={styles.ruleList}>{autoFillRuleList}</div>
        </>
      );
    return null;
  }, [questionType, autoFillRuleList]);

  return (
    <Card>
      <div
        // ref={scrollRef}
        className={styles.questionHeader}
      >
        <div className={styles.questionSettings}>
          <Input
            placeholder="Enter question name (20 char limit)"
            defaultValue={initData.name}
            maxLength={20}
            onChange={e => onQuestionChanged({ name: e.target.value })}
          />
          <Select
            defaultValue="OPTIONS"
            value={questionType}
            onSelect={value => {
              setQuestionType(value);
              if (onQuestionChanged) onQuestionChanged({ qType: value });
            }}
          >
            <Option key="OPTIONS">Options</Option>
            <Option key="FREEFORM">Free Flow</Option>
          </Select>
        </div>
        <Input
          placeholder="Enter question text (500 char limit)"
          defaultValue={initData.qText}
          maxLength={500}
          onChange={e => onQuestionChanged({ qText: e.target.value })}
        />
        <div className={styles.questionControls}>
          <Button
            text="Duplicate Question"
            type="primary"
            onClick={onDuplicate || null}
          />
          <Button text="Remove Question" onClick={onRemove || null} />
          <div className={styles.switch}>
            <Text text="Required" />
            <Switch
              size="small"
              defaultChecked={initData.isMandatory ?? false}
              onChange={value => onQuestionChanged({ isMandatory: value })}
            />
          </div>
          <div className={styles.switch}>
            <Text text="Auto Fail" />
            <Switch
              size="small"
              defaultChecked={initData.isAutoFail ?? false}
              onChange={value => onQuestionChanged({ isAutoFail: value })}
            />
          </div>
          <Text text="Max Question Score" />
          <Input
            type="number"
            key="maxScore"
            min={0}
            precision={0}
            disabled={questionType === 'OPTIONS' || sectionType === 'ALL'}
            defaultValue={initData.maxScore ?? 0}
            value={sectionType === 'ALL' ? 0 : maxScore}
            onChange={value => {
              setMaxScore(value);
              onQuestionChanged({ maxScore: value });
            }}
          />
        </div>
      </div>

      <hr className="divider" />
      {getQuestionControl()}
      <hr className="divider" />

      {getAutoFillRuleList}
    </Card>
  );
};

const AutoFillRule = ({
  onQuestionChanged,
  addNewAutoFillRule,
  filters,
  optionList,
  onRemove,
  defaultValue,
  initValues,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const autoFillOption = useRef(initValues?.response ?? 0);
  const isEmpty = useRef(!defaultValue);

  const generateSelectedValuesFromInitData = () => {
    if (!initValues?.rule) {
      return;
    }
    const newSelectedValues = [];
    function getSelectedValuesFromChild(child, index, last, current) {
      if (!child.children) {
        newSelectedValues.push({
          ...child,
          op: index === 0 ? last : current,
        });
      } else {
        getSelectedValuesFromChildren(child.children, current, child.op);
      }
    }
    function getSelectedValuesFromChildren(childrenArray, lastArg, currentArg) {
      if (childrenArray.length === 2) {
        getSelectedValuesFromChild(childrenArray[0], 0, lastArg, currentArg);
        getSelectedValuesFromChild(childrenArray[1], 1, lastArg, currentArg);
      }
    }

    if (initValues.rule.children) {
      getSelectedValuesFromChildren(
        initValues.rule.children,
        'AND',
        initValues.rule.op,
      );
    } else {
      newSelectedValues.push({
        ...initValues.rule,
        op: 'AND',
      });
    }

    setSelectedValues(newSelectedValues);
  };

  useEffect(() => {
    generateSelectedValuesFromInitData();
  }, [initValues]);

  const onOpChange = useCallback(
    index => value => {
      const newList = _.cloneDeep(selectedValues);
      newList[index].op = value;
      setSelectedValues(newList);

      function getVals(obj) {
        return {
          ruleType: obj.ruleType,
          checkType: obj.checkType,
          tags: obj.tags,
        };
      }

      const branches = [];
      for (let i = 0; i < newList.length - 1; i++) {
        const nodePointer = {};
        nodePointer.op = newList[i + 1].op;
        nodePointer.children = [];
        if (i === 0) nodePointer.children.push(getVals(newList[0]));
        else nodePointer.children.push(getVals(newList[i]));
        nodePointer.children.push(getVals(newList[i + 1]));
        branches.push(nodePointer);
      }
      let tree = {};
      tree = branches.pop();
      while (branches.length) {
        const e = branches.pop();
        e.children[1] = tree;
        tree = _.cloneDeep(e);
      }
      onQuestionChanged({ rule: tree, response: 0 });
    },
    [selectedValues, onQuestionChanged],
  );

  const onFilterApplied = useCallback(
    index => (selectedValuesList, ruleType, checkType) => {
      function getVals(obj) {
        return {
          ruleType: obj.ruleType,
          checkType: obj.checkType,
          tags: obj.tags,
        };
      }
      if (selectedValuesList.length) {
        // checks if the filter is first or not
        if (!selectedValues.length) {
          if (isEmpty.current) {
            isEmpty.current = false;
            addNewAutoFillRule();
          } else {
            addNewAutoFillRule(defaultValue);
          }
        }
        const obj = {
          ruleType,
          checkType,
          tags: selectedValuesList,
        };
        let newList = _.cloneDeep(selectedValues);
        if (index !== null) {
          newList[index] = { ...newList[index], ...obj };
          setSelectedValues(newList);
        } else {
          newList = [...newList, { ...obj, op: 'AND' }];
          setSelectedValues(newList);
        }
        if (newList.length > 1) {
          const branches = [];
          for (let i = 0; i < newList.length - 1; i++) {
            const nodePointer = {};
            nodePointer.op = newList[i + 1].op;
            nodePointer.children = [];
            nodePointer.children.push(getVals(newList[i]));
            nodePointer.children.push(getVals(newList[i + 1]));
            branches.push(nodePointer);
          }
          let tree = {};
          tree = branches.pop();
          while (branches.length) {
            const e = branches.pop();
            e.children[1] = tree;
            tree = _.cloneDeep(e);
          }
          onQuestionChanged({
            rule: tree,
            response: autoFillOption.current,
          });
        } else {
          onQuestionChanged({ rule: obj, response: autoFillOption.current });
        }
      }
    },
    [selectedValues, onQuestionChanged],
  );

  return (
    <div className={styles.autoFillContainer}>
      <div className="grid-column">
        <div className={styles.tagFilters}>
          {selectedValues.map((af, i) => (
            <>
              <MultiSelectFilter
                hideTitle
                tagRules
                checkType={af.checkType}
                ruleType={af.ruleType}
                filterValues={filters}
                onFilterApplied={onFilterApplied(i)}
                selectedValues={af.tags}
              />
              {1 + i !== selectedValues.length && (
                <div className="and-text">
                  <Select
                    defaultValue={selectedValues[1 + i].op || 'AND'}
                    onChange={onOpChange(i + 1)}
                  >
                    <Option key="AND">AND</Option>
                    <Option key="OR">OR</Option>
                  </Select>
                </div>
              )}
            </>
          ))}
          <MultiSelectFilter
            hideTitle
            tagRules
            checkType="PRESENT"
            ruleType="ANY"
            filterValues={filters}
            onFilterApplied={onFilterApplied(null)}
            selectedValues={[]}
          />
        </div>
        {selectedValues?.length > 0 && (
          <Icon size={12} onClick={onRemove} type="close" />
        )}
      </div>
      <div className={styles.optionSelect}>
        {selectedValues?.length > 0 ? (
          <>
            <Text text="Option to be selected for auto fill" />
            <Select
              defaultValue={autoFillOption.current ?? 'OPTIONS'}
              key={autoFillOption.current ?? 'OPTIONS'}
              onChange={value => {
                autoFillOption.current = value;
                onQuestionChanged({ type: 'responseOnly', response: value });
              }}
            >
              {optionList.map((option, index) => (
                <Option key={option} value={index}>
                  {option}
                </Option>
              ))}
            </Select>
          </>
        ) : (
          <>
            <Text text="Default option to be selected" />
            <Select
              defaultValue={defaultValue?.response ?? 'OPTIONS'}
              onChange={value =>
                onQuestionChanged({
                  rule: { constant: true },
                  response: value,
                })
              }
            >
              <Option key="NONE" value={null}>
                NONE
              </Option>
              {optionList.map((option, index) => (
                <Option key={option} value={index}>
                  {option}
                </Option>
              ))}
            </Select>
          </>
        )}
      </div>
    </div>
  );
};

AutoFillRule.propTypes = {
  onQuestionChanged: PropTypes.func,
  addNewAutoFillRule: PropTypes.func,
  filters: PropTypes.array,
  optionList: PropTypes.array,
  onRemove: PropTypes.func,
  defaultValue: PropTypes.object,
  initValues: PropTypes.object,
};

QuestionBuilder.propTypes = {
  initData: PropTypes.object,
  sectionType: PropTypes.string,
  onQuestionChanged: PropTypes.func,
  onRemove: PropTypes.func,
  onDuplicate: PropTypes.func,
};

export default QuestionBuilder;
