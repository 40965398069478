/**
 *
 * Text
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';

function Text({ type, text, className, ...rest }) {
  const customStyles = [];
  if (className)
    className.split(' ').forEach(classname => {
      customStyles.push(styles[classname]);
    });
  return (
    <span
      className={`${styles[type] ? styles[type] : ''} ${customStyles
        .join(' ')
        .toString()}`}
      {...rest}
    >
      {text}
    </span>
  );
}

Text.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  bold: PropTypes.string,
  className: PropTypes.string,
};

export default Text;
