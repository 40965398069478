/**
 *
 * Component: SkeletonLoader
 * Date: 23/7/2020
 *
 */

import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './style.css';
const SkeletonLoader = ({ type, repeat = 1, loading, children, ...rest }) => {
  const commponentMap = {
    transcript: Transcript,
    meta: MetaData,
    tags: TagList,
    default: props => Line('120px', props),
    reviewFilter: ReviewFilter,
    searchFilter: SearchFilter,
    button: Button,
    line: props => Line('100%', props),
    filterButton: FilterButton,
    icon: Icon,
    searchResultsTags: SearchResultsTags,
    chart: Chart,
    name: Name,
    histogram: Histogram,
  };

  /**
   * selectd the type of Component to render from collection for every key in repeat Object.
   * @returns JSX for the component Selected in for each of repeats key.
   */
  const getLoader = () => {
    const Loader = commponentMap[type];
    return (
      <>
        {[...Array(repeat).keys()].map((value, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Loader {...rest} key={idx} />
        ))}
      </>
    );
  };

  // if any element in chilred are empty render a Loader else render the childrens.
  const checkChildren = () => {
    if (Array.isArray(children) && loading)
      return children.every(element => element === undefined)
        ? getLoader()
        : children;
    return children;
  };

  if (!type) {
    return children;
  }

  return children ? checkChildren() : getLoader();
};

SkeletonLoader.propTypes = {};

/**
 * @param {Object} rest : takes all the props, to pass to ContentLoader.
 * @returns {JSX}
 */
const Transcript = rest => (
  <ContentLoader
    speed={3}
    {...rest}
    width="100%"
    height="64"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="3" ry="3" width="30%" height="19" />
    <rect x="0" y="24" rx="3" ry="3" width="80%" height="12" />
    <rect x="0" y="40" rx="3" ry="3" width="70%" height="12" />
  </ContentLoader>
);

const Icon = rest => (
  <ContentLoader
    speed={3}
    width={rest.width + 6 || '22'}
    height={rest.height + 6 || '22'}
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect
      x="0"
      y="0"
      rx="3"
      ry="3"
      width={rest.width || '16'}
      height={rest.height || '16'}
    />
  </ContentLoader>
);

const MetaData = rest => (
  <ContentLoader
    speed={3}
    {...rest}
    width="100%"
    height="24"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="3" ry="3" width="90" height="18" />
    <rect x="100" y="0" rx="3" ry="3" width="200" height="18" />
  </ContentLoader>
);

const TagList = rest => (
  <ContentLoader
    speed={3}
    {...rest}
    width="100%"
    height="42"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="8" ry="8" width="100" height="18" />
    <rect x="110" y="0" rx="8" ry="8" width="180" height="18" />
    <rect x="300" y="0" rx="8" ry="8" width="80" height="18" />
    <rect x="390" y="0" rx="8" ry="8" width="130" height="18" />
    <rect x="530" y="0" rx="8" ry="8" width="130" height="18" />

    <rect x="0" y="24" rx="8" ry="8" width="180" height="18" />
    <rect x="190" y="24" rx="8" ry="8" width="80" height="18" />
    <rect x="280" y="24" rx="8" ry="8" width="140" height="18" />
    <rect x="430" y="24" rx="8" ry="8" width="60" height="18" />
    <rect x="500" y="24" rx="8" ry="8" width="100" height="18" />
  </ContentLoader>
);

const Name = () => (
  <ContentLoader
    speed={3}
    width="150"
    height="24"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="3" ry="3" width="150" height="18" />
  </ContentLoader>
);

const Line = (width, { height, ...props }) => (
  <ContentLoader
    speed={3}
    width="100%"
    height={height + 6 || '20'}
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width={width} height={height || '20'} />
  </ContentLoader>
);

const ReviewFilter = rest => (
  <ContentLoader
    speed={3}
    width="100%"
    height={37}
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect x="0" y="5" rx="15" ry="15" width="95" height="25" />
    <rect x="100" y="5" rx="15" ry="15" width="95" height="25" />
    <rect x="200" y="5" rx="15" ry="15" width="95" height="25" />
    <rect x="300" y="5" rx="15" ry="15" width="95" height="25" />
    <rect x="400" y="5" rx="15" ry="15" width="95" height="25" />
  </ContentLoader>
);

const SearchFilter = rest => (
  <ContentLoader
    speed={3}
    width="100%"
    height={27}
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect x="0" y="0" rx="12.5" ry="12.5" width="85" height="25" />
    <rect x="90" y="0" rx="12.5" ry="12.5" width="95" height="25" />
    <rect x="190" y="0" rx="12.5" ry="12.5" width="75" height="25" />
    <rect x="270" y="0" rx="12.5" ry="12.5" width="80" height="25" />
    <rect x="355" y="0" rx="12.5" ry="12.5" width="90" height="25" />
  </ContentLoader>
);

const SearchResultsTags = rest => (
  <ContentLoader
    speed={3}
    width="100%"
    height={25}
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect x="0" y="0" rx="12.5" ry="12.5" width="85" height="25" />
    <rect x="90" y="0" rx="12.5" ry="12.5" width="95" height="25" />
    <rect x="190" y="0" rx="12.5" ry="12.5" width="75" height="25" />
  </ContentLoader>
);

const Chart = () => (
  <div className={styles.loading}>
    <div className={styles.loading1} />
    <div className={styles.loading2} />
    <div className={styles.loading3} />
    <div className={styles.loading4} />
    <div className={styles.loading5} />
  </div>
);

const Histogram = () => (
  <div className={styles.histogramLoading}>
    <div className={styles.histogramLoading1} />
    <div className={styles.histogramLoading2} />
    <div className={styles.histogramLoading3} />
    <div className={styles.histogramLoading4} />
    <div className={styles.histogramLoading5} />
    <div className={styles.histogramLoading6} />
  </div>
);

const Button = rest => (
  <ContentLoader
    speed={3}
    width="70px"
    height="32px"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="32" />
  </ContentLoader>
);

const FilterButton = rest => (
  <ContentLoader
    speed={3}
    width="180px"
    height="25px"
    backgroundColor="#e3e3e3"
    foregroundColor="#cfcfcf"
    {...rest}
  >
    <rect x="0" y="0" rx="15" ry="15" width="180" height="25" />
  </ContentLoader>
);

export default SkeletonLoader;
