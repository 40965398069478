/*
 *
 * App reducer
 *
 */
import produce from 'immer';

import * as constant from './constants';

export const initialState = {
  accessToken: localStorage.getItem('access_token'),
  domain: '',
  email: '',
  name: '',
  modal1Visible: false,
  cardVisible: false,
  newVersion: false,
  onLine: true,
  showCancelButton: true,
  trackUserOnFS: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constant.HANDLE_MODAL_VISIBLE:
        draft.modal1Visible = action.payload.modal1Visible;
        draft.cardVisible = action.payload.cardVisible;
        break;
      case constant.HANDLE_USER:
        draft.email = action.payload.email;
        draft.domain = action.payload.domain;
        draft.name = action.payload.name;
        draft.trackUserOnFS = action.payload.trackUserOnFS;
        break;
      case constant.CHANGE_NEW_VERSION:
        draft.newVersion = action.payload;
        break;
      case constant.CHANGE_ONLINE:
        draft.onLine = action.payload;
        break;
      case constant.HANDLE_CANCLE_BUTTON:
        draft.showCancelButton = action.payload.showCancelButton;
        break;
      case constant.CHANGE_ACCESS_TOKEN:
        draft.accessToken = localStorage.getItem('access_token');
        break;
    }
  });

export default appReducer;
