/**
 *
 * Component: AddTags
 * Date: 23/12/2020
 *
 */

import React, {
  useState,
  useRef,
  useReducer,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useQuery } from 'react-query';
import _ from 'lodash';
import dompurify from 'dompurify';
import Search from 'antd/lib/input/Search';
import produce from 'immer';

import {
  isTagPresent,
  tagAppliedMixpanelEvent,
  getTooltipMessage,
} from 'utils/commonFunctions';
import Auth from 'auth0-react';
import { Checkbox, Row, Col, Text, Button, Tooltip } from 'components/common';
import { BUTTON_TOOLTIP, TEXT_TOOLTIP } from 'utils/commonConstants';

import { fetchAllTags } from './api';
import styles from './style.css';

const { isScopePresent } = new Auth();
const hasAddCustomTags = isScopePresent('add_custom_tags');
const hasDeleteCustomTags = isScopePresent('delete_custom_tags');
const hasAddSystemTags = isScopePresent('add_system_tags');
const hasDeleteSystemTags = isScopePresent('delete_system_tags');
function AddTags({
  selectedItems,
  isSelectAll,
  onApplyTags,
  disabled,
  placement = 'right',
  setToolTipInfo,
}) {
  const initialState = {
    searchTag: '',
    tagsState: [],
    displayTags: [],
    initialSelectedTags: [],
    initialPartialTags: [],
    selectedTags: [],
    unSelectedTags: [],
    allTags: [],
    partialTags: [],
  };
  if (window.Cypress) {
    window.initialState = initialState;
  }
  function reducer(state, action) {
    switch (action.type) {
      case 'updateTagParameters': {
        const {
          tagsState,
          selectedTags,
          unSelectedTags,
          searchTag,
          partialTags,
          allTags,
          displayTags,
        } = action.payload;
        return {
          ...state,
          tagsState,
          selectedTags,
          unSelectedTags,
          searchTag,
          partialTags,
          allTags,
          displayTags,
          initialSelectedTags: _.cloneDeep(selectedTags),
          initialPartialTags: _.cloneDeep(partialTags),
        };
      }
      case 'selectTag': {
        const { selectedTags, unSelectedTags } = action.payload;
        return {
          ...state,
          selectedTags,
          unSelectedTags,
        };
      }
      case 'filterTags': {
        const { searchTag, displayTags } = action.payload;
        return {
          ...state,
          searchTag,
          displayTags,
        };
      }
      case 'onTagCreate': {
        const { tagsState, selectedTags } = action.payload;
        return {
          ...state,
          tagsState,
          selectedTags,
        };
      }
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    searchTag,
    tagsState,
    displayTags,
    selectedTags,
    unSelectedTags,
    allTags,
    partialTags,
    initialSelectedTags,
    initialPartialTags,
  } = state;
  const [showTags, setShowTags] = useState(false);

  const tagWrapper = useRef();
  const addTagPopover = useRef();
  const queryClient = useQueryClient();

  const { data: tags, isLoading } = useQuery('fetchAllTags', fetchAllTags, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  /**
   * Handles Outside Click Event
   * @param {HTMLElement} e: onClick htmlElement.
   * returns nothing.
   */
  const handleClickEvent = e => {
    if (tagWrapper.current) {
      if (tagWrapper.current.contains(e.target) || e.target.id === 'showTags') {
        // The click is inside, continue to whatever you are doing
        return;
      }
      if (showTags) setShowTags(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEvent, false);
    return () =>
      document.removeEventListener('mousedown', handleClickEvent, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTags]);

  useEffect(() => {
    if (!isLoading && tags) updateTagParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  /**
   * filters the tagsArgs, based on having scope for editing and type is user.
   * @param {Array} tagsArgs : Objects Array for all Tags.
   * @returns {Array} of New Tags.
   */
  const getAccessedTags = tagsArgs => tagsArgs;
  //  {
  //   const newTags = tagsArgs.filter(f => {
  //     // if (hasEditTagsScope || f.type === 'user') {
  //     return true;
  //     // }
  //     // return false;
  //   });
  //   return newTags;
  // };

  /**
   * gets all Selected Tags as well as Partially selected tags from SelectedItems. and updates the redux state.
   */
  const updateTagParameters = () => {
    const newTag = _.cloneDeep(tags || []);
    // newTag = getAccessedTags(newTag);
    let newAllTags = [];
    const selectedTagsId = [];
    const partialTagsId = [];
    if (isSelectAll) {
      newAllTags = [...newTag];
    } else if (selectedItems?.length) {
      selectedItems.forEach(item => {
        if (item.tags)
          item.tags.forEach(tag => {
            if (!isTagPresent(newAllTags, tag))
              newAllTags.push(
                newTag.find(newTagArg => tag._id === newTagArg._id) || tag,
              );
          });
      });
    }
    const newSelectedTags = [];
    const newPartialTags = [];
    newAllTags = getAccessedTags(newAllTags);
    newAllTags.forEach(tag => {
      let selected = !isSelectAll;
      selectedItems.forEach(item => {
        if (!isTagPresent(item.tags, tag)) {
          selected = false;
        }
      });
      if (selected) {
        newSelectedTags.push(tag);
        selectedTagsId.push(tag._id);
      } else {
        newPartialTags.push(tag);
        partialTagsId.push(tag._id);
      }
    });

    dispatch({
      payload: {
        tagsState: newTag,
        selectedTags: newSelectedTags,
        unSelectedTags: [],
        searchTag: '',
        partialTags: newPartialTags,
        allTags: newAllTags,
        displayTags: functionForTagsOrder(newTag, false),
      },
      type: 'updateTagParameters',
    });
  };

  /**
   * Gets all Selected Tags, PartialTags and display tags, from tagsArgs, in same order.
   * @param {Array} tagsArgs : All Tags applied array.
   * @param {Boolean} filter : Filter to be applied on Selected and and PartialSelected Tags
   * @returns {Array} returns selectedTags, PartialTags as well as newDisplayTags.
   */
  const functionForTagsOrder = (tagsArgs, filter) => {
    const newTag = getAccessedTags(tagsArgs);
    let newAllTags = [];
    const selectedTagsId = [];
    const partialTagsId = [];
    if (selectedItems?.length)
      selectedItems.forEach(item => {
        if (item?.tags)
          item.tags.forEach(tag => {
            if (!isTagPresent(newAllTags, tag))
              newAllTags.push(
                newTag.find(newTagArg => tag._id === newTagArg._id) || tag,
              );
          });
      });
    let newSelectedTags = [];
    let newPartialTags = [];
    newAllTags = getAccessedTags(newAllTags);
    newAllTags.forEach(tag => {
      let selected = true;
      selectedItems.forEach(item => {
        if (!isTagPresent(item.tags, tag)) {
          selected = false;
        }
      });
      if (selected) {
        newSelectedTags.push({ ...tag });
        selectedTagsId.push(tag._id);
      } else {
        newPartialTags.push({ ...tag });
        partialTagsId.push(tag._id);
      }
    });

    const newDisplayTags = _.orderBy(
      newTag.filter(f => {
        if (selectedTagsId.includes(f._id) || partialTagsId.includes(f._id)) {
          return false;
        }
        return true;
      }),
      ['group', 'name'],
      ['asc', 'asc'],
    );
    if (filter) {
      const tagsId = newTag.map(f => f._id);
      newSelectedTags = newSelectedTags.filter(f => tagsId.includes(f._id));
      newPartialTags = newPartialTags.filter(f => tagsId.includes(f._id));
    }

    newSelectedTags = _.orderBy(
      newSelectedTags,
      ['groupt', 'name'],
      ['asc', 'asc'],
    );
    newSelectedTags.forEach(tagArgs => {
      const tag = _.cloneDeep(tagArgs);
      const temp = newTag.find(e => e._id === tag._id);
      if (temp?.formattedGroup) tag.formattedGroup = temp.formattedGroup;
      if (temp?.formattedName) tag.formattedName = temp.formattedName;
    });
    newPartialTags = _.orderBy(
      newPartialTags,
      ['groupt', 'name'],
      ['asc', 'asc'],
    );
    newPartialTags.forEach(tagArgs => {
      const tag = _.cloneDeep(tagArgs);
      const temp = newTag.find(e => e._id === tag._id);
      if (temp?.formattedGroup) tag.formattedGroup = temp.formattedGroup;
      if (temp?.formattedName) tag.formattedName = temp.formattedName;
    });

    return [...newSelectedTags, ...newPartialTags, ...newDisplayTags];
  };

  /**
   * subtracts the height of AddTag Div from innerHeight and updates the position of Addtag container based on that
   * such that addtag is always visible besides the AddTag Button, on the intended side
   */

  const handleAddTagsPosition = () => {
    if (
      window.innerHeight - tagWrapper.current?.getBoundingClientRect()?.bottom <
      370
    ) {
      addTagPopover.current.classList.add(styles.addTagPopoverFixed);
      addTagPopover.current.classList.remove(styles.addTagPopoverAbsolute);
      addTagPopover.current.style.top = `${window.innerHeight - 380}px`;
      if (placement === 'left') {
        addTagPopover.current.style.left = `${tagWrapper.current?.getBoundingClientRect()
          ?.left - 280}px`;
      }
      if (placement === 'right') {
        addTagPopover.current.style.left = `${
          tagWrapper.current?.getBoundingClientRect()?.right
        }px`;
      }
    } else {
      addTagPopover.current.classList.remove(styles.addTagPopoverFixed);
      addTagPopover.current.removeAttribute('style');
      if (placement === 'left') {
        addTagPopover.current.classList.add(styles.addTagPopoverAbsoluteLeft);
      }
      if (placement === 'right') {
        addTagPopover.current.classList.add(styles.addTagPopoverAbsoluteRight);
      }
    }
  };

  /**
   * sets visibility of tags on or off, and updates tagParameters.
   */
  const onDropDownStateChange = () => {
    handleAddTagsPosition();
    setShowTags(!showTags);
    updateTagParameters();
  };

  /**
   * filters out Slected and unselected tags out.
   * @param {Number} index: index of the display tag.
   * @param {HTMLElement} e: checkbox element whether true or not.
   */
  const selectTag = (index, e) => {
    let newSelectedTags = getAccessedTags([...selectedTags]);
    let newUnSelectedTags = getAccessedTags([...unSelectedTags]);
    const newPartialTags = getAccessedTags(partialTags);
    const newAllTags = getAccessedTags(allTags);

    const tag = displayTags[index];
    if (
      e.target.checked &&
      ((hasAddCustomTags && tag.type === 'user') ||
        (hasAddSystemTags && tag.type === 'system'))
    ) {
      // check if tag is a partial tag and check is not from unselected list
      if (
        !(
          isTagPresent(newUnSelectedTags, tag) &&
          isTagPresent(newPartialTags, tag)
        )
      ) {
        newSelectedTags.push(tag);
      }
      newUnSelectedTags = newUnSelectedTags.filter(t => tag._id !== t._id);
    } else if (
      (hasDeleteCustomTags && tag.type === 'user') ||
      (hasDeleteSystemTags && tag.type === 'system')
    ) {
      newSelectedTags = newSelectedTags.filter(t => tag._id !== t._id);
      if (isTagPresent(newAllTags, tag)) {
        newUnSelectedTags.push(tag);
      }
    }

    dispatch({
      payload: {
        selectedTags: newSelectedTags,
        unSelectedTags: newUnSelectedTags,
      },
      type: 'selectTag',
    });
  };

  /**
   * if no exact match found return empty else provides all matched tags, along with filtering over tags.
   * and updates the same in redux store.
   * @param {String} searchTagArg: Tag to search out for.
   * @param {Array} tagsArg : Array of tags available.
   */
  const filterTags = (searchTagArg, tagsArg = null) => {
    let newSearchTag = searchTagArg;
    const newTag = _.cloneDeep(getAccessedTags(tagsArg || tagsState));
    let tagMatched = false;
    const newDisplayTags = [];
    const regexFormattedSearchTag = newSearchTag
      .trimLeft()
      .replace(RegExp(`[!?+@#$%^&*_.]`, 'g'), match => `\\${match}`);

    const testString = (s, b) => {
      if (s) {
        const match = RegExp(`(?:\\b|_)(${b})`, 'i').exec(s); // Test word boundary or starts with '_'
        if (match) return true;
      }
      return false;
    };

    const boldString = (s, b) => {
      // match[0] is full match whereas [1] is only the string without leading '_'
      const match = RegExp(`(?:\\b|_)(${b})`, 'i').exec(s)[1];
      return s.replace(RegExp(`${match}`, 'i'), `<b>$&</b>`); // where '$&' returns matched substring
    };

    newTag.forEach(tag => {
      const exactMatchFound =
        tag.name.toLowerCase() === regexFormattedSearchTag;
      const matchInTagName = testString(tag.name, regexFormattedSearchTag);
      const matchInTagGroup = testString(tag.group, regexFormattedSearchTag);

      if (exactMatchFound) {
        tagMatched = true;
      }

      if (matchInTagGroup) {
        newDisplayTags.push({
          ...tag,
          formattedGroup: (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(
                  `${boldString(tag.group, regexFormattedSearchTag)}/`,
                ),
              }}
            />
          ),
        });
      } else if (matchInTagName) {
        newDisplayTags.push({
          ...tag,
          formattedName: (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(
                  boldString(tag.name, regexFormattedSearchTag),
                ),
              }}
            />
          ),
        });
      }
      // added formattedName and formatterdGroup to keep original values untouched which are used in tooltip <etc></etc>
    });

    if (tagMatched) {
      newSearchTag = '';
    }

    dispatch({
      payload: {
        searchTag: newSearchTag,
        displayTags: functionForTagsOrder(newDisplayTags, true),
      },
      type: 'filterTags',
    });
  };

  /**
   * Creates a tag on Call.
   */
  const onTagCreate = () => {
    const tag = {
      _id: searchTag,
      name: searchTag,
      type: 'user',
      group: 'custom tag',
    };
    const newSelectedTags = [...selectedTags];
    newSelectedTags.push(tag);
    dispatch({
      payload: {
        tagsState: [..._.cloneDeep(tags), tag],
        selectedTags: newSelectedTags,
      },
      type: 'onTagCreate',
    });
    filterTags(searchTag, [..._.cloneDeep(tags), tag]);
  };

  /**
   * Applies tags
   */
  const handleApplyTags = () => {
    setShowTags(!showTags);
    // this is called to update querysearch or API call in SCV
    // itemIds condition Makes sure that tooltip don't show up when op is done on multiple calls
    if (setToolTipInfo && itemIds.length < 2) {
      setToolTipInfo(getTooltipMessage(addTagList, removeTagList));
    }
    onApplyTags(itemIds, addTagList, removeTagList);
    let tagAppliedEventArgs = {
      itemIds,
      addTagList,
      removeTagList,
      selectedTags,
    };
    const filterTagsArgs = [searchTag];
    const newCustomTagCount =
      addTagList.filter(tag => tag._id.indexOf('/') === -1)?.length || 0;

    if (addTagList.some(tag => tag._id.indexOf('/') === -1)) {
      const updateTagsCustomId = argValue =>
        // eslint-disable-next-line no-unused-vars
        produce(argValue, draft => {
          // eslint-disable-next-line no-param-reassign
          draft = [
            ...draft.map(sketch => {
              if (sketch._id.indexOf('/') === -1) {
                sketch._id = `custom tag/${sketch._id}`;
              }
              return sketch;
            }),
          ];
        });
      const newTagsState = updateTagsCustomId(tagsState);
      const newSelectedTags = updateTagsCustomId(selectedTags);
      dispatch({
        type: 'onTagCreate',
        payload: { selectedTags: newSelectedTags, tagsState: newTagsState },
      });
      queryClient.setQueryData(['fetchAllTags'], () =>
        _.cloneDeep(newTagsState),
      );
      const getNewTaglengths = () => {
        if (tagsState.length === tags.length)
          return { addLength: addTagList.length, newLength: 0 };
        const addLengthTemp = newTagsState.length - tags.length;
        return {
          addLength: addLengthTemp,
          newLength: addTagList.length - addLengthTemp,
        };
      };
      const { addLength, newLength } = getNewTaglengths();
      tagAppliedEventArgs = {
        ...tagAppliedEventArgs,
        newLength,
        addLength,
        newCustomTagCount,
      };
      filterTagsArgs.push(newTagsState);
    }
    if (displayTags.length !== tagsState.length) {
      filterTags(...filterTagsArgs);
    }
    tagAppliedMixpanelEvent(tagAppliedEventArgs);
  };

  /**
   * Simple iteration to add all seleted tags and remove all unselected tags from addTagList.
   * */
  const itemIds = [];
  selectedItems.forEach(row => {
    if (row.id) itemIds.push(row.id);
    else if (row.callId) itemIds.push(row.callId);
    else if (row.borrowerId) itemIds.push(row.borrowerId);
  });
  const addTagList = [];
  const removeTagList = [];
  selectedTags.forEach(tag => {
    if (isTagPresent(partialTags, tag) || !isTagPresent(allTags, tag))
      addTagList.push(tag);
  });
  unSelectedTags.forEach(tag => {
    if (isTagPresent(allTags, tag)) removeTagList.push(tag);
  });

  const getDisabledState = useCallback(
    tagArg => {
      if (
        !hasAddCustomTags &&
        tagArg.type === 'user' &&
        (!isTagPresent(initialSelectedTags, tagArg) ||
          isTagPresent(initialPartialTags, tagArg))
      ) {
        return true;
      }
      if (
        !hasDeleteCustomTags &&
        tagArg.type === 'user' &&
        (isTagPresent(initialSelectedTags, tagArg) ||
          isTagPresent(initialPartialTags, tagArg))
      ) {
        return true;
      }
      if (
        !hasAddSystemTags &&
        tagArg.type === 'system' &&
        (!isTagPresent(initialSelectedTags, tagArg) ||
          isTagPresent(initialPartialTags, tagArg))
      ) {
        return true;
      }
      if (
        !hasDeleteSystemTags &&
        tagArg.type === 'system' &&
        (isTagPresent(initialSelectedTags, tagArg) ||
          isTagPresent(initialPartialTags, tagArg))
      ) {
        return true;
      }
      return false;
    },
    [initialSelectedTags, initialPartialTags],
  );

  return (
    <div className={styles.container}>
      <div className={styles.tagContainer} ref={tagWrapper}>
        <Tooltip title="Add a manual Tag" type={BUTTON_TOOLTIP}>
          <Button
            type="link"
            icon="addTag"
            iconSize={17}
            text="Add Tags"
            onClick={onDropDownStateChange}
            disabled={disabled}
          />
        </Tooltip>
        {
          <div
            ref={addTagPopover}
            style={{
              visibility: showTags && !isLoading ? 'visible' : 'hidden',
            }}
            className={`${styles.tagBoxHolder} ${styles[placement]}`}
          >
            {showTags && !isLoading ? (
              <div className={styles.tagBoxFixed}>
                <div className={styles.tagBox} data-testid="tagbox-cy">
                  <div>
                    <Row>
                      <Search
                        data-testid="search-cy"
                        allowClear
                        maxLength={40}
                        placeholder="Search Tag"
                        onChange={e => {
                          filterTags(e.target.value);
                        }}
                      />
                    </Row>
                    {tagsState.length ? (
                      <Row
                        className={styles.tagFilter}
                        data-testid="Checkbox-cy"
                      >
                        {displayTags.map((tag, index) => (
                          <Row key={tag._id}>
                            <Col span={24}>
                              <Checkbox
                                disabled={getDisabledState(tag)}
                                className={styles.checkbox}
                                checked={isTagPresent(selectedTags, tag)}
                                indeterminate={
                                  isTagPresent(partialTags, tag) &&
                                  !isTagPresent(unSelectedTags, tag) &&
                                  !isTagPresent(selectedTags, tag)
                                }
                                onChange={e => selectTag(index, e)}
                              >
                                <Tooltip
                                  title={`${tag.group ? `${tag.group}/` : ''}${
                                    tag.name
                                  }`}
                                  type={TEXT_TOOLTIP}
                                >
                                  {tag.group
                                    ? tag.formattedGroup || `${tag.group}/`
                                    : ''}
                                  {tag.formattedName
                                    ? tag.formattedName
                                    : tag.name}
                                </Tooltip>
                              </Checkbox>
                            </Col>
                          </Row>
                        ))}
                      </Row>
                    ) : null}
                  </div>
                  <div className={styles.tagBoxBtns}>
                    {addTagList.length || removeTagList.length ? (
                      <Button
                        type="primary"
                        text="Apply"
                        onClick={() => handleApplyTags()}
                      />
                    ) : null}
                    {searchTag ? (
                      <>
                        <Text
                          className={styles.paragraph}
                          text="Search result not found."
                        />
                        <Button
                          onClick={onTagCreate}
                          type="link-primary"
                          text={`+ Create New tag "${searchTag}"`}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      </div>
    </div>
  );
}

AddTags.propTypes = {
  selectedItems: PropTypes.array,
  isSelectAll: PropTypes.bool,
  onApplyTags: PropTypes.func,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
  setToolTipInfo: PropTypes.func,
};

export default AddTags;
