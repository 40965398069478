/**
 *
 * Component: VisualConfig
 * Date: 6/7/2020
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// import message from 'utils/message';
import { changeIndexOfArray } from 'utils/commonFunctions';
import { Input, Card, Text, Button } from 'components/common';
import { notificationError } from 'utils/notification';

import LoadingIndicator from '../LoadingIndicator';
import VisualConfigCard from '../VisualConfigCard';
import style from './style.css';

const { Validator } = require('jsonschema');
const v = new Validator();
const defaultVisualConfig = {
  id: 'defaultId',
  isPublic: false,
  name: 'defaultName',
  thumbnail: '',
  thumbnailUrl: null,
};
function VisualConfig({ configs, loading, modifyConfig, fetchList }) {
  const configRef = useRef(configs?.configs?.config);
  const [config, setconfig] = useState(configs?.configs?.config || {});
  const [acceptDropList, setacceptDropList] = useState([]);

  useEffect(() => {
    const clonedConfig = _.cloneDeep(configRef.current);
    const newAcceptDropList =
      clonedConfig?.visualsList?.map(vis => vis.id) || [];
    setacceptDropList(newAcceptDropList);
  }, [config]);

  useEffect(() => {
    if (configs?.configs?.config) {
      setconfig(configs?.configs?.config);
      configRef.current = configs?.configs?.config;
    }
  }, [configs?.configs?.config, setconfig]);

  const handleDrop = (dragIndex, dropIndex) => {
    /**
     *to change order of visual list elements
     */
    if (configRef.current.visualsList) {
      configRef.current.visualsList = changeIndexOfArray(
        configRef.current.visualsList,
        dragIndex,
        dropIndex,
      );
      setconfig(_.cloneDeep(configRef.current));
    }
  };

  const addNewButton = () => {
    if (configRef.current.visualsList.length) {
      configRef.current.visualsList.push(defaultVisualConfig);
    } else {
      /**
       * if no entry is present than creating a default state for visualList
       * */
      configRef.current.visualsList = [defaultVisualConfig];
    }
    setconfig(_.cloneDeep(configRef.current));
  };

  const handleSave = () => {
    const validation = v.validate(
      configRef.current,
      configs.configs.configSchema,
    );
    if (validation.valid) {
      modifyConfig(configRef.current);
    } else {
      notificationError(validation.errors && validation.errors[0].stack);
      // message.error(validation.errors && validation.errors[0].stack);
    }
  };

  const deleteVisual = id => {
    const newConfig = configRef.current;
    const newvisualIndex = newConfig.visualsList.findIndex(
      vis => vis.id === id,
    );
    if (newvisualIndex >= 0) {
      newConfig.visualsList.splice(newvisualIndex, 1);
    }
    setconfig(_.cloneDeep(newConfig));
  };

  const handleRefChange = visualListIndex => (e, type) => {
    const newVisualState = configRef.current.visualsList[visualListIndex];
    newVisualState[type] = e.target.value;
  };

  if (_.isEmpty(config)) return <LoadingIndicator />;
  return (
    <div className={style.container}>
      <Card>
        <div className={style.visualDetails}>
          <div>
            <Text text="Site" type="label" />
            <Input
              onChange={e => {
                configRef.current.site = e.target.value;
              }}
              defaultValue={configs.configs.config.site}
            />
          </div>
          <div>
            <Text text="User" type="label" />
            <Input
              onChange={e => {
                configRef.current.user = e.target.value;
              }}
              defaultValue={configs.configs.config.user}
            />
          </div>
        </div>
      </Card>

      {config &&
        !_.isEmpty(acceptDropList) &&
        config?.visualsList?.map((visual, index) => (
          <VisualConfigCard
            key={visual.id}
            handleDrop={handleDrop}
            acceptDropList={acceptDropList}
            deleteVisual={deleteVisual}
            visual={visual}
            visualIndex={index}
            handleRefChange={handleRefChange(index)}
            fetchList={fetchList}
          />
        ))}
      <div className={style.actionButtons}>
        <Button
          type="link-primary"
          onClick={() => addNewButton()}
          icon="plus"
          text="Add new"
        />
        <Button
          type="primary"
          loading={loading}
          onClick={handleSave}
          icon="save"
          text="Save"
        />
      </div>
    </div>
  );
}

VisualConfig.propTypes = {
  configs: PropTypes.object,
  loading: PropTypes.bool,
  modifyConfig: PropTypes.func,
  fetchList: PropTypes.func,
};

export default VisualConfig;
