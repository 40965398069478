/**
 *
 * Component: TranscriptWithSearch
 * Date: 21/4/2022
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { formatTimeAddPadding } from 'utils/commonFunctions';
import { Text } from 'components/common';

import styles from './style.css';

const TranscriptWithSearch = ({
  call,
  eachTranscript,
  audioCurrentTime,
  handlePerWordClick,
  handleAgentandTimeClick,
  memoizeFlag,
  playingRef,
  highlightRef,
  searchInput,
  currentSearchIndex,
  index,
}) =>
  useMemo(() => {
    const Highlighted = ({ text = '', highlight = '' }) => {
      if (!highlight.trim()) {
        return <span>{text}</span>;
      }
      const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
      const parts = text.split(regex) || [];
      let spanIndex = 0;

      /**
       * Calculates index of word on each transcript, add indexes from last regex segments
       * @param {*} indexofpart
       * @param {*} currentIndex
       * @returns
       */

      const calcSpanIndex = (indexofpart, currentIndex) => {
        let prevspanIndex = 0;
        parts.map((part, i) => {
          if (indexofpart > i) {
            prevspanIndex += part.split(' ').length - 1;
          }
          return null;
        });

        return +prevspanIndex + currentIndex;
      };
      const isAgentSpeaker = call?.speakers?.agent === eachTranscript?.speaker;
      /**
       * checks whether to highlight current word, is the particular index focused
       * @param  partIndex current index of part
       * @returns Boolean value to decide whether to make text orange or yellow, orange being focused search
       */
      const isWordCurrentIndex = partIndex => {
        if (currentSearchIndex?.split('-') && partIndex && index) {
          return (
            currentSearchIndex?.split('-')?.[0] === index?.toString() &&
            currentSearchIndex?.split('-')?.[1] === partIndex?.toString()
          );
        }
        return false;
      };

      /**
       * Regex Test decides whether text is to highlighted, also isWordCurrentIndex decides
       * if the occurrence is on focus in search
       */

      return (
        <div className={styles.utterance}>
          <Text
            type="subtitle"
            className={
              // eslint-disable-next-line no-nested-ternary
              call?.speakers ? (isAgentSpeaker ? 'orange' : 'blue') : 'black'
            }
            style={{ cursor: 'pointer' }}
            text={
              // eslint-disable-next-line no-nested-ternary
              call?.speakers
                ? isAgentSpeaker
                  ? 'Agent '
                  : 'Customer '
                : 'Segment '
            }
            onClick={() => {
              handleAgentandTimeClick(eachTranscript?.stime, eachTranscript);
            }}
          />
          <Text
            type="subtitleSmall"
            style={{ cursor: 'pointer' }}
            text={`${formatTimeAddPadding(
              eachTranscript?.stime,
            )} - ${formatTimeAddPadding(eachTranscript?.etime)}`}
            onClick={() => {
              handleAgentandTimeClick(eachTranscript?.stime, eachTranscript);
            }}
          />
          <div
            ref={
              audioCurrentTime >= eachTranscript?.stime &&
              audioCurrentTime < eachTranscript?.etime
                ? playingRef
                : null
            }
          >
            {parts.map((part, i) => {
              // eslint-disable-next-line no-unused-expressions
              i !== 0
                ? (spanIndex = spanIndex + parts[i - 1].split(' ').length - 1)
                : null;

              return regex.test(part) ? (
                <>
                  <span
                    className={
                      isWordCurrentIndex(i)
                        ? styles.textCurrentHighlighted
                        : styles.textHiglighted
                    }
                    ref={ele => {
                      // eslint-disable-next-line no-param-reassign
                      highlightRef.current[
                        `${eachTranscript?.stime}-${i}`
                      ] = ele;
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${eachTranscript?.stime}-${i}`}
                  >
                    {part.split(' ').map((eachSpan, currIndex) => (
                      <>
                        <span
                          role="button"
                          tabIndex="0"
                          className={
                            audioCurrentTime >=
                              eachTranscript?.spanTimingDetails[
                                spanIndex + currIndex
                              ]?.start &&
                            audioCurrentTime <
                              eachTranscript?.spanTimingDetails[
                                spanIndex + currIndex
                              ]?.end
                              ? styles.playing
                              : ''
                          }
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            handlePerWordClick(
                              Number(
                                eachTranscript?.spanTimingDetails[
                                  calcSpanIndex(i, currIndex)
                                ]?.start,
                              ),
                              eachSpan,
                            );
                          }}
                        >
                          {eachSpan}
                          {currIndex !== part.split(' ').length - 1 ? ' ' : ''}
                        </span>
                      </>
                    ))}
                  </span>
                </>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <span key={i}>
                  {part.split(' ').map((eachSpan, currIndex) => (
                    <>
                      <span
                        role="button"
                        tabIndex="0"
                        className={
                          audioCurrentTime >=
                            eachTranscript?.spanTimingDetails?.[
                              spanIndex + currIndex
                            ]?.start &&
                          audioCurrentTime <
                            eachTranscript?.spanTimingDetails?.[
                              spanIndex + currIndex
                            ]?.end
                            ? styles.playing
                            : ''
                        }
                        onClick={() => {
                          handlePerWordClick(
                            Number(
                              eachTranscript?.spanTimingDetails[
                                calcSpanIndex(i, currIndex)
                              ]?.start,
                            ),
                            eachSpan,
                          );
                        }}
                      >
                        {eachSpan}
                      </span>
                      {currIndex !== part.split(' ').length - 1 ? ' ' : ''}
                    </>
                  ))}
                </span>
              );
            })}
          </div>
        </div>
      );
    };
    Highlighted.propTypes = {
      text: PropTypes.string,
      highlight: PropTypes.string,
    };

    return <Highlighted text={eachTranscript?.text} highlight={searchInput} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eachTranscript,
    searchInput,
    memoizeFlag(eachTranscript),
    currentSearchIndex,
  ]);

TranscriptWithSearch.propTypes = {};

export default TranscriptWithSearch;
