import produce from 'immer';

/**
 * Provides suitable grade for the score existing in the provided range.
 * @param {Object} scorecardArg : Object for defining grades with min and max score.
 * @returns {String} Id of the grade.
 */
const getGrades = scorecardArg => {
  if (scorecardArg.maxScore) {
    const scorepercentage = Math.round(
      (scorecardArg.adjustedScore * 100) / scorecardArg.maxScore,
    );
    const newGrade = scorecardArg.gradeOptions.find(
      grade => grade.min <= scorepercentage && grade.max >= scorepercentage,
    );
    if (newGrade?.id) {
      return newGrade.id;
    }
  }
  return '';
};
/**
 * Resets the score card to the previous value, which was there before answering any questions.
 * @param {Object} payload
 * @returns {Object}
 */
const initialScoreResetOfScorecard = payload => {
  const originalItems = produce(payload, sketch => {
    // Reset template level scores to 0
    sketch.score = 0;
    let scorecardScore = 0;
    sketch.maxScore = 0;
    let scorecardMaxScore = 0;

    sketch.sections.forEach((section, sectionIdx) => {
      if (section.sectionType === 'SIMPLE') {
        // Reset section lvel scores to 0
        sketch.sections[sectionIdx].score = 0;
        let sectionScore = 0;
        sketch.sections[sectionIdx].maxScore = 0;
        let sectionMaxScore = 0;

        section.questions.forEach((question, questionIdx) => {
          if (question.qType === 'OPTIONS') {
            // Calculate max question score
            const questionMaxScore = Math.max(
              question.qOptions.reduce((accumalator, nextObj) =>
                accumalator.oScore > nextObj.oScore ? accumalator : nextObj,
              ).oScore,
            );
            sketch.sections[sectionIdx].questions[
              questionIdx
            ].maxScore = questionMaxScore;
            sectionMaxScore += questionMaxScore;
            scorecardMaxScore += questionMaxScore;
            // If question has been answered already or has been autoFilled
            if (question.value >= 0) {
              const questionScore = question.qOptions[question.value].oScore;

              sketch.sections[sectionIdx].questions[
                questionIdx
              ].score = questionScore;

              sectionScore += questionScore;
              scorecardScore += questionScore;
              if (
                question.qOptions[question.value].oType === 'NEGATIVE' &&
                question.isAutoFail
              )
                sketch.autoFail = true;
            }
          } else {
            const questionMaxScore =
              sketch.sections[sectionIdx].questions[questionIdx].maxScore;
            sectionMaxScore += questionMaxScore ?? 0;
            scorecardMaxScore += questionMaxScore ?? 0;
          }
        });
        sketch.sections[sectionIdx].score += sectionScore;
        sketch.sections[sectionIdx].maxScore += sectionMaxScore;
      } else {
        // If every question has been answered and none are negative in ALL type section
        const isEveryQuestionPositive = section.questions.every(
          question =>
            question.qType === 'FREEFORM' ||
            (question.value >= 0 &&
              question?.qOptions[question.value].oType !== 'NEGATIVE'),
        );
        if (isEveryQuestionPositive) {
          sketch.sections[sectionIdx].score =
            sketch.sections[sectionIdx].maxScore;
          sketch.score += sketch.sections[sectionIdx].maxScore;
        } else {
          // Not every question has been answered, hence check if there is a autoFail case
          sketch.autoFail = section.questions.some(question => {
            const isQuestionUnanswered = !question.value;
            const isQuestionAnsweredNonNegative =
              question.qType === 'FREEFORM' ||
              question.qOptions[question.value]?.oType !== 'NEGATIVE';

            if (question.isAutoFail) {
              if (isQuestionUnanswered || isQuestionAnsweredNonNegative) {
                return false;
              }
              // question is answered negatively => section fails => call fail
              return true;
            }
            // since question is not autoFail it won't change autoFail status of call
            return false;
          });
        }
        sketch.maxScore += sketch.sections[sectionIdx].maxScore;
      }
    });
    // This is the absolute max score of a template
    sketch.score += scorecardScore;
    sketch.maxScore += scorecardMaxScore;
    sketch.adjustedScore =
      sketch.autoFail && sketch.scoringSystem === 'autoFailAdjusted'
        ? 0
        : sketch.score;
    sketch.grade = getGrades(sketch);
  });

  // NOSCORE type questions alter the maxScore values, hence maintain it in a separate "work copy"
  const items = produce(originalItems, sketch => {
    sketch.sections.forEach((section, sectionIdx) => {
      section.questions.forEach((question, questionIdx) => {
        if (
          question.qType === 'OPTIONS' &&
          question.value >= 0 &&
          question.qOptions[question.value].oType === 'NOSCORE'
        ) {
          sketch.score -= question.score ?? 0;
          sketch.maxScore -= question.maxScore ?? 0;

          sketch.sections[sectionIdx].score -= question.score;
          sketch.sections[sectionIdx].maxScore -= question.maxScore;

          sketch.sections[sectionIdx].questions[questionIdx].score = 0;
          sketch.sections[sectionIdx].questions[questionIdx].maxScore = 0;
        }
      });
    });
    sketch.grade = getGrades(sketch);
  });
  return { originalItems, items };
};
export { initialScoreResetOfScorecard };
