/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button as CustomButton } from 'antd';

import Icon from '../Icon';
import Text from '../Text';
import './style.less';

function Button({
  text,
  type = 'normal',
  icon,
  iconSize,
  iconColor,
  useAntd = false,
  loading,
  ...rest
}) {
  const style = {
    height: rest.height || '32px',
    lineHeight: rest.height || '32px',
    width: rest.width || '100%',
  };

  return (
    <CustomButton
      data-testid="button"
      {...rest}
      className={`custom-btn ant-btn-${type} ${
        rest.className ? rest.className : ''
      }`}
      loading={loading}
      style={style}
    >
      {icon ? (
        <Icon
          useAntd={useAntd}
          size={iconSize || 16}
          type={icon}
          style={{ marginRight: text ? 5 : 0, color: iconColor || null }}
          color={iconColor}
        />
      ) : null}
      {text ? (
        <Text
          text={text}
          style={{ textTransform: rest.texttransform || 'none' }}
        />
      ) : (
        rest.children
      )}
    </CustomButton>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  active: PropTypes.bool,
  iconColor: PropTypes.string,
  useAntd: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
