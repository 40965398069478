/**
 *
 * Component: Mentions
 * Date: 22/11/2021
 *
 */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Editor, Transforms, Range, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSelected,
  useFocused,
} from 'slate-react';
import PropTypes from 'prop-types';

import mixpanel from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import { trimNull } from 'utils/commonFunctions';
import history from 'utils/history';
import Auth from 'auth0-react';

import { fetchMentionsList } from './api';
import styles from './style.css';

const { isScopePresent } = new Auth();
const hasEditScorecardScope = isScopePresent('edit_scorecard');

// 1000 character length limit.
const MAX_CHAR_LENGTH = 1000;

/**
 * Very initial value of the slate structure.
 */
const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
function Mentions({
  autoFocus,
  onSelect,
  onChange,
  scorecardList = [],
  defaultValue = [],
  readOnly = false,
  readOnlyWithBorders = false,
  autoCompleteOff = false,
  callId,
}) {
  const dropboxRef = useRef();
  const [value, setValue] = useState(initialValue);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const { isLoading, data: userList } = useQuery(
    'mentionsList',
    fetchMentionsList,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  const queryClient = useQueryClient();
  const { scorecardTemplates } = queryClient.getQueryData(['appConfig']);
  const [scorecardsData, setScorecardsData] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const editorTextLength = useRef(0);
  const cursorPosition = useRef();

  /**
   * Text Element for slate to use, to render types letter/characters.
   * Separates mentions from plain text.
   */
  const renderElement = useCallback(
    props => <Element {...props} readOnly={readOnly} />,
    [],
  );
  /**
   * Slate editor constant
   */
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    [],
  );

  /**
   * on having a defaultValue, of very initial filled slate structure, (prefilled slate structure).
   * - iterate over the structure, inside the scorecard info,
   * - extract the children and iterate, to identify scorecard info based on _id of the scorecard.
   * - store the extra scorecard info as state.
   * goes in as O(n^2);
   */
  useEffect(() => {
    if (defaultValue.length && scorecardTemplates) {
      const defaultValueTemp = defaultValue;
      scorecardTemplates.forEach(template =>
        defaultValue.forEach((slateElement, slateIndex) =>
          slateElement.children.forEach((subEle, subIndex) => {
            if (
              subEle?.character?.type === 'scorecard' &&
              subEle?.character?.key === template._id
            ) {
              // uncomment to Update name from scorecards data.
              // defaultValueTemp[slateIndex].children[
              //   subIndex
              // ].character.displayOption = `${template.displayOption.split(' ').join('-')} `;
              // Extra state data, which is just complete template object
              defaultValueTemp[slateIndex].children[
                subIndex
              ].character.state = template;
            }
          }),
        ),
      );
      editorTextLength.current =
        serialize(defaultValue)?.plainTextWithoutTrim?.length || 0;
      setValue(defaultValue);
    }
  }, [defaultValue]);

  /**
   * Sets the scorecard info inside the options box.
   * - iterate on the scorecardTemplate from the app config.
   * - if hasEditScope then use the scorecard as it is,
   * - else separate scorecard as the scorecardList data,
   * - fill the resultant filteredScorecardTemplateList, with slate data Object.
   * */
  useEffect(() => {
    if (scorecardTemplates) {
      const list = [];
      let filteredScorecardTemplateList;
      if (hasEditScorecardScope) {
        // Use the scoracrdTemplate as it is.
        // eslint-disable-next-line no-undef
        filteredScorecardTemplateList = _.cloneDeep(
          scorecardTemplates.filter(templateArg => !templateArg.disable),
        );
      } else {
        // else use the scoreacrdList to generate template.
        filteredScorecardTemplateList = scorecardTemplates.filter(template =>
          scorecardList.some(
            response => response.scorecardTemplateId === template._id,
          ),
        );
      }
      // Fill the resultant with data required to be used by slate.
      filteredScorecardTemplateList.forEach(t =>
        list.push({
          actualOption: t._id,
          displayOption: `${t.name.split(' ').join('-')}\0 `,
          type: 'scorecard',
          callId,
          state: t,
        }),
      );
      setScorecardsData(list);
    }
  }, [scorecardTemplates, JSON.stringify(scorecardList)]);

  /**
   * If autoFocus enabled, focus the editor as soon as it mount.
   * focus and move cursor to the end of the last node, asynchronously.
   * https://github.com/ianstormtaylor/slate/issues/1776#issuecomment-386209349
   */
  useEffect(() => {
    if (autoFocus)
      setTimeout(() => {
        ReactEditor.focus(editor);
        Transforms.select(editor, Editor.end(editor, []));
        cursorPosition.current = editor.selection;
      }, 0);
  }, [autoFocus]);

  /**
   * Begin populating the dropbox, on typing in the search item
   * populates on the startsWith searching to extract most common starting results.
   */
  useEffect(() => {
    let users = [];
    let scorecards = [];
    if (userList) {
      // if having a userList, ready to be used inside options
      // iterate through as generate, very initial slate required struct.
      // then filter matchin search results.
      users = userList
        .map(itm => ({
          actualOption: itm.email_address,
          displayOption: `${itm.email_address.split('@')[0]}\0 `,
          type: 'user',
        }))
        .filter(c =>
          `${c.actualOption}`.toLowerCase().startsWith(search.toLowerCase()),
        );
    }

    if (scorecardsData) {
      // No nned to generate slate required data, as already done earlier
      // filter out matching results.
      scorecards = scorecardsData.filter(c =>
        `${c.displayOption}`.toLowerCase().startsWith(search.toLowerCase()),
      );
    }

    // set the ref of options list.
    setOptionsList([...users, ...scorecards]);
  }, [JSON.stringify(userList), JSON.stringify(scorecardsData), search]);

  /** On key press Handler for slate editor.
   * @param event: For getting key code.
   *  */
  const onKeyDown = useCallback(
    event => {
      if (target) {
        const list = optionsList || [];
        switch (event.key) {
          // Move the selection box (blue box) up.
          case 'ArrowDown':
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const prevIndex = index >= list.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          // Move the selection box (blue box) down.
          case 'ArrowUp':
            event.preventDefault();
            // eslint-disable-next-line no-case-declarations
            const nextIndex = index <= 0 ? list.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          // Ignore tabs.
          case 'Tab':
            break;
          // Pressing enter selects the option
          case 'Enter':
            event.preventDefault();
            // fill the text autocomplete functionality
            Transforms.select(editor, target);
            // Fill the mentions info.
            insertMention(editor, list[index]);
            // Callback the selectedMentions list to be send to the backend.
            // value->actualInfo, children->transformed info
            onSelect(
              {
                actualOption: trimNull(`${list[index]?.actualOption}`),
                displayOption: trimNull(`${list[index]?.displayOption}`),
              },
              list[index]?.type || 'user',
            );
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
          default:
            break;
        }
      }
    },
    [index, search, target, optionsList],
  );

  /**
   * Resizes the box of selection to appropriate size, to just fit the options under a limit.
   */
  const positionSelector = () => {
    if (target && dropboxRef.current && optionsList.length > 0) {
      const el = dropboxRef.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset - el.clientHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  };

  /**
   * set dimensions of the options box on changes of
   * editor sate, index change of selected options, search text string
   */
  useEffect(() => {
    positionSelector();
  }, [optionsList.length, editor, index, search, target]);

  /**
   * Clean the slate structure and convert into plain string
   * @param slateInputArray: Slate struct Array of object of slate elements.
   */
  const serialize = slateInputArray => {
    let textString = '';
    slateInputArray.forEach(line => {
      (line.children || []).forEach(subElement => {
        switch (subElement.type) {
          // append mentions text, based on mention type 'user' or 'scorecard'.
          case 'mention':
            textString += `@${subElement?.character?.displayOption}`;
            break;
          // append plain text at the back.
          default:
            textString += `${subElement?.text || ''}`;
            break;
        }
      });
      textString += '\n ';
    });
    return {
      plainText: textString.trim(),
      plainTextWithoutTrim: textString.replace(/\0/g, '').replace(/\n /g, ''),
    };
  };

  /**
   *
   * @param {Obj} inputValue: Slate editor object
   * perform following things
   * - checks max character length
   * - appends search string with underscore and hyphen
   * - sets target to apped charachters on the cursor.
   */
  const handleSlateChange = inputValue => {
    // Extract the plain text.
    const { plainText } = serialize(inputValue);
    const plainTextLength = plainText.length + inputValue.length - 1;
    // Check if maximum length of the string has been taken or not
    if (plainTextLength > MAX_CHAR_LENGTH) {
      // if max length hit, move cursor back and prevent slate struct to update.
      Transforms.select(
        editor,
        cursorPosition.current ?? Editor.end(editor, []),
      );
      return;
    }
    editorTextLength.current = plainTextLength;
    setValue(inputValue);
    onChange(plainText);
    positionSelector();
    const { selection } = editor;
    // if (!selection) return;
    // save the pointer location, for reverting back on achieving max length.
    cursorPosition.current = editor.selection;
    const [start] = Range.edges(selection);
    if (!start) return;
    const rangeOfLineStartToCursor = Editor.range(
      editor,
      { ...start, offset: 0 },
      editor.selection,
    );
    if (!rangeOfLineStartToCursor) return;
    const textBetweenLineStartToCursor = Editor.string(
      editor,
      rangeOfLineStartToCursor,
    );
    // For autocomplete functionality
    // works by checking previous typed string, and appends remaining part from the selected options text.
    // identifies the range upto which user already typed and fills remaining parts from
    // the range to the end to the selected string.
    if (
      !autoCompleteOff &&
      selection &&
      Range.isCollapsed(selection) &&
      textBetweenLineStartToCursor?.indexOf('@') > -1
    ) {
      // wordBefore is the word between cursor and @
      let wordBefore = null;
      const indexOffset = textBetweenLineStartToCursor.lastIndexOf('@');
      wordBefore = { ...start, offset: indexOffset + 1 };

      // With that position extract word till current cursor position.
      // if wordBefore null than following all are null, i.e, beforeMatch shall be null.
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch =
        beforeText && beforeText.match(/^@([a-zA-Z0-9_.-]+)$/);
      if (beforeMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
      // auto-correct disabled or match not found, set target as null, and current cursor position to append text.
      // save the pointer location, for reverting back on achieving max length.
      setTarget(null);
    }
  };

  return (
    <div className={styles.slateEditorBox}>
      <Slate
        editor={editor}
        value={value}
        // eslint-disable-next-line no-shadow
        onChange={handleSlateChange}
      >
        <Editable
          readOnly={readOnly || readOnlyWithBorders}
          renderElement={renderElement}
          onKeyDown={onKeyDown}
          placeholder="Enter some text..."
          className={
            !readOnly
              ? styles.slateEditableTextArea
              : styles.slateNonEditableTextArea
          }
        />
        {/** If not fetching from query and options List has content show the options box. */}
        {!isLoading && target && optionsList.length > 0 && (
          <Portal>
            <div
              ref={dropboxRef}
              className={styles.optionsDropbox}
              data-cy="mentions-portal"
            >
              {optionsList.map((char, i) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  onMouseEnter={() => {
                    if (i !== index) setIndex(i);
                  }}
                  ref={ref =>
                    i === index
                      ? ref?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                        })
                      : null
                  }
                  key={char?.actualOption || ''}
                  onClick={e => {
                    e.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, char);
                    onSelect(
                      {
                        actualOption: trimNull(`${char?.actualOption}`),
                        displayOption: trimNull(`${char?.displayOption}`),
                      },
                      char?.type || 'user',
                    );
                    setTarget(null);
                    ReactEditor.focus(editor);
                  }}
                  className={
                    i === index
                      ? styles.mentionOptionsSelected
                      : styles.mentionOptions
                  }
                >
                  {char.type === 'user' ? (
                    <span className={styles.userIco}>
                      {char?.displayOption[0]?.toUpperCase() || '?'}
                    </span>
                  ) : (
                    <span className={styles.scorecardIco} />
                  )}
                  <span>
                    {char.type === 'user'
                      ? char?.actualOption || '-'
                      : char?.displayOption || '-'}
                  </span>
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
      {!readOnly ? (
        <div className={styles.characterCounter}>
          <span className={styles.counterText}>{`${
            editorTextLength.current
          }/${MAX_CHAR_LENGTH}`}</span>
        </div>
      ) : null}
    </div>
  );
}

/**
 *
 * @param {*} children : content JSX to used inside portal.
 * @returns {JSX}: fill in the options dropbox as a portal,
 *
 */
const Portal = ({ children }) =>
  typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;

/**
 * Slate wrapper for mentions.
 * Perfoems checks on the slate element.
 * @param {*} editor : Slate editor
 * @returns Slate element with checks.
 */
const withMentions = editor => {
  const { isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = element =>
    element.type === 'mention' ? true : isInline(element);

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = element =>
    element.type === 'mention' ? true : isVoid(element);

  return editor;
};

/**
 *
 * @param {Object} editor: slate editor.
 * @param {Object} character: slate mentions object with data keys.
 */
const insertMention = (editor, character) => {
  const mention = {
    type: 'mention',
    character,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

/**
 * Text rendering element of the slate, Helps in filling text text wrapped inside an editor Element.
 */
const Element = props => {
  // eslint-disable-next-line react/prop-types
  const { attributes, children, element } = props;
  switch (element.type) {
    // For mentions uses dedicated mentions element.
    case 'mention':
      return <Mention {...props} />;
    // For plain text use vanilla p tag.
    default:
      return (
        <p {...attributes} className={styles.paragraphSlateInput}>
          {children}
        </p>
      );
  }
};

/**
 * Mentions element, for filling onClick on tag, for scorecard, and performing highlight according
 * to the type of the mentions, i.e, scorecards as orange and users as blue.
 */
const Mention = ({ attributes, element, children, readOnly }) => {
  const selected = useSelected();
  const focused = useFocused();
  const handleRedirectToScorecard = () => {
    mixpanel('Scorecard Selected', {
      [mixpanelKeys.description]: 'Notes',
      [mixpanelKeys.callId]: element?.character?.callId || '',
      [mixpanelKeys.templateId]: element?.character?.actualOption,
    });
    history.push({
      pathname: `/scorecard/${element?.character?.callId || ''}/${element
        ?.character?.actualOption || ''}`,
      state: element?.character?.state,
    });
  };

  // send span tag with className and styles acc. to tag type.
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element?.character?.displayOption?.replace(
        ' ',
        '-',
      ) || ''}`}
      className={`${styles.mentionsBasicStyles} ${
        element.character.type === 'user'
          ? styles.blueMention
          : styles.orangeMention
      }

      ${
        element.character.type === 'scorecard' && readOnly
          ? styles.pointerCursor
          : ''
      }

      ${
        selected && focused
          ? styles.boxShadowsEnabled
          : styles.boxShadowsDisabled
      }`}
      onClick={
        element?.character?.type === 'scorecard' && readOnly
          ? handleRedirectToScorecard
          : null
      }
    >
      @{trimNull(element?.character?.displayOption || '')}
      {children}{' '}
    </span>
  );
};

Mentions.propTypes = {
  autoFocus: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  scorecardList: PropTypes.array,
  callId: PropTypes.string,
  defaultValue: PropTypes.array,
  readOnly: PropTypes.bool,
  readOnlyWithBorders: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
};
Mention.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object,
  readOnly: PropTypes.bool,
};
Element.protoTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default Mentions;
