const regionUrlMap = {
  UK: process.env.REACT_APP_API_BASE_URL_UK,
  CANADA: process.env.REACT_APP_API_BASE_URL_CANADA,
  US: process.env.REACT_APP_API_BASE_URL_US,
};
const regionWSUrlMap = {
  UK: process.env.REACT_APP_WEBSOCKET_URL_UK,
  CANADA: process.env.REACT_APP_WEBSOCKET_URL_CANADA,
  US: process.env.REACT_APP_WEBSOCKET_URL_US,
};
const UNEXPECTED_LOGIN_ERROR = 'UNEXPECTED_LOGIN_ERROR';
/**
 * List of words where adding a "s" suffix does not makes sense, Non Plural words
 */
const NON_PLURAL_WORDS_FOR_TOOLTIPS = [
  'duration',
  'direction',
  '%',
  'failed',
  'approved',
  'by',
  'score',
  'ids',
];

const BUTTON_TOOLTIP_DELAY = 0.75;
const ICON_TOOLTIP_DELAY = 0.5;
const TEXT_TOOLTIP_DELAY = 0.5;
const SCROLLABLE_TOOLTIP_CLASS = 'scrollableTooltip';
const BUTTON_TOOLTIP = 'BUTTON_TOOLTIP';
const TEXT_TOOLTIP = 'TEXT_TOOLTIP';
const ICON_TOOLTIP = 'ICON_TOOLTIP';
const PRODIGAL_LOGO_DARK =
  'https://public.prodigaltech.com/prodigal-logo-dark.svg';

const PRODIGAL_LOGO_LIGHT =
  'https://public.prodigaltech.com/prodigal-logo-light.svg';

const REMOVE_NODES_ON_SCROLL_DELAY = 16;
const CUSTOM_TAG = 'custom tag/';

const FIXED_HEIGHT_TAGBOX = 115;

const FIXED_LENGTH_DIFFERENCE = 420;
/**
 * Pass new Dimensions Every 200ms to Child components as Props
 */
const DIMENSION_PROPAGATION_RATE = 500;

/**
 * Tooltip delay when a Tag operation is done via the AddTagPopover
 */
const PLUS_N_TOOLTIP_DELAY = 1000;

/**
 * Enables Subtraction for all the rows below the inner height of the const defined here
 * Subtracted place will be give to "+n" and Addtag button
 */
const START_SUBTRACTION_AFTER_HEIGHT = 400;

/**
 * values calculated based on the fixed height of note section and Transcript sections
 */
const EXPANDED_TAGBOX_FIXED_HEIGHT_DIFFERENCE = 340;
const COLLAPSED_TAGBOX_FIXED_HEIGHT_DIFFERENCE = 305;
/**
 * debounce Time delay for handleHeightAndWidthChanges in Tagbox
 * */
const DEBOUNCE_TIME_DELAY = 200;

const NON_SUBTRACTABLE_TAGBOX_HEIGHT = 90;
export {
  regionUrlMap,
  regionWSUrlMap,
  UNEXPECTED_LOGIN_ERROR,
  NON_PLURAL_WORDS_FOR_TOOLTIPS,
  BUTTON_TOOLTIP_DELAY,
  ICON_TOOLTIP_DELAY,
  TEXT_TOOLTIP_DELAY,
  PRODIGAL_LOGO_DARK,
  PRODIGAL_LOGO_LIGHT,
  SCROLLABLE_TOOLTIP_CLASS,
  BUTTON_TOOLTIP,
  TEXT_TOOLTIP,
  ICON_TOOLTIP,
  REMOVE_NODES_ON_SCROLL_DELAY,
  CUSTOM_TAG,
  FIXED_HEIGHT_TAGBOX,
  FIXED_LENGTH_DIFFERENCE,
  DIMENSION_PROPAGATION_RATE,
  PLUS_N_TOOLTIP_DELAY,
  START_SUBTRACTION_AFTER_HEIGHT,
  EXPANDED_TAGBOX_FIXED_HEIGHT_DIFFERENCE,
  COLLAPSED_TAGBOX_FIXED_HEIGHT_DIFFERENCE,
  NON_SUBTRACTABLE_TAGBOX_HEIGHT,
  DEBOUNCE_TIME_DELAY,
};
