/**
 *
 * Component: TranscriptWithoutSearch
 * Date: 21/4/2022
 *
 */

import React, { useMemo } from 'react';

// import PropTypes from 'prop-types';
import { formatTimeAddPadding } from 'utils/commonFunctions';
import { Text } from 'components/common';

import styles from './style.css';
const TranscriptWithoutSearch = ({
  call,
  eachTranscript,
  audioCurrentTime,
  handlePerWordClick,
  handleAgentandTimeClick,
  memoizeFlag,
  playingRef,
}) =>
  useMemo(
    () => {
      const isAgentSpeaker = call.speakers.agent === eachTranscript.speaker;
      return (
        <>
          <div>
            <div
              ref={
                audioCurrentTime > eachTranscript.stime &&
                audioCurrentTime < eachTranscript.etime
                  ? playingRef
                  : null
              }
              className={styles.utterance}
            >
              <Text
                type="subtitle"
                className={
                  // eslint-disable-next-line no-nested-ternary
                  call.speakers ? (isAgentSpeaker ? 'orange' : 'blue') : 'black'
                }
                style={{ cursor: 'pointer' }}
                text={
                  // eslint-disable-next-line no-nested-ternary
                  call.speakers
                    ? isAgentSpeaker
                      ? 'Agent '
                      : 'Customer '
                    : 'Segment '
                }
                onClick={() => {
                  handleAgentandTimeClick(eachTranscript.stime, eachTranscript);
                }}
              />
              <Text
                type="subtitleSmall"
                style={{ cursor: 'pointer' }}
                text={`${formatTimeAddPadding(
                  eachTranscript.stime,
                )} - ${formatTimeAddPadding(eachTranscript.etime)}`}
                onClick={() => {
                  handleAgentandTimeClick(eachTranscript.stime, eachTranscript);
                }}
              />

              <div>
                {eachTranscript?.spanTimingDetails.map(
                  (eachSpanTimeDetail, index) => {
                    const indexStart =
                      eachSpanTimeDetail?.span_start ||
                      eachSpanTimeDetail?.span_start === 0
                        ? eachSpanTimeDetail?.span_start
                        : null;
                    let indexEnd;
                    if (
                      index + 1 ===
                      eachTranscript?.spanTimingDetails?.length
                    ) {
                      indexEnd = eachTranscript?.text?.length || null;
                    } else {
                      indexEnd =
                        eachTranscript?.spanTimingDetails?.[index + 1]
                          ?.span_start - 1 || null;
                    }
                    return (
                      <>
                        <span
                          role="button"
                          tabIndex="0"
                          className={
                            audioCurrentTime >= eachSpanTimeDetail?.start &&
                            audioCurrentTime < eachSpanTimeDetail?.end
                              ? `${styles.playing}`
                              : ''
                          }
                          onClick={() => {
                            handlePerWordClick(
                              eachSpanTimeDetail.start,
                              eachTranscript.text.substring(
                                indexStart,
                                indexEnd,
                              ),
                            );
                          }}
                        >
                          {indexEnd && indexStart >= 0
                            ? eachTranscript.text.substring(
                                indexStart,
                                indexEnd,
                              )
                            : null}
                        </span>
                        <span> </span>
                      </>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [[eachTranscript, memoizeFlag(eachTranscript)]],
  );

TranscriptWithoutSearch.propTypes = {};

export default TranscriptWithoutSearch;
