/**
 *
 * Component: ReviewScorecardAction
 * Date: 17/6/2020
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  Text,
  Button,
  Radio,
  Modal,
  RadioGroup,
} from 'components/common';

import style from './style.css';

const EXPORT_EXCEL_FILES_LIMIT = 10000;
const EXPORT_PDF_FILES_LIMIT = 10;

function ReviewScorecardAction({
  selectAll,
  selectedRows,
  exportScorecards,
  totalCount,
  approveScorecards,
  enableActions,
  enableExport,
  loadingExport,
}) {
  const [visibleExport, setVisibleExport] = useState();
  const [visibleApprove, setVisibleApprove] = useState();
  const [exportType, setExportType] = useState(2);
  const numberOfScorecardSelected = selectAll
    ? totalCount
    : selectedRows?.length || 0;
  /**
   * Render Error Text based on the Threshhold of Files and PDF's export Limit.
   * */
  const getError = () => {
    if (numberOfScorecardSelected > EXPORT_EXCEL_FILES_LIMIT) {
      return (
        <Text
          type="Error"
          className="HeadersOrange"
          text={`Warning: You can only export excel up to ${EXPORT_EXCEL_FILES_LIMIT} and PDFs up to ${EXPORT_PDF_FILES_LIMIT} records at a time`}
        />
      );
    }
    if (numberOfScorecardSelected > EXPORT_PDF_FILES_LIMIT) {
      return (
        <Text
          type="Error"
          className="HeadersOrange"
          text={`Warning: You can only export PDFs for up to ${EXPORT_PDF_FILES_LIMIT} records at a time`}
        />
      );
    }
    return '';
  };

  const disabledActions = type => {
    if (!enableActions.includes(type)) return true;
    if (!selectAll && !selectedRows?.length) return true;
    return false;
  };

  return (
    <div className={style.columnsActions}>
      <Button
        disabled={disabledActions('approve')}
        type="primary"
        onClick={() => setVisibleApprove(true)}
        text="Approve"
      />
      <Button
        disabled={disabledActions('export')}
        type="primary"
        onClick={() => {
          setVisibleExport(true);
          setExportType(2);
        }}
        text="Export"
      />
      <Modal visible={visibleExport} onCancel={() => setVisibleExport(false)}>
        <div className={style.exportModal}>
          <Icon
            style={{
              width: '156px',
              height: '108px',
              objectFit: 'contain',
            }}
            type="confirmExport"
          />
          <Text
            text={`Export ${
              selectAll ? totalCount : selectedRows?.length || 0
            } selected
            records as:`}
            type="title"
          />
          <RadioGroup
            onChange={e => setExportType(e.target.value)}
            value={exportType}
          >
            {enableExport.includes('excel') && (
              <Radio
                className={style.radioStyle}
                disabled={numberOfScorecardSelected > EXPORT_EXCEL_FILES_LIMIT}
                value={2}
              >
                Excel format
              </Radio>
            )}
            {enableExport.includes('pdf') && (
              <Radio
                className={style.radioStyle}
                disabled={numberOfScorecardSelected > EXPORT_PDF_FILES_LIMIT}
                value={1}
              >
                PDF format
              </Radio>
            )}
          </RadioGroup>
          {enableExport.includes('pdf') && getError()}
          <div className={style.exportModalButtons}>
            <Button height="50px" onClick={() => setVisibleExport(false)}>
              Close
            </Button>
            <Button
              type="primary"
              height="50px"
              onClick={() => {
                exportScorecards(exportType);
              }}
              disabled={numberOfScorecardSelected > EXPORT_EXCEL_FILES_LIMIT}
              loading={loadingExport}
            >
              Export
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={visibleApprove} onCancel={() => setVisibleApprove(false)}>
        <div className={style.exportModal}>
          <Icon
            style={{
              width: '156px',
              height: '108px',
              objectFit: 'contain',
            }}
            type="confirmExport"
          />
          <Text
            text={`Selected  ${
              selectAll ? totalCount : selectedRows?.length || 0
            } scorecard will be locked and relevant scorecard can no longer be edited`}
            type="title"
          />

          <div className={style.exportModalButtons}>
            <Button height="50px" onClick={() => setVisibleApprove(false)}>
              Close
            </Button>
            <Button
              loading={loadingExport}
              type="primary"
              height="50px"
              onClick={() => {
                approveScorecards(exportType);
              }}
            >
              Approve
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

ReviewScorecardAction.propTypes = {
  selectAll: PropTypes.bool,
  selectedRows: PropTypes.array,
  exportScorecards: PropTypes.func,
  totalCount: PropTypes.number,
  approveScorecards: PropTypes.func,
  enableActions: PropTypes.array,
  enableExport: PropTypes.array,
  loadingExport: PropTypes.bool,
};

export default ReviewScorecardAction;
