/*
 *
 * Sider actions
 *
 */

import constant from './constants';
export default function action(type, payload = null) {
  if (payload !== null) {
    return {
      type: constant(type),
      payload,
    };
  }
  return {
    type: constant(type),
  };
}
