import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the call state domain
 */

const selectCallDomain = state => state.callSearch || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CallSearch
 */

const makeSelectCallSearch = f =>
  createSelector(
    selectCallDomain,
    substate => (f ? substate[f] : substate),
  );

export default makeSelectCallSearch;
export { selectCallDomain };
