/* eslint-disable no-return-assign */
/**
 *
 * Component: TranscriptPlayer
 * Date: 4/4/2022
 *
 */

import PropTypes from 'prop-types';
import React, {
  useMemo,
  useRef,
  useReducer,
  useEffect,
  useCallback,
  forwardRef,
} from 'react';
import ReactToPrint from 'react-to-print';
import _ from 'lodash';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { Tooltip, Icon, Input, Popover, Text } from 'components/common';
import CallTranscriptPrint from 'components/CallTranscriptPrint';
import TranscriptWithoutSearch from 'components/TranscriptWithoutSearch';
import TranscriptWithSearch from 'components/TranscriptWithSearch';
import Auth from 'auth0-react';
import { fetcher, API } from 'utils/request';
import { downloadFile } from 'utils/commonFunctions';
import { notificationError } from 'utils/notification';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import mixpanel from 'utils/mixpanel';
import message from 'utils/message';
import useElementSize from 'utils/hooks/useElementSize';
import { FIXED_HEIGHT_TAGBOX, ICON_TOOLTIP } from 'utils/commonConstants';

import styles from './style.css';
import transcriptReducer from './reducer';
import {
  FLOOR_TIME,
  SET_AUTO_SCROLL,
  SET_SEARCH_INDEX,
  ON_SEARCH_CLICK,
  ON_SEARCH_CLOSE,
  ON_SEARCH,
  LINE_HEIGHT,
  MIN_TRANSCRIPT_HEIGHT,
  FONT_SIZE,
  FONT_CONSTANT,
  LAST_TRANSCRIPT_PADDING,
  ON_FIRST_SCROLL,
} from './constants';

const TranscriptPlayer = forwardRef(
  (
    {
      audioCurrentTime,
      setAudioCurrentTime,
      call,
      searchText,
      transcriptView,
      toggleTranscriptView,
      setAudioBarLocations,
      isHowlerLoading,
      loading,
      showMore,
      setShowMore,
      showExtraMetaData,
      setShowExtraMetaData,
      showExpandTranscript,
      setShowExpandTranscript,
    },
    HowlerRef,
  ) => {
    const { isScopePresent } = new Auth();
    let hasExportTranscriptCalls = isScopePresent('export_transcript_calls');
    const expandTranscriptRef = useRef();
    const initialTranscriptState = {
      floorCurrentTime: 0,
      autoScroll: true,
      searchInput: searchText,
      isSearchIconVisble: false,
      currentSearchIndex: '0-1',
      scrolled: false,
    };
    const transcriptRef = useRef();

    const actionRef = useRef();
    const { height: elementHeight, width: elementWidth } = useElementSize(
      transcriptRef,
    );
    const {
      height: elementExpandHeight,
      width: elementExpandWidth,
    } = useElementSize(expandTranscriptRef);
    if (window.Cypress) {
      // eslint-disable-next-line prefer-destructuring
      hasExportTranscriptCalls = window.hasExportTranscriptCalls;
    }

    const [state, dispatch] = useReducer(
      transcriptReducer,
      initialTranscriptState,
    );

    const {
      floorCurrentTime,
      autoScroll,
      searchInput,
      isSearchIconVisble,
      currentSearchIndex,
      scrolled,
    } = state;

    const searchTextRef = useRef(searchText);
    const searchHitListRef = useRef([]);
    const highlightRef = useRef({});
    const audioBarsRef = useRef([]);
    const searchIndexRef = useRef(0);
    const indexesForListRef = useRef([]);
    const lastScrollTimeRef = useRef(null);
    const lastScrollIndexRef = useRef(0);
    const playingRef = useRef();
    const transcriptPrintRef = useRef();
    const backwardSearchCountRef = useRef(0);

    const TranscriptPrint = useMemo(() => <CallTranscriptPrint call={call} />, [
      call,
    ]);

    useEffect(() => {
      /**
       * Solves issues of auto scrolling being ON unnecessarily while initial few renders
       */
      if (isHowlerLoading && !autoScroll) {
        dispatch({ type: SET_AUTO_SCROLL, payload: { autoScroll: true } });
      }
    });

    /**
     * Changes the visibility of Transcript and action buttons based on the height changes
     */

    useEffect(() => {
      if (transcriptRef.current?.style) {
        transcriptRef.current.style.display = 'inline-block';
      }
      if (actionRef.current?.style) {
        actionRef.current.style.display = 'grid';
      }

      if (
        transcriptRef.current?.getBoundingClientRect()?.height <
        FIXED_HEIGHT_TAGBOX
      ) {
        transcriptRef.current.style.display = 'none';
        actionRef.current.style.display = 'none';
        setShowExpandTranscript(true);
      } else {
        if (transcriptRef.current) {
          transcriptRef.current.style.display = 'inline-block';
        }
        setShowExpandTranscript(false);
      }
    }, [
      elementHeight,
      elementWidth,
      showMore,
      showExtraMetaData,
      elementExpandHeight,
      elementExpandWidth,
    ]);

    /**
     *  Floor Time helps reduce renders and only causes a rerender when a new word is hit
     */

    useEffect(() => {
      // eslint-disable-next-line no-unused-expressions
      call?.transcript?.map(eachTranscript => {
        if (
          eachTranscript?.stime <= audioCurrentTime &&
          eachTranscript?.etime > audioCurrentTime &&
          eachTranscript?.spanTimingDetails
        ) {
          eachTranscript.spanTimingDetails.map(eachSpanTimeDetail => {
            if (
              eachSpanTimeDetail?.start <= audioCurrentTime &&
              eachSpanTimeDetail?.end > audioCurrentTime
            ) {
              dispatch({
                type: FLOOR_TIME,
                payload: { floorCurrentTime: eachSpanTimeDetail?.start },
              });
            }
            return null;
          });
        }
        return null;
      });
    }, [audioCurrentTime, call]);

    useEffect(() => {
      virtualizedScrollToView();
    }, [audioCurrentTime, virtualizedScrollToView]);

    useEffect(() => {
      getSearchIndexes();
      if (highlightRef.current) {
        Object.keys(highlightRef.current).forEach(key => {
          if (highlightRef.current[key] === null) {
            delete highlightRef.current[key];
          }
        });
      }
      // add a first highlight code and set currentIndex from here
      dispatch({
        type: SET_SEARCH_INDEX,
        payload: {
          currentSearchIndex: indexesForListRef.current[0],
        },
      });
      lastScrollIndexRef.current = currentSearchIndex;
      // shows last highlighted index when search is closed
      if (!isSearchIconVisble) {
        dispatch({
          type: SET_SEARCH_INDEX,
          payload: {
            currentSearchIndex: lastScrollIndexRef.current,
          },
        });
      }

      searchIndexRef.current = 0;
    }, [searchInput, call, getSearchIndexes, isSearchIconVisble]);

    /**
     * Decides which index to be shown in virtual list's window
     */

    const virtualizedScrollToView = useCallback(() => {
      if (call?.transcript && autoScroll) {
        call.transcript.map((eachTranscript, index) => {
          if (
            audioCurrentTime >= eachTranscript?.stime &&
            audioCurrentTime < eachTranscript?.etime
          ) {
            dispatch({
              type: SET_SEARCH_INDEX,
              payload: { currentSearchIndex: `${index}-` },
            });
          }
          return null;
        });
      }
      return null;
    }, [call.transcript, audioCurrentTime, autoScroll]);

    /**
     * Get search indexes based on searched words and sets audiobars
     * searchHitList is the array of elements with startTime and index
     * while, indexforList ref has indexes of virtual list and index which represent occurrence
     */
    const getSearchIndexes = useCallback(() => {
      handleSearchPositions('reset');
      indexesForListRef.current = [];
      audioBarsRef.current = [];
      if (call?.transcript && searchInput) {
        call.transcript.map((eachTranscript, index) => {
          if (
            eachTranscript?.text
              .toLowerCase()
              .includes(searchInput?.toLowerCase())
          ) {
            const regex = new RegExp(`(${_.escapeRegExp(searchInput)})`, 'gi');
            const parts = eachTranscript?.text.split(regex) || [];
            parts.map((part, i) => {
              let spanIndex = 0;
              if (i !== 0) {
                spanIndex = spanIndex + parts[i - 1].split(' ').length - 1;
              }

              part.split(' ').map((eachSpan, currIndex) => {
                if (currIndex === 0) {
                  if (
                    !audioBarsRef.current.includes(
                      eachTranscript?.spanTimingDetails?.[spanIndex + currIndex]
                        ?.start,
                    )
                  ) {
                    audioBarsRef.current = [
                      ...audioBarsRef.current,
                      eachTranscript?.spanTimingDetails?.[spanIndex + currIndex]
                        ?.start,
                    ];
                  }
                }
                return null;
              });
              if (regex.test(part)) {
                searchHitListRef.current = [
                  ...searchHitListRef.current,
                  `${eachTranscript?.stime}-${i}`,
                ];
                indexesForListRef.current = [
                  ...indexesForListRef.current,
                  `${index}-${i}`,
                ];
              }
              return null;
            });
          }
          return null;
        });
        setAudioBarLocations(audioBarsRef.current);
      }
    }, [
      setAudioBarLocations,
      call.transcript,
      searchInput,
      handleSearchPositions,
    ]);

    function turnOffAutoScroll(e) {
      if (
        lastScrollTimeRef.current &&
        parseInt(
          (e.nativeEvent.timeStamp - lastScrollTimeRef.current) / 20,
          10,
        ) < 1
      ) {
        dispatch({
          type: SET_AUTO_SCROLL,
          payload: { autoScroll: false },
        });
      } else {
        lastScrollTimeRef.current = e.timeStamp;
      }
    }

    const disableAutoScroll = () => {
      dispatch({ type: SET_AUTO_SCROLL, payload: { autoScroll: false } });
    };

    /**
     * Triggers search on change of searchInput text
     */
    const executeSearch = () => {
      dispatch({
        type: ON_SEARCH,
        payload: { searchInput: searchTextRef.current },
      });
      highlightRef.current = {};
      searchHitListRef.current = [];
      searchIndexRef.current = 0;
      if (searchTextRef.current === '') {
        setAudioBarLocations([]);
      }
    };

    /**
     * fetches transcript and creates txt file out of it
     * @returns text file of Transcript
     */

    const getTxtFileOfTranscript = async () => {
      if (!hasExportTranscriptCalls) return;
      const { callId } = call;
      try {
        const res = await fetcher().get(`${API.getTranscriptFile}/${callId}`);
        const { url, filename } = res.data.data;
        downloadFile(url, filename);
      } catch (err) {
        notificationError(
          'File download failed',
          err?.response?.status || 599,
          true,
        );
        message.error('File download failed. Please try again later.');
      }
    };

    /**
     * functions handles search positions, goes into reset case when index is first or last
     */

    const handleSearchPositions = useCallback(
      type => {
        let searchIndex;
        if (
          type === 'inc' &&
          indexesForListRef.current[searchIndexRef.current + 1]
        ) {
          searchIndex =
            indexesForListRef.current[(searchIndexRef.current += 1)];
          lastScrollIndexRef.current = searchIndex;
          disableAutoScroll();
          mixpanel('Search caret button', {
            [mixpanelKeys.callId]: call?.id,
            [mixpanelKeys.description]: 'up',
          });
        }

        if (
          type === 'dec' &&
          indexesForListRef.current[searchIndexRef.current - 1]
        ) {
          searchIndex =
            indexesForListRef.current[(searchIndexRef.current -= 1)];
          lastScrollIndexRef.current = searchIndex;
          disableAutoScroll();
          mixpanel('Search caret button', {
            [mixpanelKeys.callId]: call?.id,
            [mixpanelKeys.description]: 'down',
          });
        }

        if (type === 'reset') {
          if (
            lastScrollIndexRef.current ===
            indexesForListRef.current[indexesForListRef.current.length - 1]
          ) {
            [searchIndex] = indexesForListRef.current;
            lastScrollIndexRef.current = searchIndex;
            searchIndexRef.current = 0;
          } else if (
            lastScrollIndexRef.current === indexesForListRef.current[0] ||
            backwardSearchCountRef.current === 1
          ) {
            searchIndex =
              indexesForListRef.current[indexesForListRef.current.length - 1];
            lastScrollIndexRef.current = searchIndex;
            searchIndexRef.current = indexesForListRef.current.length - 1;
          } else {
            searchIndex = '0-0';
            searchIndexRef.current = 0;
          }
        }
        dispatch({
          type: SET_SEARCH_INDEX,
          payload: {
            currentSearchIndex: searchIndex,
          },
        });
      },
      [currentSearchIndex],
    );

    /**
     * used when searchbar is visible,resets all search Related states
     */
    const handleCloseSearch = () => {
      audioBarsRef.current = [];
      searchTextRef.current = '';
      setAudioBarLocations([]);
      searchHitListRef.current = [];
      dispatch({
        type: ON_SEARCH_CLOSE,
        payload: {
          isSearchIconVisble: !state.isSearchIconVisble,
          searchInput: '',
        },
      });
    };

    /**
     * Checks and decide whether a particular transcript has a search term present
     * based on that renders TranscriptWithSearch and TranscriptWithoutSearch
     * @param {*} eachTranscript
     * @param {*} index of eachTranscript
     */
    const EachTranscriptRenderer = ({ eachTranscript, index }) => {
      if (
        !_.isEmpty(searchInput) &&
        eachTranscript.text &&
        eachTranscript.text.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        return (
          <TranscriptWithSearch
            call={call}
            audioCurrentTime={audioCurrentTime}
            handlePerWordClick={handlePerWordClick}
            handleAgentandTimeClick={handleAgentandTimeClick}
            eachTranscript={eachTranscript}
            memoizeFlag={memoizeFlag}
            playingRef={playingRef}
            highlightRef={highlightRef}
            searchInput={searchInput}
            index={index}
            currentSearchIndex={currentSearchIndex}
          />
        );
      }

      return (
        <TranscriptWithoutSearch
          call={call}
          audioCurrentTime={audioCurrentTime}
          handlePerWordClick={handlePerWordClick}
          handleAgentandTimeClick={handleAgentandTimeClick}
          eachTranscript={eachTranscript}
          memoizeFlag={memoizeFlag}
          playingRef={playingRef}
        />
      );
    };
    EachTranscriptRenderer.propTypes = {
      eachTranscript: PropTypes.object,
      index: PropTypes.string,
    };

    /**
     * used in Transcript with search and without search for memoization purposes
     * @param {*} eachTranscript
     * @returns
     */
    const memoizeFlag = eachTranscript => {
      if (
        eachTranscript.stime <= audioCurrentTime &&
        eachTranscript.etime > audioCurrentTime
      ) {
        return audioCurrentTime;
      }
      return false;
    };
    /**
     * handles click on word
     * @param {*} startTime Time where we want to move
     * @param {*} span word clicked
     */

    const handlePerWordClick = (startTime, span) => {
      if (!isHowlerLoading) {
        HowlerRef.current.seek(startTime);
        setAudioCurrentTime(startTime);
        mixpanel('Clicked on word', {
          [mixpanelKeys.callId]: call?.id,
          [mixpanelKeys.word]: span,
        });
      }
    };

    /**
     * handle click on time and speaker texts
     * @param {*} startTime Time where we want to move to
     * @param {*} transcript transcript to decide speaker
     */
    const handleAgentandTimeClick = (startTime, transcript) => {
      if (!isHowlerLoading) {
        HowlerRef.current.seek(startTime);
        setAudioCurrentTime(startTime);
        mixpanel('Clicked on Utterance', {
          [mixpanelKeys.callId]: call?.id,
          // eslint-disable-next-line no-nested-ternary
          [mixpanelKeys.speaker]: call?.speakers
            ? call.speakers.agent === transcript.speaker
              ? 'agent'
              : 'customer'
            : 'segment',
        });
      }
    };

    /**
     * Virtual list method to render things on window
     */
    const RowRenderer = useMemo(
      // eslint-disable-next-line react/prop-types
      () => ({ props }) => {
        // eslint-disable-next-line react/prop-types
        const { index, key, style } = props;

        return (
          <div key={key} className="row" style={style}>
            <EachTranscriptRenderer
              eachTranscript={call?.transcript[index]}
              index={index}
            />
          </div>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        floorCurrentTime,
        searchInput,
        currentSearchIndex,
        call.transcript,
        isHowlerLoading,
      ],
    );

    RowRenderer.propTypes = {
      props: PropTypes.object,
    };

    /**
     * helps calculate height for each transcript, based of values
     * @param {*} calcProps Props from Virtual list
     * @param {*} width comes form autoSizer used in virtual scroll
     * @returns
     */
    const calcVirtualHeight = (calcProps, width) => {
      const { length } = call.transcript[calcProps.index].text;
      // width*constant/2 gives approx numbers of char that can fit in a line
      const height =
        Math.floor(length / ((width * FONT_CONSTANT) / FONT_SIZE)) *
          LINE_HEIGHT +
        MIN_TRANSCRIPT_HEIGHT;

      if (call.transcript.length - 1 === calcProps.index) {
        return height + LAST_TRANSCRIPT_PADDING;
      }
      return height;
    };

    /**
     * gets current scroll index to be pushed into virtual list
     * @returns index
     */
    const getScrollIndex = () => currentSearchIndex?.split('-')?.[0];

    /**
     * help execute Increments/dec key bindings with search input
     * @param {*} e Input event from search bar
     */
    const handleKeyPress = e => {
      if (e.key === 'Enter' && e.shiftKey) {
        backwardSearchCountRef.current += 1;
        handleSearchPositions('dec');
        return;
      }
      if (e.key === 'Enter') {
        handleSearchPositions('inc');
      }
    };

    const executeOnInput = e => {
      searchTextRef.current = e.target.value;
      backwardSearchCountRef.current = 0;
      executeSearch();
    };

    /**
     * Debounces input to reduce unnecessary ops on each change
     */
    const handleChange = useMemo(() => {
      const debounced = _.debounce(executeOnInput, 10);
      return e => {
        e.persist();
        return debounced(e);
      };
    }, []);

    const recordScrollOnMixPanel = () => {
      if (!scrolled && !loading) {
        mixpanel('Transcript scrolled', {
          [mixpanelKeys.callId]: call?.id,
        });
        dispatch({ type: ON_FIRST_SCROLL, payload: { scrolled: true } });
      }
    };

    return (
      <div
        onScroll={e => turnOffAutoScroll(e)}
        onWheel={recordScrollOnMixPanel}
      >
        <div className={styles.actionContainer} ref={actionRef}>
          <Tooltip
            placement="left"
            title={transcriptView ? 'Collapse Transcript' : 'Expand Transcript'}
            key="transcript-expand"
            type={ICON_TOOLTIP}
          >
            <Icon
              className={`${styles.expandTranscriptIcon} ${
                styles.cursorPointer
              } ${styles.fadeIn}`}
              type={transcriptView ? 'collapse' : 'expand'}
              size={16}
              onClick={() => {
                toggleTranscriptView();
                mixpanel('Transcript Mode', {
                  [mixpanelKeys.callId]: call.id,
                  isExpanded: !transcriptView,
                });
              }}
            />
          </Tooltip>
          <Tooltip
            placement="left"
            title="Click to search for keywords in transcript"
            type={ICON_TOOLTIP}
            key="transcript-search"
          >
            <Icon
              className={`${styles.searchTranscriptIcon} ${
                styles.cursorPointer
              } ${styles.fadeIn}`}
              type="search"
              size={18}
              onClick={() => {
                if (isSearchIconVisble) {
                  handleCloseSearch();
                } else {
                  dispatch({
                    type: ON_SEARCH_CLICK,
                    payload: {
                      isSearchIconVisble: !state.isSearchIconVisble,
                    },
                  });
                }
              }}
            />
          </Tooltip>
          {isSearchIconVisble ? (
            <div className={styles.callDetailSearch}>
              <Input
                autoFocus
                defaultValue={searchInput}
                placeholder="Enter Text to search"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                suffix={
                  <div style={{ color: '#C0C0C0' }}>
                    {searchHitListRef.current.length > 0 ? (
                      <>
                        <span>{searchIndexRef.current + 1}</span>
                        <span> </span>
                        of {indexesForListRef.current.length}
                      </>
                    ) : null}
                  </div>
                }
              />
              <div className={styles.searchTranscriptBox}>
                <Icon
                  className={searchInput ? '' : 'disabled'}
                  onClick={() => {
                    backwardSearchCountRef.current += 1;
                    handleSearchPositions('dec');
                  }}
                  type="up"
                  size={14}
                />
                <Icon
                  className={searchInput ? '' : 'disabled'}
                  onClick={() => {
                    handleSearchPositions('inc');
                  }}
                  type="down"
                  size={14}
                />
                <div className={styles.searchTranscriptBoxClose}>
                  <Icon onClick={handleCloseSearch} type="close" size={10} />
                </div>
              </div>
            </div>
          ) : null}

          {hasExportTranscriptCalls ? (
            <Popover
              trigger="click"
              className="search-transcript-icon cursor-pointer fadeIn"
              placement="left"
              content={
                <div className="popover-div-export">
                  <ReactToPrint
                    content={() => transcriptPrintRef.current}
                    documentTitle={`prodigal-call-transcript-${call.callId}`}
                    trigger={() => (
                      <div>
                        <Tooltip
                          placement="left"
                          title="Print / Export to PDF"
                          key="transcript-print"
                        >
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() =>
                              mixpanel('Print Transcript', {
                                [mixpanelKeys.callId]: call.id,
                              })
                            }
                          >
                            <Icon
                              className="print-transcript-icon cursor-pointer"
                              type="printer"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  />
                  <Tooltip
                    placement="left"
                    title="Export to txt"
                    key="transcript-txt"
                  >
                    <div
                      role="button"
                      tabIndex="0"
                      className={
                        hasExportTranscriptCalls ? '' : styles.disabled
                      }
                      onClick={() => getTxtFileOfTranscript()}
                    >
                      <Icon
                        className="print-transcript-icon cursor-pointer"
                        type="fileText"
                      />
                    </div>
                  </Tooltip>
                </div>
              }
            >
              <Tooltip
                title="Download transcript"
                placement="left"
                type={ICON_TOOLTIP}
              >
                <div>
                  <Icon
                    className="tools-transcript-icon cursor-pointer fadeIn"
                    type="downloadBlack"
                    data-testid="transcript-export"
                  />
                </div>
              </Tooltip>
            </Popover>
          ) : (
            <></>
          )}

          {!autoScroll && (
            <Tooltip
              placement="left"
              title="Enable Auto Scrolling"
              key="transcript-autoscrolling"
              className="autoscrolling-transcript-icon-container"
            >
              <div
                role="button"
                tabIndex="0"
                onClick={() =>
                  dispatch({
                    type: SET_AUTO_SCROLL,
                    payload: { autoScroll: true },
                  })
                }
              >
                <Icon
                  className={styles.autoscrollingTranscriptIcon}
                  type="drag"
                  size={18}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.transcript} ref={transcriptRef}>
          <div style={{ display: 'none' }}>
            <div ref={el => (transcriptPrintRef.current = el)}>
              {TranscriptPrint}
            </div>
          </div>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={index => calcVirtualHeight(index, width)}
                rowRenderer={props => <RowRenderer props={props} />}
                rowCount={call.transcript?.length || 0}
                overscanRowCount={3}
                scrollToIndex={Number(
                  currentSearchIndex
                    ? getScrollIndex()
                    : handleSearchPositions('reset'),
                )}
                scrollToAlignment="center"
              />
            )}
          </AutoSizer>
          <div />
        </div>
        {showExpandTranscript ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={styles.expandTranscriptDiv}
            ref={expandTranscriptRef}
            onClick={() => {
              setShowMore(false);
              setShowExtraMetaData(false);
              if (!showMore && !showExpandTranscript) {
                setShowExpandTranscript(false);
              }
            }}
          >
            <div className={styles.expandTranscriptText}>
              <Text text="Transcription" />
            </div>
            <div className={styles.expandTranscriptIcon}>
              <Icon type="expandTranscript" size="25" scale={0.5} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
);

export default TranscriptPlayer;

TranscriptPlayer.propTypes = {
  audioCurrentTime: PropTypes.bool,
  setAudioCurrentTime: PropTypes.number,
  call: PropTypes.object,
  searchText: PropTypes.string,
  transcriptView: PropTypes.bool,
  toggleTranscriptView: PropTypes.func,
  setAudioBarLocations: PropTypes.func,
  isHowlerLoading: PropTypes.bool,
  loading: PropTypes.bool,
  showMore: PropTypes.bool,
  showExtraMetaData: PropTypes.bool,
  setShowMore: PropTypes.func,
  setShowExtraMetaData: PropTypes.func,
  showExpandTranscript: PropTypes.bool,
  setShowExpandTranscript: PropTypes.func,
};
